import { combineReducers } from "redux";
import mainReducer from "./Main/reducer";
import CIReducer from "./CI/reducer";
import DataReducer from "./Data/reducer";
import DynamicReducer from "./Dynamic/reducer";
import FFTPReducer from "./FFTP/reducer";
import HSIReducer from "./HSI/reducer";
import OffliceDonationReducer from "./OffliceDonation/reducer";
import SGKReducer from "./SGK/reducer";
import SecurityReducer from "./Security/reducer";
import UserReducer from "./User/reducer";

const RootReducer = combineReducers({
  main: mainReducer,
  data: DataReducer,
  security: SecurityReducer,
  ci: CIReducer,
  hsi: HSIReducer,
  sgk: SGKReducer,
  fftp: FFTPReducer,
  offline_url: OffliceDonationReducer,
  users: UserReducer,
  dynamic: DynamicReducer
});

export default RootReducer;