import React, { useState, useEffect } from 'react';
import { Select, Typography, Spin, Card, Form, Tabs, Checkbox, Button, Input, Modal, notification } from 'antd';
import { PlusOutlined, ShareAltOutlined, CheckCircleOutlined, CloseCircleOutlined, EllipsisOutlined, StarTwoTone } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs'; // For handling the time display
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const { Option } = Select;
const { Text } = Typography;
const team = "team1";//'20231213080921'; // Replace with your actual team name
const region = "staging-01";//'aeyx'; // Replace with your actual region
const webname = "bytespree.net";
const apiKey = "443825182a005295917bc72799d13c5117ebe3a13fea85b888be1f29b0f4d89a";//'56031ad5e82c20a24425ae1601690d9eb1db8adce6dbb1f1739ec927a0943334'; // Replace with your orchestration API key
const email = 'optional_user_email@example.com'; // Replace with an existing user email or leave undefined
//const loginUrl = `https://${team}.${region}.bytespree.net/api/v1/auth/login`;
const CreateConnections = () => {

    const [connectors, setConnectors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isConnectorVisible, setIsConnectorVisible] = useState(false);
    const [settings, setSettings] = useState([]);
    const [settingsLoading, setSettingsLoading] = useState(false);
    const [conditionalVisibility, setConditionalVisibility] = useState({});
    const [form] = Form.useForm();
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [selectedConnectorId, setSelectedConnectorId] = useState(null);
    const [limitation, setLimitation] = useState('');
    const [showLimitationModal, setShowLimitationModal] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(true);
    const [creationTime, setCreationTime] = useState(null); // Store the creation time
    const [showNewCreated, setShowNewCreated] = useState(false); // Control showing "new created"
    const [customInputValues, setCustomInputValues] = useState(false);
    const scheduleTypeId = process.env.REACT_APP_BYTESPREE_SCHEDULE_TYPE_ID;
    const schedulePropId = process.env.REACT_APP_BYTESPREE_SCHEDULE_PROP_ID;
    const scheduleValueId = process.env.REACT_APP_BYTESPREE_SCHEDULE_VALUE_ID;
    const [jwtToken, setJwtToken] = useState(null);
    const [commonDefaultTables, setCommonDefaultTables] = useState([]);
    const [MainConnectionID, setMainConnectionID] = useState(null);
    const navigate = useNavigate();
    const { role, client } = useSelector(({ main }) => main);
    // Function to get connectors using the JWT token
    const getJwtToken = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `https://${team}.${region}.${webname}/api/v1/auth/login`,
                {
                    api_key: apiKey,
                    email: "", // Optional email, comment out if not needed
                },
                {
                    headers: {
                        Authorization: "Bearer 443825182a005295917bc72799d13c5117ebe3a13fea85b888be1f29b0f4d89a",
                    },
                }
            );
            setJwtToken(response.data.data.access_token);
            setLoading(false);
            fetchConnectors(response.data.data.access_token);
            // return jwtToken;
        } catch (error) {
            notification.error({
                message: 'Error Stablishing Connection',
                description: (
                    <div data-qa="error-description">
                         {error.message}
                    </div>
                ),
                icon: <CloseCircleOutlined style={{ color: 'red', display: 'block' }} />,
            });
            setLoading(false);
            throw error;
        }
    };

    const fetchConnectors = async (token) => {
        try {
            setLoading(true);
            const response = await axios.get(
                `https://${team}.${region}.${webname}/api/v1/connectors`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const primaryConnectors = response.data.data;

            // Fetch additional connectors from the second API
            const additionalResponse = await axios.get(
                'https://z0eepnybq0.execute-api.us-east-1.amazonaws.com/dev/v1/fetch?r=connectors'
            );

            const additionalConnectors = additionalResponse.data;
            const commonConnectors = primaryConnectors
                .filter((primary) =>
                    additionalConnectors.some(
                        (additional) =>
                            additional.connectors.name.toLowerCase() === primary.name.toLowerCase()
                    )
                )
                .map((primary) => {
                    // Find the matching connector from additionalConnectors
                    const matchingAdditional = additionalConnectors.find(
                        (additional) =>
                            additional.connectors.name.toLowerCase() === primary.name.toLowerCase()
                    );

                    // Return the common connector with the added connection_id
                    return {
                        ...primary,
                        connection_id: matchingAdditional?.connectors?.id, // Add connection_id from additionalConnectors
                    };
                });

            // Extract default tables from the common connectors and ensure they match the expected format
            const defaultTablesByConnectorId = primaryConnectors.reduce((acc, primary) => {
                // Find the matching connector from the additional connectors
                const matchingAdditional = additionalConnectors.find(
                    (additional) =>
                        additional.connectors.name.toLowerCase() === primary.name.toLowerCase()
                );
                // If a match is found, store default tables under the correct primary connector's ID
                if (matchingAdditional) {
                    acc[primary.id] = matchingAdditional.connectors.default_tables || [];
                }

                return acc;
            }, {});

            setConnectors(commonConnectors);
            setCommonDefaultTables(defaultTablesByConnectorId);


        } catch (error) {
            notification.error({
                message: 'Error fetching Connection',
                description: (
                    <div data-qa="error-description">
                         {error.message}
                    </div>
                ),
                icon: <CloseCircleOutlined style={{ color: 'red', display: 'block' }} />,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchSettings = async (connectorId) => {
        form.resetFields();
        setSettings([]);
        try {

            setSettingsLoading(true);
            const response = await axios.get(
                `https://${team}.${region}.${webname}/api/v1/connectors/${connectorId}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );
            setAgreeToTerms(false);
            setLimitation(response.data.data?.known_limitations[0] || '');
            setSettings(response.data.data.settings);
            setSelectedConnectorId(connectorId); // Save selected connector ID
        } catch (error) {
            notification.error({
                message: 'Error fetching Connection',
                description: (
                    <div data-qa="error-description">
                         {error.message}
                    </div>
                ),
                icon: <CloseCircleOutlined style={{ color: 'red', display: 'block' }} />,
            });
        } finally {
            form.setFieldsValue({ agreeToTerms: false });
            setSubmitDisabled(true);
            setSettingsLoading(false);
        }
    };

    const onConnectorSelect = (value, option) => {
        setMainConnectionID(option['data-connection']);
        fetchSettings(value);
    };
    const generateConnectionName = (baseConnectionString) => {
        // Get the current timestamp and high-resolution time
        const timestamp = Date.now();
        const highResTime = performance.now().toString().replace('.', '');

        // Generate a random alphanumeric string of length 6
        const randomString = Math.random().toString(36).substring(2, 8);

        // Concatenate to form the unique connection name
        const connectionName = `${baseConnectionString}${timestamp}${highResTime}${randomString}`;

        return connectionName;
    }

    const updateTables = (selectedConnectorId, scheduleTypeId, schedulePropId, scheduleValueId) => {
        const defaultTables = commonDefaultTables[selectedConnectorId] || [];

        return defaultTables.map((tableName) => ({
            name: tableName,
            is_active: true,
            schedule: {
                schedule_type_id: scheduleTypeId,
                properties: [
                    {
                        id: schedulePropId,
                        value: scheduleValueId,
                    },
                ],
            },
        }));
    };

    const onFinish = async (values) => {
        if (settingsLoading || !selectedConnectorId) return;
        setLoading(true);

        // Format the settings data
        const settingsData = settings.map((setting) => ({
            name: setting.name,
            value: values[setting.name] || '', // Get the value from form data
        }));

        try {
            // First API call to test the connector
            const testResponse = await axios.post(
                `https://${team}.${region}.${webname}/api/v1/connectors/${selectedConnectorId}/test`,
                { settings: settingsData },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );

            // If the first API call is successful, proceed to the second API call
            if (testResponse.status === 200) {
                try {
                    // Generate a unique connection name
                    const baseConnectionString = 'connection';
                    const connectionName = generateConnectionName(baseConnectionString);

                    const tables = updateTables(
                        selectedConnectorId,
                        scheduleTypeId,
                        schedulePropId,
                        scheduleValueId
                    );

                    // Second API call to datalakes
                    const datalakeResponse = await axios.post(
                        `https://${team}.${region}.${webname}/api/v1/datalakes`,
                        {
                            server_id: 1,
                            connector_id: selectedConnectorId, // Use the connector ID from the first call
                            name: connectionName,
                            settings: settingsData,
                            tables,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    );

                    // If the second API call is successful, proceed to the third API call
                    if (datalakeResponse.status === 200) {

                        // Third API call: clientConnection
                        try {
                            const clientConnectionResponse = await axios.post(
                                'https://z0eepnybq0.execute-api.us-east-1.amazonaws.com/dev/v1/clientConnection/',
                                {
                                    client_id: "1", // Use client_id from response or default
                                    connector_id: MainConnectionID, // Use connector_id from response or fallback
                                    database_id: datalakeResponse.data.data.database_id, // Use database_id from response or default
                                },
                                {
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                }
                            );

                            // Success notification for the third API call
                            if (clientConnectionResponse.status === 200) {
                                notification.success({
                                    message: 'Data submitted successfully',
                                    description: (
                                        <div data-qa="success-description">
                                            Connection has been established successfully.
                                        </div>
                                    ),
                                    icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
                                });

                                // Additional actions after all API calls succeed
                                const now = dayjs();
                                setCreationTime(now);
                                form.resetFields(); // Reset the form after submission
                                setSubmitDisabled(true); // Disable the submit button again after resetting
                                setConnectors([]); // Clear the connectors data
                                setSettings([]); // Clear the settings data
                                // getJwtToken(); // Re-fetch the connectors       
                                setIsConnectorVisible(false);
                                navigate('/admin/Connections');
                            }
                        } catch (clientError) {
                            // Error handling for the clientConnection API call
                            notification.error({
                                message: 'Error Establishing Client Connection',
                                description: (
                                    <div data-qa="error-description">
                                         {clientError.response?.data?.message}
                                    </div>
                                ),
                                icon: <CloseCircleOutlined style={{ color: 'red', display: 'block' }} />,
                            });
                        }
                    }
                } catch (secondError) {
                    // Error handling for the datalakes API call
                    notification.error({
                        message: 'Error',
                        description: (
                            <div data-qa="error-description">
                                 {secondError.message}
                            </div>
                        ),
                        icon: <CloseCircleOutlined style={{ color: 'red', display: 'block' }} />,
                    });
                }
            } else {

                notification.error({
                    message: 'Error',
                    description: (
                        <div data-qa="error-description">
                             {testResponse?.data?.message}
                        </div>
                    ),
                    icon: <CloseCircleOutlined style={{ color: 'red', display: 'block' }} />,
                });
            }
        } catch (firstError) {
            // Error handling for the first API call
            notification.error({
                message: 'Error',
                description: (
                    <div data-qa="error-description">
                         {firstError.response?.data?.message}
                    </div>
                ),
                icon: <CloseCircleOutlined style={{ color: 'red', display: 'block' }} />,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSelectChange = (settingName, value) => {
        // Update conditional visibility state
        if (value === 'custom') {
            setCustomInputValues(true)
            setConditionalVisibility((prev) => ({
                ...prev,
                [settingName]: value,
            }));
            form.setFieldsValue({ [settingName]: value }, () => {
                // Call validateForm to re-validate the form on change
                validateForm();
            });
        } else {
            setCustomInputValues(false)
        }

    };
    const shouldRenderCustomInput = (setting) => {
        if (!setting.visible_if) return false;

        const [targetSettingName, targetValue] = setting.visible_if
            .split(' == ')
            .map((str) => str.replace(/['"]+/g, '').trim());

        return conditionalVisibility[targetSettingName] === targetValue;
    };

    const renderSettingInput = (setting) => {
        // Set main settings without the filtered ones
        const { data_type, options, is_required, friendly_name, name } = setting;
        if (data_type === 'boolean') {
            return (
                <Form.Item
                    label={friendly_name}
                    name={name}
                    valuePropName="checked"
                    rules={[
                        {
                            required: is_required,
                            message: `${friendly_name} is required`,
                        },
                    ]}
                    data-qa={`form-item-${name}`}
                    onChange={validateForm}
                >
                    <Checkbox data-qa={`checkbox-${name}`}>{friendly_name}</Checkbox>
                </Form.Item>
            );
        }

        if (data_type === 'select' && options) {
            const parsedOptions = JSON.parse(options);
            return (
                <>
                    <Form.Item
                        label={friendly_name}
                        name={name}
                        rules={[
                            {
                                required: is_required,
                                message: `${friendly_name} is required`,
                            },
                        ]}
                        data-qa={`form-item-${name}`}
                    >
                        <Select
                            placeholder={`Select ${friendly_name}`}
                            className="w-1/3"
                            onChange={(value) => handleSelectChange(name, value)}
                            style={{ width: '33.333%' }}
                            data-qa={`select-${name}`}
                        >
                            {Object.keys(parsedOptions).map((key) => (
                                <Option key={key} value={key} data-qa={`select-option-${name}-${key}`}>
                                    {parsedOptions[key]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {customInputValues && settings
                        .filter(shouldRenderCustomInput)
                        .map((customSetting) => (
                            <Form.Item
                                key={customSetting.name}
                                label={customSetting.friendly_name}
                                name={customSetting.name}
                                rules={[
                                    {
                                        required: customSetting.is_required,
                                        message: `${customSetting.friendly_name} is required`,
                                    },
                                ]}
                                onChange={validateForm}
                                data-qa={`form-item-${customSetting.name}`}
                            >
                                <Input
                                    placeholder={`Enter ${customSetting.friendly_name}`}
                                    className="w-1/3"
                                    data-qa={`input-${customSetting.name}`}
                                    onChange={validateForm}
                                />
                            </Form.Item>
                        ))}
                </>
            );
        }

        if ((!data_type || data_type === 'text') && !setting.visible_if) {
            return (
                <Form.Item
                    label={friendly_name}
                    name={name}
                    rules={[
                        {
                            required: is_required,
                            message: `${friendly_name} is required`,
                        },
                    ]}
                    data-qa={`form-item-${name}`}
                >
                    <Input
                        placeholder={`Enter ${friendly_name}`}
                        className="w-1/3"
                        data-qa={`input-${name}`}
                        onChange={validateForm}
                    />
                </Form.Item>
            );
        }
    };

    // Validate form fields whenever there are changes
    const validateForm = async () => {
        // Check if the form values are valid
        const values = form.getFieldsValue(); // Get current form values

        // Ensure all required fields are filled
        const allFieldsFilled = settings
        .filter(setting => {
          // Ignore settings with visible_if condition "environment_url == 'custom'"
          const [key, value] = setting.visible_if
            ? setting.visible_if.split(' == ').map(str => str.replace(/['"]+/g, '').trim())
            : [];
               // Ignore settings with default_value set to "hidden"
          if (setting.data_type === 'hidden') {
            return false;
          }
      
          return !(key === 'environment_url' && value === 'custom');
        })
        .filter(setting => setting.is_required) // Ignore settings where is_required is false
        .every(setting => {
          
          return values[setting.name] !== undefined && values[setting.name] !== '';
        });
      
        // Ensure agreement to terms or no limitation is present
        const isLimitationAgreed = agreeToTerms || limitation === '';
        // Check if limitation is true or false
        if (limitation === true) {
            // Check if agreeToTerms is true
            if (agreeToTerms) {
                setSubmitDisabled(false);
            } else {
                setSubmitDisabled(true);
            }
        } else {
            // Enable submit button only if all fields are filled and limitation terms are agreed
            if (allFieldsFilled && isLimitationAgreed) {
                setSubmitDisabled(false);

            } else {
                setSubmitDisabled(true);
            }
        }
    };

    // Trigger validation on form field change
    useEffect(() => {
        // getJwtToken();
        validateForm();
    }, [settings, form, agreeToTerms]);

    // useEffect to call getJwtToken once when the page loads
    useEffect(() => {
        getJwtToken(); // Call getJwtToken only once on component mount
    }, []); // Empty dependency array ensures it runs only once

    const handleLimitationModal = () => {
        setShowLimitationModal(true);
    };

    const handleLimitationModalOk = () => {
        form.setFieldsValue({ agreeToTerms: true });
        setAgreeToTerms(true);
        setShowLimitationModal(false);
    };

    const handleLimitationModalCancel = () => {
        setShowLimitationModal(false);
    };


    return (
        <div className="flex flex-col bg-white shadow-lg rounded-2xl" style={{ minHeight: "440px" }}>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
                    <Spin tip="Syncing..." size="large" />
                </div>
            )}
            <div className="w-full rounded-2xl">
                <Form
                    layout="vertical"
                    className="bg-white p-6 rounded shadow flex flex-col justify-between"
                    style={{ minHeight: "440px" }}
                    form={form}
                    onFinish={onFinish}
                    data-qa="connection-form"
                    onValuesChange={validateForm}
                >
                    <Tabs defaultActiveKey="1" className="mb-0">
                        <Tabs.TabPane
                            tab={
                                <span className="flex items-center">
                                    <ShareAltOutlined className="mr-4" />
                                    Create a Connection
                                </span>
                            }
                            key="1"
                            className="p-0 mb-0"
                            data-qa="create-connection-tab"
                        >
                            <Form.Item
                                label="Choose a connector"
                                className="text-base w-1/3"
                                data-qa="form-item-connector"
                            >
                                <Select
                                    showSearch
                                    placeholder="Select from the dropdown"
                                    optionFilterProp="children"
                                    className="w-3/4"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    rules={[{ required: true, message: 'This field is required' }]}
                                    onChange={onConnectorSelect}
                                    loading={loading}
                                    data-qa="select-connector"
                                >
                                    {connectors.map((connector) => (
                                        <Option key={connector.id} value={connector.id} data-connection={connector.connection_id} data-qa={`select-option-connector-${connector.id}`}>
                                            {connector.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {settingsLoading ? (
                                <div className="flex justify-center items-center my-4">
                                    <Spin size="small" data-qa="settings-loading-spinner" />
                                </div>
                            ) : (
                                <>
                                    {settings.map((setting) => (
                                        <div key={setting.id} data-qa={`setting-${setting.id}`}>
                                            {renderSettingInput(setting)}
                                        </div>
                                    ))}
                                </>
                            )}
                        </Tabs.TabPane>
                    </Tabs>
                    <div>
                        <hr className="my-4" />
                        <div className="flex items-center">
                            {limitation && (
                                <Form.Item name="agreeToTerms" valuePropName="checked" data-qa="form-item-agree" rules={[{ required: true, message: 'This field is required' }]}>
                                    <Checkbox
                                        checked={agreeToTerms}
                                        onChange={(e) => setAgreeToTerms(e.target.checked)}
                                        data-qa="checkbox-agree"
                                    >
                                        I understand the known limitations and wish to proceed.
                                        <Button className="p-1" type="link" onClick={handleLimitationModal} data-qa="link-limitation-info">
                                            Read about limitations.
                                        </Button>
                                    </Checkbox>
                                </Form.Item>
                            )}
                            <Form.Item className="ml-auto" data-qa="form-item-submit">
                                <Button type="primary" htmlType="submit" disabled={submitDisabled} data-qa="submit-btn">
                                    Finish
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <Modal
                    title="Known Limitations"
                    visible={showLimitationModal}
                    onOk={handleLimitationModalOk}
                    onCancel={handleLimitationModalCancel} // Optional: If you want to enable modal cancel action
                    footer={[
                        <Button key="accept" type="primary" onClick={handleLimitationModalOk} data-qa="modal-btn-accept">
                            Accept
                        </Button>
                    ]}
                >
                    <p>{limitation}</p>
                </Modal>
            </div>
        </div >
    );
};
export default CreateConnections;
