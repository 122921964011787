import React from "react";
import { DatePicker, Skeleton, Typography } from 'antd';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip as RechartsTooltip } from "recharts";
import dayjs from 'dayjs';
import { UserOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const NewVsRepeatVisits = ({ newVsRepeatVisits, isLoading, dates, setDates, disableStartDate }) => {

  const data = newVsRepeatVisits;

  const CustomTooltip = ({ active, payload }) => {
    if (!(active && payload && payload.length)) return null;
    return (
      <div className="custom-tooltip bg-white p-4 rounded shadow-lg">
        <div>
          <Text className="font-semibold" style={{ color: '#287d9f' }}>
            {payload[0].payload.name}
          </Text>
          <p>
            {payload[0].payload.count} ({payload[0].payload.percentage}%)
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="card">
        <div className="card-header card-header-icon" data-background-color='red'>
          <UserOutlined className="text-white text-6xl" />
        </div>
        <div className="card-content">
          <div className="col">
            <h2 className="text-2xl font-semibold mb-7">New vs Repeat Visitors</h2>
            <div className="card-content">
              {/*<RangePicker
                value={[dates.startDate, dates.endDate]}
                onChange={(date, dateString) => setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) })}
                allowClear={false}
                size={"middle"}
                format={"YYYY-MM-DD"}
                disabledDate={(current) => current && current < dayjs(disableStartDate)}
                separator={<ArrowRightOutlined />}
              />*/}
            </div>
            <div>
              {!isLoading ?
                <div className="flex justify-center">
                  <div className="w-full lg:w-3/4">
                    <ResponsiveContainer width="100%" height={500}>
                      <PieChart>
                        <Pie
                          data={data}
                          dataKey="count"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={200}
                          innerRadius={150}
                          paddingAngle={2}
                        >
                          {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Legend height={36} />
                        <RechartsTooltip content={<CustomTooltip />} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                :
                <div className="flex justify-center">
                  <div className="w-full">
                    <div className="grid grid-cols-10 gap-2">
                      {[...Array(10)].map((_, i) => (
                        <Skeleton.Button key={i} active={true} size="large" shape="default" className="h-48" />
                      ))}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewVsRepeatVisits;
