let callback = process.env.AUTH0_CALLBACK_URL;
let usersAPI = process.env.USERS_API_URL;

let options = {
    theme: {
        logo: 'https://public.charitable.one/assets/branding/logo-rkd.svg',
        primaryColor: '#f44336'
    }
};

export const AUTH_CONFIG = {
    domain:         process.env.AUTH0_DOMAIN,
    clientId:       process.env.AUTH0_CLIENT_ID,
    callbackUrl:    callback,
    options:        options,
    usersAPI:       usersAPI
};