import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import dayjs from "dayjs";
import axios from 'axios';
import moment from 'moment';

//import components
import DashboardCard from '../../Components/DashbaordCard/DashboardCard';
import DashboardChart from '../../Components/DashboardChart/DashboardChart';
import { addLeadingZero } from '../../Helper/helper';

const AbandonedGiftsLightbox = ({
  dates
}) => {

  const { client, IdentityApi } = useSelector(({ main }) => main);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isArrayEmpty, setIsArrayEmpty] = useState(true);
  const [isPerformanceArrayEmpty, setIsPerformanceArrayEmpty] = useState(true)
  const [giftTab, setGiftTab] = useState({
    RechartGiftArray: [],
    onetime: "",
    intialSubscription: "",
  });
  const [revenueTab, setRevenueTab] = useState({
    RechartArray: [],
    onetime: "",
    intialSubscription: "",
  });
  const [performanceTab, setPerformanceTab] = useState({
    RechartPerformanceArray: [],
    onetime: "",
    intialSubscription: "",
  })
  const [isGiftArrayEmpty, setIsGiftArrayEmpty] = useState(true);
  const [cardsDetails, setCardsDetails] = useState({
    topChannel: {title:"Clicks", name: 0, totalAmount: 0, totalGift: 0 },
    donors: {title:"Donations", totalDonors: 0, avgGift: 0 },
    revenue: {
      title:"Donation Revenue",
      totalRevenue: 0,
      avgGift: 0,
      conversionRate: 0,
      totalOneTimeRevenue: 0,
      totalRecurringRevenue: 0,
    },
    viewsEngagedTotal: {
      title:"Conversion Rate", views: 0, engaged: 0 },
  });
  const [data, setData] = useState([]);
  
  useEffect(() => {
    handleCardAndChartData();
  }, [dates]);

  const handleCardAndChartData = async() =>{
    try {
      setIsLoading(true);
      // Parse start and end dates
      const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
      const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

      const dateDifference = moment(endDate).diff(moment(startDate), 'days');

      const formData = {
          start_date: dayjs(dates.startDate).format('YYYY/MM/DD'),
          end_date: dayjs(dates.endDate).format('YYYY/MM/DD'),
          client: client.toLowerCase(),
          url: 'abandonedcart_lb'
      };

      const results = await axios.post(`${IdentityApi}query/get_donations`, formData);

      if (results.data.status === 'success') {
          const countsData = results.data.onetime;
          const countsData1 = results.data.recurring;
          const countsData2 = results.data.click_event;
          const countsData3 = results.data.page_view;
          let Array = [], GiftArray = [], PerformanceArray = [], newAmount = 0, newAmount1 = 0; 
          let onetimeCount = 0, recurringCount = 0, click_count = 0, page_view_count = 0; 
          let isArrayEmpty = true, isGiftArrayEmpty = true, isPerformanceArrayEmpty = true;
          
          for (let i = 0; i <= dateDifference; i++) {
              const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
              let matchingData = countsData.find((res) => res.unixdate === currentDate);
              let matchingData1 = countsData1.find((res) => res.unixdate === currentDate);
              let matchingData2 = countsData2.find((res) => res.unixdate === currentDate);
              let matchingData3 = countsData3.find((res) => res.unixdate === currentDate);

              let onetimeAmount = matchingData ? matchingData.transaction_amount : 0;
              let RecurringAmount = matchingData1 ? matchingData1.transaction_amount : 0;

              onetimeCount =  matchingData ? onetimeCount += 1 : 0;
              recurringCount =  matchingData1 ? recurringCount += 1 : 0; 
              click_count =  matchingData2 ? click_count += 1 : 0; 
              page_view_count =  matchingData3 ? page_view_count += 1 : 0; 

              newAmount += onetimeAmount; 
              newAmount1 += RecurringAmount; 
              if (onetimeAmount > 0 || RecurringAmount > 0) {
                isArrayEmpty = false;
            }
            if (onetimeCount > 0 || recurringCount > 0) {
                isGiftArrayEmpty = false;
            }
           
            let calculateClick = click_count / page_view_count;
            let PerformanceClickRate =  calculateClick > 0 ? calculateClick : 0;

            let calculateCount = onetimeCount + recurringCount;
            let calcualtePageView =  calculateCount / click_count;
            let conversionRate = calcualtePageView > 0 ? calcualtePageView : 0;
            conversionRate = conversionRate === Infinity ? 0 : conversionRate;

            if (PerformanceClickRate > 0 || conversionRate > 0) {
              isPerformanceArrayEmpty = false;
          }

            Array.push({ date: currentDate, onetime: onetimeAmount, intialSubscription: RecurringAmount });
            GiftArray.push({ date: currentDate, onetime: onetimeCount, intialSubscription: recurringCount });
            PerformanceArray.push({ date: currentDate, onetime: PerformanceClickRate, intialSubscription: conversionRate });
          }
          setIsArrayEmpty(isArrayEmpty);
          setIsGiftArrayEmpty(isGiftArrayEmpty);
          setIsPerformanceArrayEmpty(isPerformanceArrayEmpty);
          setRevenueTab({RechartArray: Array })
          setGiftTab({RechartGiftArray: GiftArray })
          setPerformanceTab({RechartPerformanceArray: PerformanceArray })
            
            let count =  results.data.onetime ? results.data.onetime.length : 0;
            let count1 =  results.data.recurring ? results.data.recurring.length : 0;
            let newAmountTotal = newAmount + newAmount1;
            let totalCount = count + count1;
            let reveneue = totalCount ? newAmountTotal / totalCount : 0;
            let click_through_rate =  countsData2 ? countsData2.length : 0;
            let EstimatedImpression = countsData3 ? countsData3.length : 0;
            let conversionRate = totalCount > 0 ? totalCount / click_through_rate : 0;
            conversionRate = conversionRate === Infinity ? 0 : conversionRate;

          setCardsDetails({
            topChannel: {title:"Clicks", name: click_through_rate, totalAmount: 0, totalGift: 0 },
            donors: {title:"Donations", totalDonors: totalCount, avgGift: 0 },
            revenue: {
              title:"Donation Revenue",
              totalRevenue: addLeadingZero(reveneue),
              avgGift: 0,
              conversionRate: 0,
              totalOneTimeRevenue: 0,
              totalRecurringRevenue: 0,
            },
            viewsEngagedTotal: {
              title:"Conversion Rate", views: conversionRate, engaged: 0 },
          })
        }
        setIsLoading(false);
        }catch (error){
          setIsLoading(false);
          let Array = [];
          const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
          const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

          const dateDifference = moment(endDate).diff(moment(startDate), 'days');
          //calculate dashbard Rechart  
          for (let i = 0; i <= dateDifference; i++) {
              // Get the current date
              const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
              Array.push({ date: currentDate });
          }
          setData(Array)
          console.error("An error occurred:", error);
      }
  }
  return (
    <div className='abandoned-cart dynamic-card'>
      {client !== "NONE" && (
        <div className="dashboard">

          {/* Card Component */}
          <DashboardCard
            key={`dash-${counter}`}
            topChannel={cardsDetails.topChannel}
            donors={cardsDetails.donors}
            revenue={cardsDetails.revenue}
            pageViewTotal={cardsDetails.viewsEngagedTotal}
            isLoading={isLoading}
            type='AbandonedGiftsLightbox'
          />

          {/* Chart Component */}
          <DashboardChart
            key={`chart-${counter}`}
            revenueTab={revenueTab}
            performanceTab={performanceTab}
            isPerformanceArrayEmpty={isPerformanceArrayEmpty}
            isArrayEmpty={isArrayEmpty}
            giftTab={giftTab}
            isGiftArrayEmpty={isGiftArrayEmpty}
            endDate={dates.endDate}
            startDate={dates.startDate}
            type='AbandonedGiftsLightbox'
            isLoading={isLoading}
          />
        </div>
      )}

    </div>
  )
}
export default AbandonedGiftsLightbox;
