import React from 'react';


export const SkeletonLine = ({
    style
}) => {

    return (
        <div className="line" style={style}></div>
    );
}

export const SkeletonLogo = ({
    style
}) => {
    return(
        <div className="logo" style={style}></div>
    );
}