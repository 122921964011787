import DataTypes from "./type";

let initialState = {
    dataState : {
        initial: 'ready'
    },
};

const CIReducer = (state = initialState, action) => {

    switch (action.type) {

        case DataTypes.UPDATE_DATA_STATE:
            return {
                ...state,
                dataState: action.payload
            }

        default:
            return state;
    }

}

export default CIReducer;