import React from 'react';

function ReactTablePageResizer({ pageNumberCount, tablePages, pageSizeOptions, defaultPageSize, gotoPage, setPageSize, inputOnChange }) {
    const renderPageNumbers = () => {
        const pagesToShow = 5; // Number of page buttons to show
        const pageNumbers = [];
        
        if (tablePages <= pagesToShow) {
            // If total pages are less than or equal to pages to show, display all page numbers
            for (let i = 1; i <= tablePages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => inputOnChange(i)}
                        className={pageNumberCount === i ? '-pageNumber active' : '-pageNumber'}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            // If total pages are more than pages to show, display with ellipsis
            const currentPage = pageNumberCount;
            const totalPages = tablePages;
            const ellipsis = <span key="ellipsis">...</span>;
            let startPage, endPage;

            if (currentPage <= 3) {
                startPage = 1;
                endPage = pagesToShow;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - (pagesToShow - 1);
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => inputOnChange(i)}
                        className={pageNumberCount === i ? '-pageNumber active' : '-pageNumber'}
                    >
                        {i}
                    </button>
                );
            }

            if (startPage > 1) {
                if (startPage === 2) {
                    // Display the first page number if the ellipsis is adjacent to it
                    pageNumbers.unshift(
                        <button
                            key={1}
                            onClick={() => inputOnChange(1)}
                            className={pageNumberCount === 1 ? '-pageNumber active' : '-pageNumber'}
                        >
                            {1}
                        </button>
                    );
                } else {
                    let doc = document.getElementById('page_numbers');
                    let el = doc?.firstElementChild.innerHTML;
                    if (el !== "...") {
                        pageNumbers.unshift(ellipsis);
                        pageNumbers.unshift(
                            <button
                                key={1}
                                onClick={() => inputOnChange(1)}
                                className={pageNumberCount === 1 ? '-pageNumber active' : '-pageNumber'}
                            >
                                {1}
                            </button>
                        );
                    }
                    if (pageNumberCount == totalPages) {
                        if (el !== "..." && el !== "1") {
                            pageNumbers.unshift(ellipsis);
                        }
                    }
                }
            }

            if (endPage < totalPages) {
                if (endPage === totalPages - 1) {
                    // Display the last page number if the ellipsis is adjacent to it
                    pageNumbers.push(
                        <button
                            key={totalPages}
                            onClick={() => inputOnChange(totalPages)}
                            className={pageNumberCount === totalPages ? '-pageNumber active' : '-pageNumber'}
                        >
                            {totalPages}
                        </button>
                    );
                } else {
                    pageNumbers.push(ellipsis);
                    pageNumbers.push(
                        <button
                            key={totalPages}
                            onClick={() => inputOnChange(totalPages)}
                            className={pageNumberCount === totalPages ? '-pageNumber active' : '-pageNumber'}
                        >
                            {totalPages}
                        </button>
                    );
                }
            }
        }

        if (pageNumbers[0]?.key == 1) {
            let doc = document.getElementById('page_numbers')?.firstElementChild;
            if (doc?.innerHTML == "...") {
                doc.remove();
            }
        }
        let elm = document.getElementById('page_numbers')?.firstElementChild?.nextElementSibling?.nextElementSibling?.innerHTML;
        if (elm == 2) {
            let element = document.getElementById('page_numbers')?.firstElementChild?.nextElementSibling;
            if (element?.innerHTML == "...") {
                element.remove();
            }
        }
        return pageNumbers;
    };

    return (
        <div className='pagination-top'>
            <div className='-pagination -page'>
                <div className='-center'>
                    <span className='-pageInfo'>
                        <div className='-pageNumbers' id='page_numbers'>
                            {renderPageNumbers()}
                        </div>
                    </span>
                    {pageSizeOptions ?
                        <span className='select-wrap -pageSizeOptions'>
                            <select onChange={(e) => { setPageSize(e.target.value); gotoPage(0); }} defaultValue={defaultPageSize}>
                                {[5, 10, 20, 25, 50, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </span>
                        : ''
                    }
                </div>
            </div>
        </div>
    );
}

export default ReactTablePageResizer;
