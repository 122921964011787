// packages
import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Switch } from "antd";
import dayjs from "dayjs";


//import components
import { ChangeInitial } from "../../redux/Main/action";
import { setLocalStorageWithExpiry, getLocalStorage, checkAndCleanExpiredLocalStorage } from "../../Helper/helper";
import ClientChoose from "../Clients/chooseClient";
import DashboardCard from "../../Components/DashbaordCard/DashboardCard";
import DashboardChart from "../../Components/DashboardChart/DashboardChart";
import { IdentityApi } from "../../utils/constants";
import axios from "axios";

const { RangePicker } = DatePicker;

const Home = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { role, client } = useSelector(({ main }) => main);

  const [datePopup, setDatePopup] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [counter, setCounter] = useState(0);
  const [mode, setMode] = useState("live");
  const [dates, setDates] = useState({
    startDate: dayjs().subtract(30, "days"),
    endDate: dayjs().subtract(1, "days"),
  });
  const [isGiftArrayEmpty, setIsGiftArrayEmpty] = useState(true);
  const [giftTab, setGiftTab] = useState({
    RechartGiftArray: [],
    giftLeadingSource: "",
    giftLeadingCreative: "",
    giftPercentage: "",
    giftAverageAmount: "",
    totalGifts: "",
    giftsfirsttimedonorcount: "",
    giftsRecurring: "",
    GiftLeadingZipcode: "",
  });
  const [isArrayEmpty, setIsArrayEmpty] = useState(true);
  const [isSelect, setIsSelect] = useState(false);
  const [revenueTab, setRevenueTab] = useState({
    RechartArray: [],
    leadingCreative: "",
    leadingSource: "",
    revenuePercentage: "",
    revenueAverageAmount: "",
    revenueTotalAmount: "",
    RevenueLeadingZipcode: "",
  });
  const [cardsDetails, setCardsDetails] = useState({
    topChannel: { name: "-", totalAmount: 0, totalGift: 0 },
    donors: { totalDonors: 0, avgGift: 0 },
    revenue: {
      totalRevenue: 0,
      avgGift: 0,
      conversionRate: 0,
      totalOneTimeRevenue: 0,
      totalRecurringRevenue: 0,
    },
    viewsEngagedTotal: { views: 0, engaged: 0 },
  });

  let pageViewsEngaged20Total = {
    pageviews: 0,
    engaged_20: 0
  };

  useEffect(() => {
    dispatch(ChangeInitial("Home"));
  }, [dispatch]);

  useEffect(() => {
    fetchClientDashboardData();
  }, []);

  useEffect(() => {
    if (isSelect && !datePopup && dates.startDate && dates.endDate) {
      fetchClientDashboardData();
      setIsSelect(false);
    }
  }, [datePopup]);

  const fetchClientDashboardData = async () => {
    if (client === "NONE") {
      return;
    }

    setIsLoading(true);
    const tableData = {
      client: client, //clientName
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD"),
    };

    checkAndCleanExpiredLocalStorage('Home_');
    let cookieData = getLocalStorage(`Home_${tableData.startDate}_${tableData.endDate}_${tableData.client}`);
    if (cookieData) {
      setAllData(cookieData);
      return;
    }

    const cards = await axios.get(
      `${IdentityApi}donations/dashboard?start_date=${tableData.startDate}&end_date=${tableData.endDate}&client=${tableData.client}`
    );

    const chart = await axios.get(
      `${IdentityApi}donations/dashboard/chart?start_date=${tableData.startDate}&end_date=${tableData.endDate}&client=${tableData.client}`
    );

    const top_data = await axios.get(
      `${IdentityApi}donations/dashboard/chart/top-data?start_date=${tableData.startDate}&end_date=${tableData.endDate}&client=${tableData.client}`
    );

    if (
      chart.status === 200 && 
      cards.status === 200 &&
      top_data.status === 200
    ) {
      let dashboard_data = {
        'chart': chart.data,
        'cards': cards.data,
        'top_data': top_data.data,
      }

      setLocalStorageWithExpiry(`Home_${tableData.startDate}_${tableData.endDate}_${tableData.client}`, dashboard_data, 1);

      setAllData(dashboard_data);
    } else {
      window.swal({
        text: "No data found",
        type: "error",
        confirmButtonClass: "btn btn-success",
      });

      resetStateData();
      setIsLoading(false);
      setCounter(counter + 1);
    }
  };

  const setAllData = (clientDashboardData) => {
    setIsLoading(true);
    console.log("clientDashboardData", clientDashboardData);

    if (
      clientDashboardData.cards && 
      clientDashboardData.chart &&
      clientDashboardData.top_data
    ) {
      let cards = clientDashboardData.cards;
      let chart = clientDashboardData.chart;
      let top_data = clientDashboardData.top_data;

      cardsDetails["topChannel"].name = cards.CHANNEL_DATA.MOST_COMMON_CHANNEL != null ? cards.CHANNEL_DATA.MOST_COMMON_CHANNEL : "-";
      cardsDetails["topChannel"].totalAmount = cards.CHANNEL_DATA.TOTAL_AMOUNT != 0 ? cards.CHANNEL_DATA.TOTAL_AMOUNT : 0;
      cardsDetails["topChannel"].totalGift = cards.CHANNEL_DATA.TOTAL_GIFTS > 0 ? cards.CHANNEL_DATA.TOTAL_GIFTS : 0;

      cardsDetails["viewsEngagedTotal"].views = cards.PAGE_VIEW_DATA.PAGE_VIEWS;
      cardsDetails["viewsEngagedTotal"].engaged = cards.PAGE_VIEW_DATA.ENAGAGED_USERS;

      cardsDetails["donors"].totalDonors = cards.DONOR_DATA.TOTAL_DONORS > 0 ? cards.DONOR_DATA.TOTAL_DONORS : 0;
      cardsDetails["donors"].avgGift = cards.DONOR_DATA.AVG_DONATION != null ? cards.DONOR_DATA.AVG_DONATION : 0;
        
      cardsDetails["revenue"].totalRevenue = cards.DONATION_DATA.TOTAL_REVENUE != null ? cards.DONATION_DATA.TOTAL_REVENUE : 0;
      cardsDetails["revenue"].totalOneTimeRevenue = cards.DONATION_DATA.ONETIME_TOTAL ? cards.DONATION_DATA.ONETIME_TOTAL : 0;
      cardsDetails["revenue"].totalRecurringRevenue = cards.DONATION_DATA.RECURRING_TOTAL ? cards.DONATION_DATA.RECURRING_TOTAL : 0;

      setCardsDetails(cardsDetails);
      setCounter(counter + 1);

      setRevenueTab({
        RechartArray: chart.REVENUE,
        leadingCreative: top_data.TOP_EARNER.IDENTIFIER,
        leadingSource: top_data.TOP_EARNER.IDENTIFIER,
        RevenueLeadingZipcode: top_data.TOP_EARNER.IDENTIFIER,
        revenueAverageAmount: top_data.TOP_EARNER.AVERAGE_DONATION,
        revenueTotalAmount: top_data.TOP_EARNER.TOTAL_EARNED,
      });

      setGiftTab({
        RechartGiftArray: chart.GIFTS,
        giftLeadingCreative: top_data.TOP_GIFTS.IDENTIFIER,
        giftLeadingSource: top_data.TOP_GIFTS.IDENTIFIER,
        GiftLeadingZipcode: top_data.TOP_GIFTS.IDENTIFIER,
        giftAverageAmount: top_data.TOP_GIFTS.AVERAGE_DONATION,
        totalGifts: top_data.TOP_GIFTS.TOTAL_GIFTS,
      });

      if (chart.HAS_REVENUE) {
        setIsArrayEmpty(false);
        setIsGiftArrayEmpty(false);
      }

      setIsLoading(false);
    }
  };

  const handleDateChange = (date, dateString) => {
      if (dateString && dateString[0] && dateString[1]) {
          setIsSelect(true);
          setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) });
      }
  }

  const handle = async (val) => {
      setDatePopup(val);
  }

  // for reset state value
  const resetStateData = () => {
    setCardsDetails({
      topChannel: { name: "-", totalAmount: 0, totalGift: 0 },
      donors: { totalDonors: 0, avgGift: 0 },
      revenue: {
        totalRevenue: 0,
        avgGift: 0,
        conversionRate: 0,
        totalOneTimeRevenue: 0,
        totalRecurringRevenue: 0,
      },
      viewsEngagedTotal: { views: pageViewsEngaged20Total.pageviews, engaged: pageViewsEngaged20Total.engaged_20 },
    });
    setIsArrayEmpty(true);
    setIsGiftArrayEmpty(true);
    setRevenueTab({
      RechartArray: [],
      leadingCreative: "",
      leadingSource: "",
      revenueAverageAmount: 0,
      revenueTotalAmount: 0,
      RevenueLeadingZipcode: "",
    });
    setGiftTab({
      RechartGiftArray: [],
      giftLeadingSource: "",
      giftLeadingCreative: "",
      giftAverageAmount: 0,
      totalGifts: 0,
      GiftLeadingZipcode: "",
    });
    setCounter(counter + 1);
  };

  // Mode change 'live' and 'test' default live.
  const handleChangeMode = (event) => {
    let modeNew = event === true ? "live" : "test";
    setMode(modeNew);
    if (modeNew === "test") {
      resetStateData();
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setAllData();
    }
  };

  return (
    <>
      {isAuthenticated && role === "admin" && (
        <ClientChoose alertType="alert alert-success">
          <span>
            Welcome <strong>Team Member</strong>. Please choose a client to
            view/edit their data.
          </span>
        </ClientChoose>
      )}
      {client !== "NONE" && (
        <div className="dashboard">
          {/* Date Range Implimented for Card and Chart */}
          <div className="date-range card">
            <div className="card-content">
              <div className="row">
                <div className="col-md-9 col-sm-9">
                  <RangePicker
                    defaultValue={[dates.startDate, dates.endDate]}
                    onChange={handleDateChange}
                    onOpenChange={handle}
                    allowClear={false}
                    size={"middle"}
                    variant="borderless"
                    format={"MMMM D, YYYY"}
                    separator={
                      <i className="material-icons">arrow_right_alt</i>
                    }
                  />
                </div>
                <div className="col-md-3 col-sm-3 text-right date-range">
                  <p className="transaction-data-mode-switch">
                    <Switch
                      size={"default"}
                      checked={mode === "live" ? true : false}
                      onChange={handleChangeMode}
                    />{" "}
                    Live data
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Card Component */}
          <DashboardCard
            key={`dash-${counter}`}
            topChannel={cardsDetails.topChannel}
            donors={cardsDetails.donors}
            revenue={cardsDetails.revenue}
            pageViewTotal={cardsDetails.viewsEngagedTotal}
            isLoading={isLoading}
          />

          {/* Chart Component */}
          <DashboardChart
            key={`chart-${counter}`}
            revenueTab={revenueTab}
            isArrayEmpty={isArrayEmpty}
            giftTab={giftTab}
            isGiftArrayEmpty={isGiftArrayEmpty}
            endDate={dates.endDate}
            startDate={dates.startDate}
            isLoading={isLoading}
          />

        </div>
      )}
    </>
  );
};

export default Home;