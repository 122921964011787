import React from 'react'
import DeleteAction from './DeleteAction';
import DownloadAction from './LoadSearchAction';
import ReactTbl from '../../UI/reactTbl';

const StoredSearchTable = ({ data, isLoading, load_search, delete_search }) => {

    const active_columns = [
        {
            Header: 'Data',
            width: 635,
            columns: [
                {
                    Header: 'Type',
                    accessor: 'type',
                    Cell: row => (<strong>{row.value}</strong>),
                    width: 166,
                },
                {
                    Header: 'Name',
                    accessor: 'name',
                    Cell: row => (<strong>{row.value}</strong>),
                    width: 166,
                },
                {
                    Header: 'Date',
                    accessor: 'date',
                    Cell: row => (<strong>{row.value}</strong>),
                    width: 166,
                },
                {
                    Header: 'Load',
                    // accessor: 'hash',
                    Cell: row => (
                        <DownloadAction row={row.row.original} load_search={load_search} />
                    ),
                    width: 70,
                },
                {
                    Header: 'Delete',
                    // accessor: 'hash',
                    Cell: row => (
                        <DeleteAction row={row.row.original} delete_search={delete_search} />
                    ),
                    width: 70,
                }
            ]
        },
    ];

    return (
        <div style={{ width: "655px" }}>
            <ReactTbl
                id="stored_searches"
                data={data}
                columns={active_columns}
                defaultPageSize={5}
                minRows={3}
                isLoading={isLoading}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value)}
                showPaginationOnTop={false}
                showPaginationOnBottom={true}
            />
        </div>
    )
}

export default StoredSearchTable;