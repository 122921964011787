import React, { useState, useEffect } from "react";
import { DatePicker, Button, Switch, Input, Row, Col, Modal } from "antd";
import {
    DatabaseOutlined,
    DownloadOutlined,
    LoadingOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Pane, SideSheet, Card as Cards, Positioner } from "evergreen-ui";
import { useSelector } from "react-redux";
import { debounce } from 'lodash';

// Import NPM Libraries
import axios from "axios";
import swal from 'sweetalert';

// for handling RangePicker Dates
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DashbaordCard from "../UI/dashboardCards";

// Helper Functions
import { commas, setLocalStorageWithExpiry, getLocalStorage, checkAndCleanExpiredLocalStorage } from "../../Helper/helper";

// Table component
import ReactTable from "../../Components/ReactTablePagination/ReactTable";
import StoredSearchTable from "../ClientData/StoredSearch/StoredSearchTable";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc)
dayjs.extend(timezone)

const { RangePicker } = DatePicker;
let source = axios.CancelToken.source();

const DynamicCards = ({
    title,
    background,
    formType,
    columns,
    card,
    customDateRange
}) => {

    const { client, IdentityApi } = useSelector(({ main }) => main);

    const [isLoading, setIsLoading] = useState(false);
    const [sideLoader, setSideLoader] = useState(false);
    const [mode_search, setModeSearch] = useState("date");
    const [mode_text, setModeText] = useState("Date");
    const [startDate, setStartDate] = useState(customDateRange?.start?.format("YYYY-MM-DD") ?? dayjs().tz("America/New_York").subtract(6, 'd').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(customDateRange?.end?.format("YYYY-MM-DD") ?? dayjs().tz("America/New_York").format("YYYY-MM-DD"));
    const [date_range, setDateRange] = useState(customDateRange ?? {
        start: dayjs().tz("America/New_York").subtract(6, 'd'),
        end: dayjs().tz("America/New_York")
    });

    // Advance Search States
    const [ga_id, setGaid] = useState("");
    const [person_id, setPersonId] = useState("");
    const [session_id, setSessionId] = useState("");
    const [utm_campaign, setUtmCampaign] = useState("");
    const [utm_source, setUtmSource] = useState("");
    const [utm_medium, setUtmMedium] = useState("");
    const [campaign_name, setCampaignName] = useState("");
    const [supporter_id, setSupporterId] = useState("");
    const [email, setEmail] = useState("");

    // Stored Results States
    const [stored_searches, setStoredSearches] = useState([]);
    const [search_range, setSearchRange] = useState('Loading');

    // DashbaordCard States
    const [revenue, setRevenue] = useState("");
    const [mobile_count, setMobileCount] = useState(0);
    const [tablet_count, setTabletCount] = useState(0);
    const [desktop_count, setDesktopCount] = useState(0);

    // Side Sheet States
    const [isShown, setIsShown] = useState(false);
    const [sideTableLoading, setSideTableLoading] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const [pageNumberCount, setPageNumberCount] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [active_data, setActiveData] = useState([]);
    const [tablePages, setTablePages] = useState(0);

    // Initial Check
    const [resetFilter, setResetFilter] = useState(false);

    useEffect(() => {
        fetchData(1, []);
    }, []);

    const handleDateChanges = (date, dateString) => {
        setDateRange({ start: date && date[0], end: date && date[1] });
        setStartDate(dateString && dayjs(dateString[0]).format("YYYY-MM-DD"));
        setEndDate(dateString && dayjs(dateString[1]).format("YYYY-MM-DD"));
    };

    const search_mode = (checked) => {
        if (checked) {
            setModeText("Advanced");
            setModeSearch("advanced");
        } else {
            setModeText("Date");
            setModeSearch("date");
        }
    };

    const show_error = (error, message) => {
        Modal.error({ title: "Error: " + error, content: message });
    };

    const save_search = () => {
        if (active_data.length <= 0) {

            show_error('Not a valid search', 'Please run a search before trying to save results.');
            return;

        }

        window.swal({
            text: "What would you like to name this search?",
            content: "input",
            buttons: { confirm: { value: '' } }
        }).then(async (value) => {

            if (!value) {

                window.swal({ text: 'Please enter a name for this search.', });

            } else {

                let actionUrl = `${IdentityApi}files/get_stored_names?client=${client}`;

                let validateNames = await axios.get(actionUrl)

                const { message: storedNames } = validateNames.data;

                if (storedNames.includes(value)) {
                    swal("ERROR", "Same name already exists. Try some other name.", "error", { closeOnClickOutside: false });
                } else {

                    let url = `${IdentityApi}files/save_search`;

                    // eslint-disable-next-line
                    let regExp = /[^a-zA-Z0-9 \-\/]/

                    if (regExp.test(value)) {

                        show_error('Not a valid search', 'Please enter valid name');
                        return;

                    }

                    const fromResolve = await axios.get(url, {
                        params: {
                            name: value.toLowerCase().replace(/ /g, '_'),
                            type: formType,
                            searchParams: JSON.stringify(searchParams),
                            counts: JSON.stringify({
                                revenue: revenue,
                                mobile_count: mobile_count,
                                tablet_count: tablet_count,
                                desktop_count: desktop_count,
                                tablePages: tablePages,
                                searchRange: search_range
                            }),
                            client: client
                        }
                    });

                    const { status, message } = fromResolve.data;

                    if (status === 'success') {
                        window.swal({ text: message, });
                    } else {
                        window.swal({ text: message, });
                    }

                }

            }

        });
    }

    const getSaveResult = () => {
        setIsShown(true);
        setSideTableLoading(true);

        let url = `${IdentityApi}files/get_search_list?client=${client}`;

        axios.get(url).then((fromResolve) => {

            if (fromResolve.data.status === 'success') {

                let results = [];
                fromResolve.data.message.forEach((search) => { if (search.type === formType) { results.push(search); } });
                setStoredSearches(results);
                setSideTableLoading(false);

            } else {

                show_error("Fetch Search List", "We could not load your data. Please try again");
                setSideTableLoading(false);

            }

        }).catch(() => {

            show_error("Fetch Search List", "We could not load your data. Please try again");
            setSideTableLoading(false);

        });


    }

    const handleSearch = () => {
        if (startDate === "" || endDate === "") {
            show_error(
                "No Date Selected",
                "Please select a date range before searching."
            );
            return;
        }

        setResetFilter(true);
        setPageNumberCount(1);
        fetchData(1, []);
    };

    // Fetch data through paggination.
    const fetchData = async (page, filter_data = []) => {
        setTimeout(() => {
            debouncedFetchData(page, filter_data);
        }, 200);
    }

    const fetchDataAjaxCall = async (page, filter_data) => {
        if (source) {
            source.cancel("Previous request cancelled");
        }
        
        source = axios.CancelToken.source();

        setIsLoading(true);

        checkAndCleanExpiredLocalStorage('Engaged_');
        let cookieData = getLocalStorage(`Engaged_${startDate}_${endDate}_${client}`);
        if (cookieData && filter_data.length == 0 && page == 1) {
            setActiveData(cookieData.engaged.records);
            setTablePages(cookieData.engaged.pages);

            setMobileCount(cookieData.device.mobile_count);
            setTabletCount(cookieData.device.tablet_count);
            setDesktopCount(cookieData.device.desktop_count);
            setSearchRange(`${startDate} - ${endDate}`);

            setIsLoading(false);
            return;
        }

        try {
            let url_type = "engagement";
            // Will use later when signup success is implemented
            if (formType === "engaged_20") {
                
            }

            let formData = {
                limit: 10,
                offset: 10 * (page - 1),
                filter: filter_data,
            };

            let url = `${IdentityApi}${url_type}?start_date=${startDate}&end_date=${endDate}&client=${client}`;

            const response = await axios.post(url, formData, { cancelToken: source.token });

            const { status, data } = response;

            if (status === 200) {
                setActiveData(data.engaged.records);
                setTablePages(data.engaged.pages);

                setMobileCount(data.device.mobile_count);
                setTabletCount(data.device.tablet_count);
                setDesktopCount(data.device.desktop_count);
                setSearchRange(`${startDate} - ${endDate}`);

                if (filter_data.length == 0 && page == 1) {
                    setLocalStorageWithExpiry(`Engaged_${startDate}_${endDate}_${client}`, data, 1);
                }

                setIsLoading(false);
            } else {
                window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }

    const debouncedFetchData = debounce(fetchDataAjaxCall, 200);

    const inputOnChange = (count) => {
        if (count > 0 && count <= tablePages) {
            setPageNumberCount(count);
            fetchData(count, filteredData);
        }
    }

    const filterOnChange = (count, data) => {
        if (resetFilter && data.length > 0) {
            setResetFilter(false);
            setFilteredData([]);
            return;
        }

        setFilteredData(data);
        setPageNumberCount(1);
        fetchData(count, data);
    }

    const nextPageEvent = (page) => {
        if (tablePages > pageNumberCount + 1) {
            setPageNumberCount(prev => prev + 1);
            fetchData(pageNumberCount + 1, []);
        }
    }

    const previousPageEvent = (page) => {
        if (pageNumberCount - 1 > 0) {
            setPageNumberCount(prev => prev - 1);
            fetchData(pageNumberCount - 1, []);
        }
    }

    const load_search = (event) => {
        let id = event.hash;
        setIsLoading(true);
        setIsShown(false);
        setResetFilter(true);

        let isAdvanceSearch = false;

        let url = `${IdentityApi}files/get_search?hash=${id}&client=${client}`;

        axios.get(url).then((fromResolve) => {

            if (fromResolve.data.status === 'success') {

                let tempSearchParams = JSON.parse(fromResolve.data.data.searchParams);
                let searchCounts = JSON.parse(fromResolve.data.data.counts);
                let params = { type: tempSearchParams.type, eventData: "search" };

                if (tempSearchParams.ga_id) {
                    setGaid(tempSearchParams.ga_id);
                    params.ga_id = tempSearchParams.ga_id;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.person_id) {
                    setPersonId(tempSearchParams.person_id);
                    params.person_id = tempSearchParams.person_id;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.session_id) {
                    setSessionId(tempSearchParams.session_id);
                    params.session_id = tempSearchParams.session_id;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.utm_campaign) {
                    setUtmCampaign(tempSearchParams.utm_campaign);
                    params.utm_campaign = tempSearchParams.utm_campaign;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.utm_source) {
                    setUtmSource(tempSearchParams.utm_source);
                    params.utm_source = tempSearchParams.utm_source;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.utm_medium) {
                    setUtmMedium(tempSearchParams.utm_medium);
                    params.utm_medium = tempSearchParams.utm_medium;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.campaign_name) {
                    setCampaignName(tempSearchParams.campaign_name);
                    params.campaign_name = tempSearchParams.campaign_name;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.supporter_id) {
                    setSupporterId(tempSearchParams.supporter_id);
                    params.supporter_id = tempSearchParams.supporter_id;
                    isAdvanceSearch = true;
                }

                if (tempSearchParams.email) {
                    setEmail(tempSearchParams.email);
                    params.email = tempSearchParams.email;
                    isAdvanceSearch = true;
                }

                setModeSearch("date");
                setModeText("Date");

                setDateRange({
                    start: dayjs(tempSearchParams.date_start),
                    end: dayjs(tempSearchParams.date_end)
                });

                setStartDate(tempSearchParams.date_start);
                setEndDate(tempSearchParams.date_end);

                params.date_start = tempSearchParams.date_start;
                params.date_end = tempSearchParams.date_end;
                params.client = client;

                setSearchParams(params);

                url = `${IdentityApi}query/get_event`;

                axios.get(url, { params }).then((fromResolve) => {

                    if (fromResolve.data.status === 'success') {

                        setActiveData(fromResolve.data.data.data);
                        setSearchRange(`${searchCounts.searchRange}`);
                        setTablePages(searchCounts.tablePages);
                        setRevenue(searchCounts.revenue);
                        setMobileCount(searchCounts.mobile_count);
                        setTabletCount(searchCounts.tablet_count);
                        setDesktopCount(searchCounts.desktop_count);
                        setModeSearch('advanced');
                        setPageNumberCount(1);
                        setIsLoading(false);

                        if (isAdvanceSearch) {

                            setModeSearch("advanced");
                            setModeText("Advanced")

                        } else {

                            setModeSearch("date");
                            setModeText("Date");

                        }

                    } else {
                        setIsLoading(false);
                        window.swal({ title: "Error", text: 'No data found.', icon: "error", button: "OK" });
                    }

                }).catch((error) => {
                    setIsLoading(false);
                    setActiveData([]);
                    setTablePages(1);
                    console.log(error);

                });


            } else {
                setIsLoading(false);
                setActiveData([]);
                setTablePages(1);
            }

        }).catch((error) => {
            setIsLoading(false);
            setActiveData([]);
            setTablePages(1);
            console.log(error);
        });


    }

    const delete_search = (event) => {
        let url = `${IdentityApi}files/delete_search?hash=${event.hash}&client=${client}`;

        window.swal("Are you sure you want to delete this stored search?", {
            buttons: {
                no: { text: "No, cancel", value: "cancel" },
                publish: { text: "Yes, delete it", value: "yes" },
            }
        }).then((value) => {

            if (value === 'yes') {

                axios.get(url).then((fromResolve) => {

                    window.swal({ text: fromResolve.data.message, });
                    setIsShown(false);

                });

            } else {

                setIsShown(false);

            }

        });

    }

    const downloadFull = async (file) => {
        let url = `${IdentityApi}files/get_file_download?file=${file}&client=${client}`;

        const response = await axios.get(url);

        const { status, data } = response;

        if (status === 200) {

            try {

                if (data.status === 'success') {

                    let hiddenElement = document.createElement('a');

                    hiddenElement.href = data.message;

                    hiddenElement.target = '_blank';

                    hiddenElement.download = 'fullResults.csv';

                    document.body.appendChild(hiddenElement);

                    hiddenElement.click();

                    document.body.removeChild(hiddenElement);

                } else {

                    window.swal({ title: "Error", text: 'We could not find your result file', icon: "error", button: "OK" });

                }

            } catch (error) {

                window.swal({ title: "Error", text: 'We could not find your result file', icon: "error", button: "OK" });

            }

        } else {

            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });

        }

    }

    return (
        <div className="utm-leader-board-container">
            <div className="card">
                <div
                    className="card-header card-header-icon"
                    data-background-color="red"
                    style={{ background: background }}
                >
                    <span className="icon-title-text">{title}</span>
                </div>

                <div className="card-content">
                    <div className="row card-title mr-5"></div>
                    <div className="row transaction-range-container">
                        <div class="flex flex-wrap">
                            <div class="w-full">
                                <div class="flex flex-wrap">
                                    <div class="flex w-full">
                                        <div class="w-1/2">
                                            {mode_text === "Date" ? (
                                                <RangePicker
                                                    defaultValue={[date_range.start, date_range.end]}
                                                    value={[date_range.start, date_range.end]}
                                                    onChange={(date, dateString) =>
                                                        handleDateChanges(date, dateString)
                                                    }
                                                    allowClear={false}
                                                    size="large"
                                                    bordered="false"
                                                    separator={
                                                        <i className="material-icons">arrow_right_alt</i>
                                                    }
                                                />
                                            ) : (
                                                <span id="advanced_search">
                                                    <div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                <strong>Google ID:</strong> &nbsp;
                                                            </label>
                                                        </div>

                                                        <div className="col-md-8 advance_field">
                                                            <Input
                                                                onChange={(e) => setGaid(e.target.value)}
                                                                size="large"
                                                                value={ga_id}
                                                                defaultValue=""
                                                                placeholder="Google ID"
                                                                id="ga_id"
                                                                style={{ width: "260px" }}
                                                            />
                                                            (optional)
                                                        </div>

                                                        {(formType === "onetime_donation" ||
                                                            formType === "recurring_donation") && (
                                                                <div className="col-md-4">
                                                                    <label>
                                                                        <strong>Campaign Name:</strong> &nbsp;
                                                                    </label>
                                                                </div>
                                                            )}

                                                        {(formType === "onetime_donation" ||
                                                            formType === "recurring_donation") && (
                                                                <div className="col-md-8 advance_field">
                                                                    <Input
                                                                        onChange={(e) => setCampaignName(e.target.value)}
                                                                        value={campaign_name}
                                                                        size="large"
                                                                        defaultValue=""
                                                                        placeholder="Campaign Name"
                                                                        id="campaign_name"
                                                                        style={{ width: "260px" }}
                                                                    />
                                                                    (optional)
                                                                </div>
                                                            )}

                                                        {formType === "general_email_submitted" && (
                                                            <div className="col-md-4">
                                                                <label>
                                                                    <strong>Email ID:</strong> &nbsp;
                                                                </label>
                                                            </div>
                                                        )}

                                                        {formType === "general_email_submitted" && (
                                                            <div className="col-md-8 advance_field">
                                                                <Input
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    value={email}
                                                                    size="large"
                                                                    defaultValue=""
                                                                    placeholder="Email ID"
                                                                    id="email"
                                                                    style={{ width: "260px" }}
                                                                />
                                                                (optional)
                                                            </div>
                                                        )}

                                                        {(formType === "general_email_submitted" ||
                                                            formType === "abandoned_cart") && (
                                                                <div className="col-md-4">
                                                                    <label>
                                                                        <strong>Session ID:</strong> &nbsp;
                                                                    </label>
                                                                </div>
                                                            )}

                                                        {(formType === "general_email_submitted" ||
                                                            formType === "abandoned_cart") && (
                                                                <div className="col-md-8 advance_field">
                                                                    <Input
                                                                        onChange={(e) => setSessionId(e.target.value)}
                                                                        value={session_id}
                                                                        size="large"
                                                                        defaultValue=""
                                                                        placeholder="Session ID"
                                                                        id="session_id"
                                                                        style={{ width: "260px" }}
                                                                    />
                                                                    (optional)
                                                                </div>
                                                            )}

                                                        <div className="col-md-4">
                                                            <label>
                                                                <strong>UTM Campaign:</strong> &nbsp;
                                                            </label>
                                                        </div>

                                                        <div className="col-md-8 advance_field">
                                                            <Input
                                                                onChange={(e) => setUtmCampaign(e.target.value)}
                                                                value={utm_campaign}
                                                                size="large"
                                                                defaultValue=""
                                                                placeholder="UTM Campaign"
                                                                id="utm_campaign"
                                                                style={{ width: "260px" }}
                                                            />
                                                            (optional)
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>
                                                                <strong>UTM Source:</strong> &nbsp;
                                                            </label>
                                                        </div>

                                                        <div className="col-md-8 advance_field">
                                                            <Input
                                                                onChange={(e) => setUtmSource(e.target.value)}
                                                                value={utm_source}
                                                                size="large"
                                                                defaultValue=""
                                                                placeholder="UTM Source"
                                                                id="utm_source"
                                                                style={{ width: "260px" }}
                                                            />
                                                            (optional)
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>
                                                                <strong>UTM Medium:</strong> &nbsp;
                                                            </label>
                                                        </div>

                                                        <div className="col-md-8 advance_field">
                                                            <Input
                                                                onChange={(e) => setUtmMedium(e.target.value)}
                                                                value={utm_medium}
                                                                size="large"
                                                                defaultValue=""
                                                                placeholder="UTM Medium"
                                                                id="utm_medium"
                                                                style={{ width: "260px" }}
                                                            />
                                                            (optional)
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>
                                                            <strong>Dates:</strong>{" "}
                                                        </label>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <RangePicker
                                                            defaultValue={[date_range.start, date_range.end]}
                                                            allowClear={false}
                                                            size="large"
                                                            onChange={(date, dateString) =>
                                                                handleDateChanges(date, dateString)
                                                            }
                                                            bordered="false"
                                                            separator={
                                                                <i className="material-icons">arrow_right_alt</i>
                                                            }
                                                            style={{ width: "260px" }}
                                                        />
                                                        &nbsp;{" "}
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "24px",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </span>
                                            )}
                                            <div className="col-md-12 donation-buttons">
                                                <div className="flex flex-wrap p-2">
                                                    <Button
                                                        type="default"
                                                        icon={<SearchOutlined />}
                                                        size="large"
                                                        className="w-1/2 btn-generate-export searh-button"
                                                        onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </Button>
                                                    {/* <Button
                                                        type="default"
                                                        icon={<DatabaseOutlined />}
                                                        size="large"
                                                        className="w-1/2 btn-generate-export search-results-button"
                                                        onClick={() => save_search()}
                                                    >
                                                        Save Results
                                                    </Button> */}
                                                </div>
                                                {/* <div class="flex flex-wrap">
                                                    <div className="toggle-search">
                                                        <div>
                                                            Toggle search modes: &nbsp;&nbsp;
                                                            <strong>{mode_text} Search:</strong> &nbsp;
                                                            {mode_text === "Date" && (
                                                                <Switch size="large" onChange={search_mode} />
                                                            )}
                                                            {mode_text === "Advanced" && (
                                                                <Switch
                                                                    size="large"
                                                                    defaultChecked
                                                                    onChange={search_mode}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="w-1/2"> {/* Adjust the width as needed */}
                                            <div className="w-full">
                                                <div className="sub-cards-block flex flex-row">
                                                    <div className="card-title mr-5"></div>
                                                    <div className="sub-dynamic-cards w-full -my-12">
                                                        {/* Button code commented out */}
                                                        <DashbaordCard
                                                            cards={card}
                                                            range={search_range}
                                                            revenueData={revenue}
                                                            mobileCount={mobile_count}
                                                            tabletCount={tablet_count}
                                                            desktopCount={desktop_count}
                                                            isLoading={sideLoader}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="w-full flex justify-end">
                                        <div className="viewButton">
                                            View saved results: &nbsp;
                                            <Button
                                                type="default"
                                                icon={<DatabaseOutlined />}
                                                size="medium"
                                                onClick={() => getSaveResult()}
                                                className="w-full text-[1.1vw] h-9"
                                            >
                                                Saved Results
                                            </Button>
                                        </div>
                                    </div> */}
                                </div>
                                <div class="flex flex-wrap w-full">
                                    <ReactTable
                                        data={active_data}
                                        columns={columns}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        inputOnChange={inputOnChange}
                                        filterOnChange={filterOnChange}
                                        filteredData={filteredData}
                                        nextPageEvent={nextPageEvent}
                                        previousPageEvent={previousPageEvent}
                                        setPageNumberCount={setPageNumberCount}
                                        defaultPageSize={10}
                                        tablePages={tablePages}
                                        pageNumberCount={pageNumberCount}
                                        showPaginationOnBottom={true}
                                        showFilter={true}
                                        resetFilter={resetFilter}
                                        className="table-auto"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <SideSheet
                isShown={isShown}
                position={Positioner.TOP}
                width={700}
                onCloseComplete={() => setIsShown(false)}
                containerProps={{ display: "flex", flex: "1", flexDirection: "column" }}
            >
                <Pane flex="1" overflowY="scroll" appearance="tint1" padding={16}>
                    <Cards
                        backgroundColor="white"
                        elevation={0}
                        display="block"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <h2 style={{ padding: "10px", textAlign: "center" }}>Stored Search Results</h2>

                        <StoredSearchTable
                            id="stored_searches"
                            data={stored_searches}
                            isLoading={sideTableLoading}
                            load_search={load_search}
                            delete_search={delete_search}
                        />

                    </Cards>
                </Pane>
            </SideSheet>
        </div>
    );
};

export default DynamicCards;