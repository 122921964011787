import React from "react";
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import EventBarChart from "./EventBarChart";
import EventPieChart from "./EventPieChart";
import EventLineChart from "./EventLineChart";

const SignupEventChart = ({
    eventType,
    data = [],
    ApiData = [],
    isLoading = false
}) => {

    return (
        <div className="row avg-chart">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color='red'>
                        {eventType === "total_signups_over_time" && <i className="material-icons">timeline</i>}
                        {eventType === "signup_conversion_rate_by_campaign" && <i className="material-icons">bar_chart</i>}
                        {eventType === "signup_source_distribution" && <i className="material-icons">pie_chart</i>}
                        {eventType === "subscriber_growth_rate" && <i className="material-icons">timeline</i>}
                    </div>
                    <div className="card-content">
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-md-offset-1">
                                {eventType === "total_signups_over_time" &&
                                    <h4 className="card-title">Total Sign-ups Over Time</h4>
                                }
                                {eventType === "signup_conversion_rate_by_campaign" &&
                                    <h4 className="card-title">Sign-up Conversion Rate by Campaign</h4>
                                }
                                {eventType === "signup_source_distribution" &&
                                    <h4 className="card-title">Sign-up Source Distribution</h4>
                                }
                                {eventType === "subscriber_growth_rate" &&
                                    <h4 className="card-title">Subscriber Growth Rate</h4>
                                }
                            </div>
                        </div>
                        {!isLoading ?
                            <div className="row">
                                <div className={`col-md-12 line-chart-container`}>
                                    <div className='col-md-12'>
                                        <div className='charts'>
                                            {eventType === "total_signups_over_time" &&
                                                <EventLineChart data={data} ApiData={ApiData} type={eventType} />}
                                            {eventType === "signup_conversion_rate_by_campaign" &&
                                                <EventBarChart data={data} ApiData={ApiData} />}
                                            {eventType === "signup_source_distribution" &&
                                                <EventPieChart data={data} />}
                                            {eventType === "subscriber_growth_rate" &&
                                                <EventLineChart data={data} ApiData={ApiData} type={eventType} />}
                                        </div>
                                        {Object.keys(ApiData).length === 0 && (
                                            <div className='rechart-text' style={{ top: eventType === "signup_source_distribution" ? "45%" : "50%", left: eventType === "signup_source_distribution" ? "50%" : "55%" }}>No Data Found</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="skeleton skeleton-chart">
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>)
}

export default SignupEventChart;
