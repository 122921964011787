import React from "react";
import { Skeleton } from 'antd';
import PieCharts from "./PieCharts";
import StateTable from "../../Views/TrafficTrends/StateTable";
import TrendLineCharts from "./TrendLineCharts";

const TrafficCards = ({
    headerIcon,
    title,
    trafficType,
    data = [],
    filter_dates,
    isValid = false,
    isLoading = false,
}) => {
    return (
        <div className={`p-6 flex flex-wrap traffic-trends-container ${trafficType === "device_location" ? "state-chart" : ""}`}>
            <div className="w-full">
                <div className="relative shadow-md rounded-lg">
                    <div className="absolute text-white -top-4 left-4 p-4 z-50 shadow-lg rounded" style={{ background: "linear-gradient(60deg, rgb(0, 98, 114), rgb(0, 59, 73))" }}>
                        <div className="text-6xl  p-2 rounded-full" style={{ background: "linear-gradient(60deg, rgb(0, 98, 114), rgb(0, 59, 73))" }}>
                            {headerIcon}
                        </div>
                    </div>
                    <div className="bg-white shadow-lg rounded-lg pt-4 h-full flex flex-col justify-between">
                        <div className="p-4">
                            <div className="flex flex-col items-start pl-32">
                                <h2 className="text-2xl font-semibold mb-7">{title}</h2>
                            </div>
                            {!isLoading ?
                                <div className="flex flex-wrap justify-center gap-4">
                                    <div className="w-full">
                                        <div className='charts'>
                                            {trafficType === "page_depth" &&
                                                <TrendLineCharts
                                                    data={data}
                                                    type={trafficType}
                                                    ApiData={data}
                                                    isValid={isValid}
                                                />
                                            }
                                            {trafficType === "engagement_rate" &&
                                                <TrendLineCharts
                                                    data={data}
                                                    type={trafficType}
                                                    ApiData={data}
                                                    isValid={isValid}
                                                />
                                            }
                                            {trafficType === "device_category" &&
                                                <PieCharts
                                                    data={data}
                                                    isValid={isValid}
                                                    filter_dates={filter_dates}
                                                />
                                            }
                                            {trafficType === "device_location" &&
                                                <StateTable
                                                    data={data}
                                                    isValid={isValid}
                                                    trafficType={trafficType}
                                                    isLoading={isLoading}
                                                />
                                            }
                                        </div>
                                        {/* Display the "No Data Found" message */}
                                        {!isValid && trafficType !== "device_location" && (
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <div
                                                    className={`${trafficType === "device_category"
                                                        ? "rechart-text-chart"
                                                        : "rechart-text"
                                                        } ${"rechart-text-chart-full"} flex items-center text-gray-500 font-semibold justify-center h-full text-center`}
                                                >
                                                    No Data Found
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                :
                                <div className="flex flex-wrap justify-center">
                                    <EmptySkeleton />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EmptySkeleton = () => {
    return (
        <div className="w-full">
            <div className="skeleton skeleton-chart">
                <Skeleton active paragraph={{ rows: 6 }} />
            </div>
        </div>
    )
}

export default TrafficCards;
