import React from "react";
import { Card } from "antd";
import { LoadingOutlined, UserOutlined, DollarOutlined, MobileOutlined, TabletOutlined, DesktopOutlined } from "@ant-design/icons";

const DashbaordCard = ({
  range,
  revenueData,
  cards,
  mobileCount,
  tabletCount,
  desktopCount,
  isLoading
}) => {
  const formatDateRange = (dateRangeString) =>{
    const [startDateString, endDateString] = dateRangeString.split(' - ');
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    const formattedStartDate = startDate.toLocaleDateString('en-US', options);
    const formattedEndDate = endDate.toLocaleDateString('en-US', options);

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  return (
    <div className="flex gap-4">
      {cards.includes("Person") && (
        <Card className="flex-1 shadow-md" style={{ flexBasis: '30%' }}>
          <div className="text-center mb-2"><strong>Person</strong></div>
          <UserOutlined className="text-red-800 text-6xl mb-2" />
          <div className="text-center">

            <div className="text-lg">{formatDateRange(range)}</div>
          </div>
        </Card>
      )}

      {cards.includes("Revenue") && (
        <Card className="flex-1" style={{ flexBasis: '0' }}>
          <DollarOutlined className="text-6xl mb-2" />
          <div className="text-center">
            <strong className="text-lg">Revenue</strong> <br />
            {isLoading ? (
              <LoadingOutlined />
            ) : (
              <strong>${revenueData === "" ? "0.00" : revenueData}</strong>
            )}
            <div className="text-sm">{range}</div>
          </div>
        </Card>
      )}

      {cards.includes("Device") && (
        <Card className="flex-1 shadow-md" style={{ flexBasis: '70%' }}>
          <div className="text-center mb-2"><strong>Device types</strong></div>
          <div className="flex justify-around">
            <div className="flex flex-col items-center">
              <MobileOutlined className="text-red-800 text-6xl" />
              <strong className="text-lg">Mobile</strong>
              {isLoading ? <LoadingOutlined /> : <div><strong>{mobileCount}</strong></div>}
            </div>
            <div className="flex flex-col items-center">
              <TabletOutlined className="text-red-800 text-6xl" />
              <strong className="text-lg">Tablet</strong>
              {isLoading ? <LoadingOutlined /> : <div><strong>{tabletCount}</strong></div>}
            </div>
            <div className="flex flex-col items-center">
              <DesktopOutlined className="text-red-800 text-6xl" />
              <strong className="text-lg">Desktop</strong>
              {isLoading ? <LoadingOutlined /> : <div><strong>{desktopCount}</strong></div>}
            </div>
          </div>
          <div className="text-center">
            <strong className="text-lg">{formatDateRange(range)}</strong>
          </div>
        </Card>
      )}
    </div>
  );
};

export default DashbaordCard;
