import React, { useState } from 'react';
import { SketchSquareFilled } from '@ant-design/icons';
import { Progress, Pagination } from 'antd';

const StateTable = ({ data = [], isValid = false }) => {
  // State to manage current page
  const [currentPage, setCurrentPage] = useState(1);
  // Number of items to display per page
  const itemsPerPage = 5;
  // Calculate index range based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // Filter data based on current page
  const currentPageData = data.slice(startIndex, endIndex);

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="charts pt-2">
            <div className="grid grid-cols-12 bg-gray-200 py-2 p-4 rounded">
              <div className="col-span-10 ">
                <strong>Location</strong>
              </div>
              <div className="col-span-2 text-right">
                <strong>No. of Users</strong>
              </div>
            </div>
            <div className="progress-bars">
              {currentPageData.length ? (
                currentPageData.map((item, index) => {
                  return (
                    <div className="grid grid-cols-12 py-2" key={index}>
                      <div className="col-span-12">
                        <div className="table-heading">
                          <div className="grid grid-cols-12">
                            <div className="col-span-10">
                              {item.city}
                            </div>
                            <div className="col-span-2 text-right">
                              {item.users}
                            </div>
                          </div>
                          <div className="grid grid-cols-12 mt-2">
                            <div className="col-span-12">
                              <Progress
                                strokeLinecap="butt"
                                percent={parseFloat(item.percentage)}
                                status="active"
                                strokeColor="#39a7d9"
                                showInfo={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="grid grid-cols-12 py-2">
                  <div className="col-span-12 text-center">No Data</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Pagination component */}
      {isValid && (
        <div className="grid grid-cols-12 mt-4">
          <div className="col-span-12">
            <Pagination
              current={currentPage}
              total={data.length}
              pageSize={itemsPerPage}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StateTable;
