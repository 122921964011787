import React, { useMemo, useState, useEffect,useCallback } from "react";
import { Button, Col, Modal, Row } from "antd";
import { useSelector } from "react-redux";
import { debounce } from 'lodash';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { setLocalStorageWithExpiry, getLocalStorage, checkAndCleanExpiredLocalStorage } from "../../Helper/helper";
import GivingCentralReactTable from "../../Components/ReactTablePagination/GivingCentralReactTable";
import { LoadingOutlined, SendOutlined } from "@ant-design/icons";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

let source = {
    'request': axios.CancelToken.source(),
    'type': '',
};

const GivingCentralDynamicCard = ({
    title,
    formType,
    urlSegment,
    columns,
    dates,
    isDropDown,
    isSelect,
    setIsSelect,
    datePopup,
    btnActive,
}) => {
    const { client, IdentityApi } = useSelector(({ main }) => main);
    const { user: authUser } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [pageNumberCount, setPageNumberCount] = useState(1);
    const [activeData, setActiveData] = useState([]);
    const [tablePages, setTablePages] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    useEffect(() => {
        fetchData(1, []);
        return () => {
            if (source.request) {
                source.request.cancel("Component unmounted");
            }
        };
    }, [client, isDropDown]);

    useEffect(() => {
        if (isSelect && !datePopup && dates.startDate && dates.endDate) {
            fetchData(1, []);
            setIsSelect(false);
        }
    }, [datePopup]);

    const fetchData = useCallback(async (page, filter_data = []) => {
        let searchValue = "";
        if (filter_data[0]?.value) {
          searchValue = filter_data[0]?.value;
        }

        setIsLoading(true);

          // Check if the component is still mounted before updating the state
          if (isMounted) {
            debouncedFetchData(page, formType, searchValue);
            setIsLoading(false);
          }
      
      }, []);

      useEffect(() => {
        setIsMounted(true);
    
        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            setIsMounted(false);
        };
      }, []);

    const fetchDataAjaxCall = async (page, formType, searchValue) => {
        if (source.request && source.type === formType) {
            source.request.cancel("Previous request cancelled");
        }

        source = {
            'request': axios.CancelToken.source(),
            'type': formType,
        };

        let startDate = dayjs(dates?.startDate).format('YYYY-MM-DD');
        let endDate = dayjs(dates?.endDate).format('YYYY-MM-DD');
        setIsLoading(true);

        checkAndCleanExpiredLocalStorage('GivingCentral_');
        let cookieData = getLocalStorage(`GivingCentral_${startDate}_${endDate}_${client}_chart_${formType}`);
        
        if (cookieData && searchValue === '' && page === 1) {
            
            setActiveData(cookieData.records);
            setTablePages(cookieData.pages);
            setIsLoading(false);
            return;
        }

        try {
            let url = `${IdentityApi}donations?start_date=${startDate}&end_date=${endDate}&client=${client}`;
            let formData = {
                limit: 10,
                offset: 10 * (page - 1),
                filter: searchValue,
                type: formType,
            };

            const response = await axios.post(url, formData, { cancelToken: source.request.token });
            const { status, data } = response;

            if (status === 200) {
                setActiveData(data.records);
                setTablePages(data.pages);

                if (searchValue === '' && page === 1) {
                    setLocalStorageWithExpiry(`GivingCentral_${startDate}_${endDate}_${client}_chart_${formType}`, data, 1);
                }

                setIsLoading(false);
            } else {
                window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const debouncedFetchData = debounce(fetchDataAjaxCall, 200);

    const inputOnChange = (count) => {
        if (count > 0 && count <= tablePages) {
            setPageNumberCount(count);
            fetchData(count, filteredData[0]?.value ? filteredData : []);
        }
    };

    const filterOnChange = (count, data) => {
        if (resetFilter && data.length > 0) {
            setResetFilter(false);
        }

        setFilteredData(data);
        setPageNumberCount(1);
        fetchData(1, data);
    };

    const handledownload = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        if (activeData.length === 0) {
            window.swal({
                title: "Error",
                text: "No data to download",
                icon: "error",
                button: "OK",
            });
            setIsModalOpen(false);
            return;
        }

        setIsLoading(true);
        try {
            const headers = Object.keys(activeData[0]);
            const csvHeader = headers.join(',') + '\n';
            const csvRows = activeData.map(row => {
                return headers.map(fieldName => {
                    const fieldValue = row[fieldName];
                    return `"${fieldValue.toString().replace(/"/g, '""')}"`;
                }).join(',');
            }).join('\n');
            const csvContent = csvHeader + csvRows;
            const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const fileURL = URL.createObjectURL(file);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${formType}_${client}_${dayjs(dates?.startDate).format("YYYY-MM-DD")}_${dayjs(dates?.endDate).format("YYYY-MM-DD")}.csv`);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);

            setIsLoading(false);
            setIsModalOpen(false);
        } catch (error) {
            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
            setIsLoading(false);
            setIsModalOpen(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={urlSegment ? "giving-central-card" : "giving-central-card"} style={{ paddingTop: '20px' }}>
            <div className="flex flex-wrap">
                {urlSegment ?
                    <div className="w-full">
                        <div className="card-title">{title}</div>
                    </div>
                    : ""
                }
            </div>
            <div className="card" style={{ padding: '0px' }}>
                <div className="card-content" style={{ padding: '0px' }}>
                    <GivingCentralReactTable
                        data={activeData}
                        columns={columns}
                        isLoading={isLoading}
                        urlSegment={urlSegment}
                        setIsLoading={setIsLoading}
                        inputOnChange={inputOnChange}
                        downloadData={handledownload}
                        filterOnChange={filterOnChange}
                        filteredData={filteredData}
                        setPageNumberCount={setPageNumberCount}
                        defaultPageSize={10}
                        tablePages={tablePages}
                        pageNumberCount={pageNumberCount}
                        showPaginationOnBottom={true}
                        showFilter={false}
                        resetFilter={resetFilter}
                        btnActive={btnActive}
                        isDropDown={isDropDown}
                        dates={dates}
                    />
                </div>
            </div>
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <div className="download-modal">
                    <p>Downloading</p>
                    <strong>{title}</strong>
                    <span>You are about to download the data for {title}. Do you want to continue?</span>
                    <Button className="send-download-link" key="submit" type="primary" onClick={handleOk}>
                        Download CSV
                        {isLoading ? <LoadingOutlined /> : <SendOutlined />}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default GivingCentralDynamicCard;