import React, { useEffect, useState } from "react";
import { getProfile } from "../../Security/Auth/Auth";
import Sidebar from "../../Navigation/Sidebar";
import { useAuth0 } from "@auth0/auth0-react";
import TopNav from '../../Navigation/TopNav';
import Validate from "../../Security/Validate/validate";
import { Layout } from 'antd';

const { Header, Content } = Layout;

const Wrapper = ({ children }) => {
    const [type, setType] = useState("editor");
    const { user: authUser } = useAuth0();
    const [isUserLoggedin, setIsUserLoggedin] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const { isAuthenticated } = useAuth0();

    const getProfileOnLoad = async (authUser) => {
        let user = await getProfile(authUser);
        setType(user && user.type);
    };

    useEffect(() => {
        getProfileOnLoad(authUser);
    }, [authUser]);

    useEffect(() => {
        if (isAuthenticated) {
            setIsUserLoggedin(true);
        } else {
            setIsUserLoggedin(false);
        }
    }, [isAuthenticated]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    

    return (
        <>
        {isUserLoggedin ? (
          <Layout style={{ minHeight: '100vh' }}>
            <Validate />
            <Sidebar
              type={type}
              collapsed={collapsed}
              toggleCollapsed={toggleCollapsed}
            />
            <Layout
              className={`site-layout ${collapsed ? 'collapsed-layout' : ''}`}
              style={{
                marginLeft: collapsed
                  ? isMobile
                    ? 0
                    : 80
                  : 201, // Adjust the margin based on sidebar state and screen size
                transition: 'margin-left 0.3s ease', // Smooth transition for content area
              }}
            >
              <TopNav />
              <Content
                style={{
                  padding: isMobile ? '16px 8px' : '24px', // Adjust padding for mobile and desktop
                  minHeight: 360,
                  marginTop: 0, // Offset for the top navigation height
                  overflow: 'auto', // Allow content to scroll if needed
                }}
              >
                <div className="site-layout-background">
                  {children}
                </div>
              </Content>
            </Layout>
          </Layout>
        ) : (
          <>{children}</>
        )}
      </>
    );
};

export default Wrapper;