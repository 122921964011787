import React from 'react'
import dayjs from 'dayjs';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { Typography } from 'antd';

const { Text } = Typography;
const PieCharts = ({
    data = [],
    isValid = false,
    filter_dates
}) => {

    const startDate = dayjs(filter_dates.startDate).format('YYYY-MM-DD');
    const endDate = dayjs(filter_dates.endDate).format('YYYY-MM-DD');

    const COLORS = ['#004A6C', '#0E7D9F', '#41B0D2', '#1A237E', '#303F9F', '#3F51B5', '#263238', '#455A64', '#607D8B'];

    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip bg-white p-4 rounded shadow-lg">
                    <>
                        <div>
                            <Text className="average" style={{ color: '#287d9f' }}>Date : {startDate} to {endDate}</Text>
                            <div><Text className="average" style={{ color: '#287d9f' }}>Device Category: {payload[0].payload.name}</Text>
                            </div>
                            <Text className="average" style={{ color: '#287d9f' }}>User Count : {payload[0].payload.count} ({payload[0].payload.percentage}%)</Text>
                        </div>

                        
                    </>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer width="100%" height={400}>
                {
                    !isValid ?
                        <PieChart width={800} height={400}>
                            <Pie
                                data={[{name: 'No Data Found', count: 1}]}
                                cx={'50%'}
                                cy={'45%'}
                                innerRadius={130}
                                outerRadius={160}
                                fill="#f3f6f9"
                                paddingAngle={5}
                                dataKey="count"

                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill="#f3f6f9" />
                                ))}
                            </Pie>
                        </PieChart>
                        :
                        <PieChart width={800} height={400}>
                            <Pie
                                data={data}
                                cx={'50%'}
                                cy={'45%'}
                                innerRadius={130}
                                outerRadius={160}
                                fill="#8884d8"
                                paddingAngle={0}
                                dataKey="count"
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend />
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                }

            </ResponsiveContainer>
        </div>
    )
}

export default PieCharts;