/*eslint-disable*/
import React, { useEffect, useState } from "react";

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { addLeadingZero } from "../../Helper/helper";

const TrendLineCharts = ({
    data = [],
    ApiData = [],
    isValid = false,
    type = ""
}) => {

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setChartData(data);
    }, []);
    const formattedDate=(dateString)=> {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        return formattedDate;
      }
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={5} y={20} dy={5} textAnchor="end" fill="#666" transform="rotate(-27)" fontSize={'12px'}>
                    {formattedDate(payload.value)}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip  bg-white p-4 rounded shadow-lg">
                    <p className="date">{`${payload[0].payload.date}`}</p>
                    <div>
                        {type == "engagement_rate" ? <p className="average">{`Engagement Rate: ${payload[0].payload.engaged_rate}%`}</p> : <p className="average">{`Average page view depth: ${payload[0].payload.average}`}</p>}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 25,
                    }}
                >
                    <XAxis
                        allowDataOverflow
                        dataKey="date"
                        tick={<CustomizedAxisTick />}
                        domain={['dataMin', 'dataMax']}
                        interval={chartData ? chartData.length < 31 ? 0 : Math.round(chartData.length / 30) : 0}
                        height={70}
                        padding={{
                            right: 6,
                            left: 4,
                        }}
                        label={{
                            value: "Time (days)",
                            style: { textAnchor: 'middle' },
                            position: 'bottom',
                            offset: -10,
                        }}
                    />

                    <YAxis
                        tickFormatter={(value) => `${addLeadingZero(value)}`}
                        allowDataOverflow={true}
                        interval="preserveStartEnd"
                        width={100}
                        padding={{
                            top: 10,
                            bottom: 4,
                            left:10
                        }}
                        label={{
                            value: type == "engagement_rate" ? "Engagement Rate" : "Average page view depth",
                            style: { textAnchor: 'middle' },
                            angle: -90,
                            position: 'left',
                            offset: -10,
                        }}
                    />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                    {isValid ?
                        <Tooltip content={<CustomTooltip />} />
                        : ''
                    }
                    <Line strokeWidth={2.5} type="monotone" dataKey={type == "engagement_rate" ? 'engaged_rate' : 'average'} strokeDasharray="10 5" stroke={isValid ? "#4c9ef5" : "white"} dot={{ stroke: isValid ? "#4c9ef5" : "white", strokeWidth: 2, r: 4, fill: isValid ? "#4c9ef5" : "white" }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default TrendLineCharts;
