import React, { useState } from "react";
import { DatePicker,Typography } from 'antd';
import { UserOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import dayjs from 'dayjs';

// Plugins
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const { RangePicker } = DatePicker;
const { Text } = Typography;
const UniqueVisits = ({ uniqueVisits, isLoading, dates, setDates, disableStartDate }) => {
  const formattedDate=(dateString)=> {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    return formattedDate;
  }
  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={5} y={20} dy={5} textAnchor="end" fill="#666" transform="rotate(-27)" fontSize={'12px'}>
                {formattedDate(payload.value)}
            </text>
        </g>
    );
};

  const CustomTooltip = ({ active, payload }) => {
    if (!(active && payload && payload.length)) return null;
  
    return (
      <div className="custom-tooltip bg-white p-4 rounded shadow-lg">
        <div>
          <Text className="title" style={{ color: '#287d9f' }}>
            Date: {payload[0].payload.unixdate}
          </Text>
          <hr className="my-2" />
          <Text className="title" style={{ color: '#287d9f' }}>
            Total Visits: {payload[0].payload.uniqueVisits}
          </Text>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="card">
      <div className="card-header card-header-icon" data-background-color='red'>
          <UserOutlined className="text-white text-6xl" />
        </div>
        <div className="card-content">
          <div className="col">
            <h2 className="text-2xl font-semibold mb-7">Unique Visitors</h2>
            <div className="card-content">
          {/*<RangePicker
            value={[dates.startDate, dates.endDate]}
            onChange={(date, dateString) => setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) })}
            allowClear={false}
            size={"middle"}
            format={"YYYY-MM-DD"}
            disabledDate={(current) => current && current < dayjs(disableStartDate)}
            separator={<ArrowRightOutlined />}
          />*/}
        </div>
        <div className="p-4">
          {!isLoading ? (
            <div className="flex">
              <div className="w-full">
                <div className='w-full'>
                  <div className='charts'>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={uniqueVisits}>
                        <Line
                          type="monotone"
                          dataKey="uniqueVisits"
                          stroke="#0093B2"
                          activeDot={{ r: 4 }}
                        />
                        <YAxis dataKey="uniqueVisits" />
                        <XAxis dataKey="unixdate" 
                          tick={<CustomizedAxisTick />}
                          domain={['dataMin', 'dataMax']}
                          interval={uniqueVisits ? uniqueVisits.length < 31 ? 0 : Math.round(uniqueVisits.length / 30) : 0}
                          height={70}
                          padding={{
                              right: 12,
                              left: 4,
                          }}
                          label={{
                            value: "Time (days)",
                            style: { textAnchor: 'middle' },
                            position: 'bottom',
                            offset: -10,
                        }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Legend /> */}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex">
              <div className="w-full">
                <div className="skeleton skeleton-chart flex justify-between">
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                  <SkeletonLine style={{ height: '20%', width: '4%' }} />
                  <SkeletonLine style={{ height: '100%', width: '4%' }} />
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                  <SkeletonLine style={{ height: '20%', width: '4%' }} />
                  <SkeletonLine style={{ height: '100%', width: '4%' }} />
                  <SkeletonLine style={{ height: '50%', width: '4%' }} />
                  <SkeletonLine style={{ height: '45%', width: '4%' }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default UniqueVisits;
