import Home from "../Views/Home/Home";
import EmailSignUps from "../Views/Events/EmailSignUps";
import EngagedSession from "../Views/Events/EngagedSession";
import GivingCentral from "../Views/GivingCentral/GivingCentral";
import TrafficTrends from "../Views/TrafficTrends/TrafficTrends";
import AbandonedGiftsLightbox from "../Views/AbandonedGiftsLightbox/AbandonedGiftsLightbox";
import MaximizedGivingCentral from "../Views/GivingCentral/MaximizedGivingCentral";
import Activations from "../Views/Activations/Activations";
import ActivationsList from "../Views/Activations/ActivationList";
import Connections from "../Views/Connections/Connections";
import CreateConnections from "../Views/Connections/CreateConnections";

export const routesList = [
    {
        path: '/admin/identity-dashboard',
        componentName: () => <Home />
    },
    {
        path: '/admin/identity-dashboard/:path/:url',
        componentName: () => <Home />
    },
    {
        path: '/admin/give-central',
        componentName: () => <GivingCentral />
    },
    {
        path: '/admin/give-central/maximized/:dynamicSegment/:activeTab/:dropdown/:dropdownValue',
        componentName: () => <MaximizedGivingCentral />
    },
    {
        path: '/admin/signup-success',
        componentName: () => <EmailSignUps />
    },
    {
        path: '/admin/engaged-sessions',
        componentName: () => <EngagedSession />
    },
    {
        path: '/admin/abandoned-gifts-lightbox',
        componentName: () => <AbandonedGiftsLightbox />
    },
    {
        path: '/admin/traffic-trends',
        componentName: () => <TrafficTrends />
    },{
        path:'/admin/activations',
        componentName:()=><Activations />
    },{
        path:'/admin/activationsList',
        componentName:()=><ActivationsList />
    },
    {   path: "/admin/activations/id/:id", 
        componentName:()=><Activations />
    },
    {   path: "/admin/connections/", 
        componentName:()=><Connections />
    },
    {   path: "/admin/CreateConnections/", 
        componentName:()=><CreateConnections />
    },
]
