import React, { useState, useEffect } from 'react';
import { Typography, Spin, Card, Badge, notification } from 'antd';
import { PlusOutlined, EllipsisOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;
const logoID = [
    { "connectorID": 1, "logo": "https://bytespree.com/assets/img/connectors/salesforce.jpeg" },
    { "connectorID": 2, "logo": "https://bytespree.com/assets/img/connectors/raisersedgenxt.jpeg" },
    { "connectorID": 3, "logo": "https://bytespree.com/assets/img/connectors/donorperfect.png" },
    { "connectorID": 4, "logo": "https://bytespree.com/assets/img/connectors/charityengine.jpeg" },
    { "connectorID": 5, "logo": "https://bytespree.com/assets/img/connectors/datamanagementinc.jpeg" },
    { "connectorID": 6, "logo": "https://bytespree.com/assets/img/connectors/virtuous.png" },
    { "connectorID": 7, "logo": "https://bytespree.com/assets/img/connectors/bloomerang.png" },
    { "connectorID": 8, "logo": "https://bytespree.com/assets/img/connectors/luminate.png" },
];
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Add suffix to day
    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // for 4-20
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    return ` ${month} ${day}${daySuffix(day)} ${year}`;
};
// Check if created within the last hour
const isRecentlyAdded = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);

    // Convert both dates to America/New_York timezone
    const options = { timeZone: 'America/New_York', hour12: false };
    const nowInNY = new Date(now.toLocaleString('en-US', options));
    const createdDateInNY = new Date(createdDate.toLocaleString('en-US', options));

    const diffInMinutes = (nowInNY - createdDateInNY) / (1000 * 60);
    return diffInMinutes <= 60;
};
const Connections = () => {
    const [loading, setLoading] = useState(true);
    const [connections, setConnections] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch connections from API
        const fetchConnections = async () => {
            try {
                const response = await fetch('https://z0eepnybq0.execute-api.us-east-1.amazonaws.com/dev/v1/fetch?r=clientConnections&l=100');
                const data = await response.json();
                setConnections(data.map(item => ({
                    id: item.clientConnections.id,
                    client_id: item.clientConnections.client_id,
                    connector_id: item.clientConnections.connector_id,
                    database_id: item.clientConnections.database_id,
                    status: item.clientConnections.status,
                    createdOn: item.clientConnections.createdAt,
                })));
            } catch (error) {
                notification.error({
                    message: 'Error Establishing Client Connection',
                    description: (
                        <div data-qa="error-description">
                            Error: {error.message}
                        </div>
                    ),
                    icon: <CloseCircleOutlined style={{ color: 'red', display: "block" }} />,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchConnections();
    }, []);

    const handleCardClick = () => {
        navigate('/admin/CreateConnections');
    };
    const getLogoByConnectorID = (connectorID) => {
        const logoObj = logoID.find(logo => logo.connectorID === connectorID);
        return logoObj ? logoObj.logo : '';
    };

    return (
        <div className="flex flex-col bg-white shadow-lg rounded-2xl h-full" style={{ minHeight: "440px", maxHeight: "100%" }}>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
                    <Spin tip="Syncing..." size="large" />
                </div>
            )}
            <h1 className="text-3xl font-semibold p-6">Connections List</h1>
            <div className="grid grid-cols-3 gap-12 h-full p-8">
                {connections.map((connection) => (
                    <div className="flex flex-col" key={connection.id}>
                        <Card
                            className="h-full flex flex-col items-center justify-center border-blue-300 bg-white"
                            hoverable
                            style={{ height: "220px" }}
                        >
                            <div className="absolute top-2 right-2">
                                <EllipsisOutlined />
                            </div>
                            <div className="flex items-center justify-center h-24 mb-4">
                                <img src={getLogoByConnectorID(connection.connector_id)} alt={connection.client_id} className="h-32" />
                            </div>
                            {isRecentlyAdded(connection.createdOn) &&
                                <Badge count="Recently added" style={{ backgroundColor: '#52c41a' }} />
                            }
                            <div className='flex flex-col text-center'>
                            <br></br>
                            <Text className="text-lg">Created on: {formatDate(connection.createdOn)}</Text>
                            <br></br>
                            <Text className="text-lg">Database ID: {connection.database_id}</Text>
                            </div>
                        </Card>
                    </div>
                ))}
                {/* Create Connection Card */}
                <div className="flex flex-col rounded-xl">
                    <div className="h-full">
                        <Card
                            hoverable
                            onClick={handleCardClick}
                            className="flex flex-col h-full p-6 border-blue-300 bg-blue-50 items-center justify-center border-none"
                            style={{ textAlign: 'center', height: "220px" }}
                            data-qa="create-connection-card"
                        >
                            <div>
                                <PlusOutlined className="text-3xl text-blue-600 mb-4" data-qa="plus-icon" />
                            </div>
                            <div>
                                <Text className="text-lg text-blue-600 font-semibold">Create Connection</Text>
                            </div>
                        </Card>
                    </div>

                    {connections.length === 0 && (
                        <div className="col-span-3 text-center mt-4" data-qa="no-connections-text">
                            <Text className="text-gray-500 text-sm">
                                It seems you haven’t made any connections yet.
                                <br />
                                Click the button above to get started.
                            </Text>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Connections;
