import React, { useEffect, useState } from 'react';
import { Card, Statistic, Skeleton } from 'antd';
import { UsergroupAddOutlined, DollarOutlined, EyeOutlined, TrophyOutlined, AimOutlined } from '@ant-design/icons';

const DashboardCard = ({
    isLoading,
    topChannel,
    donors,
    revenue,
    pageViewTotal,
    type = ""
}) => {
    const [data, setData] = useState({ topChannel: {}, donors: {}, revenue: {}, viewsTotal: {} });

    useEffect(() => {
        setData({
            topChannel: topChannel,
            donors: donors,
            revenue: revenue,
            viewsTotal: pageViewTotal,
        });
    }, [topChannel, donors, revenue, pageViewTotal]);

    const cardData = [
        {
            title: data?.topChannel.title === "Clicks" ? data?.topChannel.title : "Top Channel",
            value: data.topChannel.name,
            icon: type === "AbandonedGiftsLightbox" ? <AimOutlined /> : <TrophyOutlined />,
            footer: type === "AbandonedGiftsLightbox" ? null : (
                <>
                    {data.topChannel.totalAmount && <strong className='font-semibold'>${data.topChannel.totalAmount}</strong>}
                    {" received from "}
                    {data.topChannel.totalGift && <strong className='font-semibold'>{data.topChannel.totalGift}</strong>}
                    {" gifts"}
                </>
            ),
            background: "linear-gradient(60deg, rgb(255, 200, 69), rgb(255, 158, 27))",
        },
        {
            title: data?.donors.title === "Donations" ? data?.donors.title : "Donors",
            value: data.donors.totalDonors,
            icon: <UsergroupAddOutlined />,
            footer: type === "AbandonedGiftsLightbox" ? null : (
                <>
                    {"Average gift of "}
                    <strong className='font-semibold'>${data.donors.avgGift}</strong>
                </>
            ),
            background: "linear-gradient(60deg, rgb(213, 0, 3), rgb(191, 13, 62))",
        },
        {
            title: data?.revenue.title === "Donation Revenue" ? data?.revenue.title : "Revenue",
            value: `$${data.revenue.totalRevenue}`,
            icon: <DollarOutlined />,
            footer: type === "AbandonedGiftsLightbox" ? null : (
                <>
                    {"One-time gifts: "}
                    <strong className='font-semibold'>${data.revenue.totalOneTimeRevenue}</strong>
                    {", Initial sustaining gifts: "}
                    <strong className='font-semibold'>${data.revenue.totalRecurringRevenue}</strong>
                </>
            ),
            background: "linear-gradient(60deg, rgb(0, 98, 114), rgb(0, 59, 73))",
        },
        {
            title: data?.viewsTotal.title === "Conversion Rate" ? data?.viewsTotal.title : "Page Views",
            value: data?.viewsTotal.views,
            icon: <EyeOutlined />,
            footer: type === "AbandonedGiftsLightbox" ? null : (
                <>
                    {"20 Second Engagements: "}
                    <strong className='font-semibold'>{data.viewsTotal.engaged}</strong>
                </>
            ),
            background: "linear-gradient(60deg, rgb(0, 147, 178), rgb(0, 97, 127))",
        }
    ];

    return (
        <div className="flex flex-wrap justify-center">
            {cardData.map((card, index) => (
                <div key={index} className="w-full sm:w-1/2 lg:w-1/4 p-4">
                    <div className="relative h-full shadow md:shadow-lg">
                        <div className="absolute text-white -top-4 left-4 p-4 z-50 shadow-lg rounded" style={{background:card.background}}>
                            <div className={`text-6xl ${card.background} p-2 rounded-full`}>
                                {card.icon}
                            </div>
                        </div>
                        <Card className="shadow-lg rounded-lg pt-4 h-full flex flex-col justify-between" style={{background:"white"}}>
                            <div>
                                <div className="flex flex-col items-end p-2">
                                    <span className="text-black text-2xl text-right font-semibold mb-7">{card.title}</span>
                                    <div className="pb-6 text-right">
                                        {isLoading ? (
                                            <Skeleton active paragraph={{ rows: 2 }} />
                                        ) : (
                                            <Statistic
                                                value={card.value}
                                                valueStyle={{ color: 'black' }}
                                                className="text-black text-3xl"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isLoading ? (
                                <Skeleton active paragraph={{ rows: 2 }} />
                            ) : (
                                card.footer && (
                                    <p className="text-left border-t p-2 border-inherit mt-2 text-black text-base">
                                        {card.footer}
                                    </p>
                                )
                            )}
                        </Card>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DashboardCard;
