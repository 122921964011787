import React from 'react';
import DistributionTbl from '../../Components/DistributionTbl/DistributionTbl';

const DistributionTable = ({
    urlSegment,
    btnActive,
    isDropDown
}) => {
    return (
        <div className='distribution-table'>
            <DistributionTbl
                urlSegment={urlSegment}
                btnActive={btnActive}
                isDropDown={isDropDown}
            />
        </div>
    )
}

export default DistributionTable;