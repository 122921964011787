import updateUserTypes from "./type";

export const UpdateUserType = (query) => {
    return {
        type: updateUserTypes.UPDATE_USER_TYPE,
        payload: query
    }
};

export const ChangeDomain = (query) => {
    return {
        type: updateUserTypes.CHANGE_DOMAIN,
        payload: query
    }
};

export const ChangeInitial = (query) => {
    return {
        type: updateUserTypes.CHANGE_INITIAL,
        payload: query
    }
};

export const ChangeExportData = (query) => {
    return {
        type: updateUserTypes.CHANGE_EXPORT_DATA,
        payload: query
    }
};

export const ChangeQueryData = (query) => {
    return {
        type: updateUserTypes.CHANGE_QUERY_DATA,
        payload: query
    }
};

export const ChangeExportDataS3Url = (query) => {
    return {
        type: updateUserTypes.CHANGE_EXPORT_DATA_S3URL,
        payload: query
    }
};

export const ChangeImageData = (query) => {
    return {
        type: updateUserTypes.CHANGE_IMAGE_DATA,
        payload: query
    }
};

export const ChangeCreativeData = (query) => {
    return {
        type: updateUserTypes.CHANGE_CREATIVE_DATA,
        payload: query
    }
};

export const ChangeRole = (query) => {
    return {
        type: updateUserTypes.CHANGE_ROLE,
        payload: query
    }
};

export const ChangeHTML = (query) => {
    return {
        type: updateUserTypes.CHANGE_HTML,
        payload: query
    }
};

export const ChangeClient = (query) => {
    return {
        type: updateUserTypes.CHANGE_CLIENT,
        payload: query
    }
};

export const ChangeClientType = (query) => {
    return {
        type: updateUserTypes.CHANGE_CLIENT_TYPE,
        payload: query
    }
};

export const ChangeCurrentEmail = (query) => {
    return {
        type: updateUserTypes.CHANGE_CURRENTE_MAIL,
        payload: query
    }
};

export const ChangeCampaignStored = (query) => {
    return {
        type: updateUserTypes.CHANGE_CAMPAIGN_STORED,
        payload: query
    }
};

export const UpdateClientData = (query) => {
    return {
        type: updateUserTypes.UPDATE_CLIENT_DATA,
        payload: query
    }
};

export const UpdateCreatives = (query) => {
    return {
        type: updateUserTypes.UPDATE_CREATIVES,
        payload: query
    }
};

export const SwapDashboard = (query) => {
    return {
        type: updateUserTypes.SWAP_DASHBOARD,
        payload: query
    }
};

export const changeCreativeName = (query) => {
    return {
        type: updateUserTypes.CHANGE_CREATIVE_NAME,
        payload: query
    }
};

export const changeQuestionType = (query) => {
    return {
        type: updateUserTypes.CHANGE_QUESTION_TYPE,
        payload: query
    }
};

export const ChangeQuestionData = (query) => {
    return {
        type: updateUserTypes.CHANGE_QUESTION_DATA,
        payload: query
    }
};

export const ChangesubCampaignData = (query) => {
    return {
        type: updateUserTypes.CHANGE_SUB_CAMPAIGN_DATA,
        payload: query
    }
};

export const ChangeUserID = (query) => {
    return {
        type: updateUserTypes.CHANGE_USERID,
        payload: query
    }
};

export const ChangeCampaignYear = (query) => {
    return {
        type: updateUserTypes.CHANGE_CAMPAIGN_YEAR,
        payload: query
    }
};

export const ChangeSidebar = (query) => {
    return {
        type: updateUserTypes.CHANGE_SIDEBAR,
        payload: query
    }
};

export const ChangeTicketData = (query) => {
    return {
        type: updateUserTypes.CHANGE_TICKET_DATA,
        payload: query
    }
};

export const UpdateMultiChannelReportingStage = (query) => {
    return {
        type: updateUserTypes.UPDATE_MULTICHANNEL_REPORTING_STAGE,
        payload: query
    }
};

export const UpdateMultiChannelReportingLive = (query) => {
    return {
        type: updateUserTypes.UPDATE_MULTICHANNEL_REPORTING_LIVE,
        payload: query
    }
};

export const changeBreadcrumbs = (query) => {
    return {
        type: updateUserTypes.CHANGE_BREADCRUMBS,
        payload: query
    }
};

export const changeLayout = (query) => {
    return {
        type: updateUserTypes.CHANGE_LAYOUT,
        payload: query
    }
};

export const changeFormCreativeData = (query) => {
    return {
        type: updateUserTypes.CHANGE_FORM_CREATIVE_DATA,
        payload: query
    }
};

export const changeIsColorSetting = (query) => {
    return {
        type: updateUserTypes.CHANGE_ISCOLOR_SETTING,
        payload: query
    }
};

export const changeColorSettingNeighbor = (query) => {
    return {
        type: updateUserTypes.CHANGE_COLOR_SETTING_NEIGHBOR,
        payload: query
    }
};

export const changeColorSettingGifting = (query) => {
    return {
        type: updateUserTypes.CHANGE_COLOR_SETTING_GIFTING,
        payload: query
    }
};

export const changeIsMinimumAmount = (query) => {
    return {
        type: updateUserTypes.CHANGE_ISMINIMUM_AMOUNT,
        payload: query
    }
};

export const changeMinimumAmountValue = (query) => {
    return {
        type: updateUserTypes.CHANGE_MINIMUM_AMOUNT_VALUE,
        payload: query
    }
};

export const changeDonorPortalImageData = (query) => {
    return {
        type: updateUserTypes.CHANGE_DONOR_PORTAL_IMAGE_DATA,
        payload: query
    }
};

export const updateClientMetaData = (query) => {
    return {
        type: updateUserTypes.UPDATE_CLIENT_METADATA,
        payload: query
    }
};

export const getClientDashboard = (query) => {
    return {
        type: updateUserTypes.GET_CLIENT_DASHBOARD,
        payload: query
    }
};
