/*eslint-disable*/
import React, { useEffect, useState } from "react";

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { addLeadingZero } from "../../Helper/helper";

const EventLineChart = ({
    data = [],
    ApiData = [],
    type = ""
}) => {

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setChartData(data);
    }, []);

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                    {payload.value}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="date">{` ${payload[0].payload.date} ${type == "subscriber_growth_rate" ? " - " + payload[0].payload.lastDate : ""}`}</p>
                    <div>
                        {type == "subscriber_growth_rate" ? <p className="average">{`Number of Subscribers: ${payload[0].payload.subscribers}`}</p> : <p className="average">{`Number of Sign-ups: ${payload[0].payload.average}`}</p>}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 25,
                    }}
                >
                    <XAxis
                        allowDataOverflow
                        dataKey="date"
                        tick={<CustomizedAxisTick />}
                        domain={['dataMin', 'dataMax']}
                        interval={chartData ? chartData.length < 31 ? 0 : Math.round(chartData.length / 30) : 0}
                        height={70}
                        padding={{
                            right: 6,
                            left: 4,
                        }}
                        label={{
                            value: type == "subscriber_growth_rate" ? "Time (weekly)" : "Time (days)",
                            style: { textAnchor: 'middle' },
                            position: 'bottom',
                            offset: 0,
                        }}
                    />

                    <YAxis
                        tickFormatter={(value) => `${addLeadingZero(value)}`}
                        allowDataOverflow={true}
                        interval="preserveStartEnd"
                        width={100}
                        padding={{
                            top: 10,
                            bottom: 4
                        }}
                        label={{
                            value: type == "subscriber_growth_rate" ? "Number of Subscribers" : "Number of Sign-ups",
                            style: { textAnchor: 'middle' },
                            angle: -90,
                            position: 'left',
                            offset: 0,
                        }}
                    />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                    {Object.keys(ApiData).length ?
                        <Tooltip content={<CustomTooltip />} />
                        : ''
                    }
                    <Line strokeWidth={2.5} type="monotone" dataKey={type == "subscriber_growth_rate" ? 'subscribers' : 'average'} stroke={Object.keys(ApiData).length ? "#4c9ef5" : "white"} dot={{ stroke: Object.keys(ApiData).length ? "#4c9ef5" : "white", strokeWidth: 2, r: 3, fill:"white" }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default EventLineChart;
