import React, { useState } from "react";
import { Skeleton, Button, Col, Modal, Row, Tooltip } from "antd";
import { DownloadOutlined, ExpandOutlined, LoadingOutlined } from "@ant-design/icons";
import LineCharts from "./LineCharts";
import BarCharts from "./BarCharts";
import PieCharts from "./PieCharts";
import AreaCharts from "./AreaCharts";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSelector } from "react-redux";
import { replaceArrayKeys } from "../../Helper/helper";

const GiveEventChart = ({
  eventType,
  data = [],
  isValid = false,
  isLoading = false,
  urlSegment,
  btnActive,
  isDropDown,
  dates,
  setisLoading,
}) => {
  const { user: authUser } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Data, setData] = useState([]);
  const { client, IdentityApi } = useSelector(({ main }) => main);
  const [title, setTitle] = useState("");

  const handledownload = (data, eventType) => {
    setIsModalOpen(true);
    let finalArr = [];
    if (eventType === "average_donation_amount") {
      finalArr = replaceArrayKeys(data, [
        { old: "average1", new: "OneTime Average Donation" },
        { old: "average2", new: "Recurring Average Donation" },
      ]);
      setTitle("Average Donation Amount");
    }
    if (eventType === "channels_performance") {
      finalArr = replaceArrayKeys(data, [
        { old: "count", new: "Count" },
        { old: "name", new: "Channel Name" },
        { old: "totalamount", new: "Donation Amount" },
      ]);
      setTitle("Donation Channel Performance");
    }
    if (eventType === "onetime_vs_recurring") {
      finalArr = replaceArrayKeys(data, [
        { old: "oneTimeTotalDonors", new: "OneTime Donors" },
        { old: "recurringTotalDonors", new: "New Subscriptions" },
      ]);
      setTitle("One Time vs New Subscriptions");
    }
    if (eventType === "donation_source") {
      finalArr = replaceArrayKeys(data, [
        { old: "count", new: "Count" },
        { old: "name", new: "Source Name" },
        { old: "totalamount", new: "Donation Amount" },
      ]);
      setTitle("Donation Distribution by Source");
    }
    if (eventType === "campaign_performance") {
      finalArr = replaceArrayKeys(data, [
        { old: "count", new: "Count" },
        { old: "name", new: "Campaign Name" },
        { old: "totalamount", new: "Donation Amount" },
      ]);
      setTitle("Donation Campaign Performance");
    }
    if (eventType === "frequency_distribution") {
      finalArr = replaceArrayKeys(data, [
        { old: "count", new: "Count" },
        { old: "percentage", new: "Percentage" },
        { old: "totalamount", new: "Total Amount" },
      ]);
      setTitle("Donation Frequency Distribution");
    }
    if (eventType === "total_donation") {
      finalArr = replaceArrayKeys(data, [
        { old: "totalDonationAmt", new: "Total Donation Amount" },
      ]);
      setTitle("Total Donations Over Time");
    }
    setData(finalArr);
  };

  const handleOk = async () => {
    if (Data.length === 0) {
      window.swal({
          title: "Error",
          text: "No data to download",
          icon: "error",
          button: "OK",
        });
 
      setIsModalOpen(false);
      return;
    }

    setisLoading(true);

    try {
      // Step 1: Extract Column Headers
      const headers = Object.keys(Data[0]);

      // Step 2: Create Header Row
      const csvHeader = headers.join(',') + '\n';

      // Step 3: Create Data Rows
      const csvRows = Data.map(row => {
        return headers.map(fieldName => {
          // Handle values that contain commas or newlines
          const fieldValue = row[fieldName];
          return `"${fieldValue.toString().replace(/"/g, '""')}"`; // Escape double quotes
        }).join(',');
      }).join('\n');

      // Step 4: Combine Header and Data Rows
      const csvContent = csvHeader + csvRows;

      // Proceed with creating and downloading the file as before
      const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${title}_${client}_${dayjs(dates?.startDate).format("YYYY-MM-DD")}_${dayjs(dates?.endDate).format("YYYY-MM-DD")}.csv`);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);

      setisLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      window.swal({
        title: "Error",
        text: "Something went wrong! Please try again later.",
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="event-chart p-5">
      <div className={urlSegment ? "event" : "eventChart"}>
        <div className="mb-4">
          {urlSegment && (
            <div className="text-2xl font-bold">
              {eventType === "average_donation_amount" && "Average Donation Amount"}
              {eventType === "channels_performance" && "Donation Channel Performance"}
              {eventType === "onetime_vs_recurring" && "One Time vs New Subscriptions"}
              {eventType === "donation_source" && "Donation Distribution by Source"}
              {eventType === "campaign_performance" && "Donation Campaign Performance"}
              {eventType === "frequency_distribution" && "Donation Frequency Distribution"}
              {eventType === "total_donation" && "Total Donations Over Time"}
            </div>
          )}
        </div>

        <div className="card" style={{ maxHeight: urlSegment ? "350px" : "500px" }}>
          <div className="p-1">
            {!isLoading ? (
              <div className="flex flex-wrap">
                <div className="w-full">
                  <div className="w-full">
                    <div className="flex justify-end space-x-4">
                      <Tooltip title="Maximize" className="mr-2 p-1">
                        {urlSegment && (
                          <a
                            href={`/admin/give-central/maximized/${urlSegment}/${btnActive}/${isDropDown}/${dayjs(
                              dates?.startDate
                            ).format("YYYY-MM-DD")}_${dayjs(dates?.endDate).format(
                              "YYYY-MM-DD"
                            )}`}
                            target="_blank"
                            className="text-gray-600"
                          >
                            <ExpandOutlined className="cursor-pointer" />
                          </a>
                        )}
                      </Tooltip>
                      <Tooltip title="Download" className="hidden">
                        <DownloadOutlined
                          className="cursor-pointer"
                          onClick={() => handledownload(data, eventType)}
                        />
                      </Tooltip>
                    </div>
                    <div className="charts">

                      {eventType === "average_donation_amount" && (
                        <LineCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />
                      )}
                      {eventType === "channels_performance" && (
                        <BarCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />
                      )}
                      {eventType === "onetime_vs_recurring" && (
                        <AreaCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />
                      )}
                      {eventType === "donation_source" && (
                        <PieCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />
                      )}
                      {eventType === "campaign_performance" && (
                        <BarCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />
                      )}
                      {eventType === "frequency_distribution" && (
                        <BarCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />
                      )}
                      {eventType === "total_donation" && (
                        <LineCharts data={data} isValid={isValid} eventType={eventType} segment={urlSegment} />
                      )}
                      {/* Display the "No Data Found" message */}
                      {!isValid && (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div
                            className={`${urlSegment && eventType === "donation_source"
                                ? "rechart-text-chart"
                                : "rechart-text"
                              } ${!urlSegment && "rechart-text-chart-full"} flex items-center text-gray-500 font-semibold justify-center h-full text-center`}
                          >
                            No Data Found
                          </div>
                        </div>
                      )}
                    </div>


                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-wrap">
                <div className="w-full">
                  <Skeleton active paragraph={{ rows: 4 }} />
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
          <div className="download-modal text-center">
            <p>Downloading</p>
            <strong>{title}</strong>
            <span>
              You are about to download the data for {title}. Do you want to continue?
            </span>
            <Button
              className="send-download-link mt-4"
              type="primary"
              onClick={handleOk}
              icon={isLoading ? <LoadingOutlined /> : <DownloadOutlined />}
            >
              Download CSV
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default GiveEventChart;
