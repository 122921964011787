import securityTypes from "./type";

let initialState = {
    products: [],
    user: {}
};

const securityReducer = (state = initialState, action) => {

    switch (action.type) {

        case securityTypes.UPDATE_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }

        case securityTypes.UPDATE_USER:
            return {
                ...state,
                user: action.payload
            }

        default:
            return state;
    }

}

export default securityReducer;