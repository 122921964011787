import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import loading from '../../Views/Callback/loading.svg';
import Login from '../../Views/Login/Login';

export const AuthenticationGuard = ({ component }) => {

    const style = {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
    }

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <div style={style}> <img src={loading} alt="loading"/> </div>,
        returnTo: () => <Login />
    });

    return <Component />;
};