import React, { useEffect, useState } from 'react';

//Import Plugins
import swal from 'sweetalert';

//UI
import { Button, Skeleton, Progress } from 'antd';
import { Pane, SideSheet, Card } from 'evergreen-ui';
import { DownloadOutlined, EyeOutlined, RiseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

const pageViewData = [
  {
    "type": "page_view",
    "ga_id": "N/A",
    "person_id": "N/A",
    "user_id": "N/A",
    "url": "N/A",
    "utm_source": "N/A",
    "utm_medium": "N/A",
    "utm_campaign": "N/A",
    "rs_id": "N/A",
    "referrer": "N/A",
    "timestamp": "1558529591524",
    "session_id": "N/A",
    "date": "N/A",
    "unixdate": "N/A",
    "mode": "N/A",
    "unique": "N/A",
    "ua": "N/A",
    "browser_name": "N/A",
    "browser_version": "N/A",
    "browser_major": "N/A",
    "engine_name": "N/A",
    "engine_version": "N/A",
    "os_name": "N/A",
    "os_version": "N/A",
    "isdesktop": "N/A",
    "ismobile": "N/A",
    "istablet": "N/A",
    "ipaddress": "N/A",
    "messagetype": "N/A",
    "partition_0": "N/A",
    "partition_1": "N/A"
  }
];

const Event = ({ rowData }) => {

  const { client, IdentityApi } = useSelector(({ main }) => main);

  const [data, setData] = useState({});
  const [isShown, setIsShown] = useState(false);
  const [height, setHeight] = useState(500);
  const [render_data, setRender_data] = useState('');
  const [sidebar, setSidebar] = useState('event');
  const [page_view_data, setPage_view_data] = useState(pageViewData);
  const [most_recent, setMost_recent] = useState('');
  const [view_count, setView_count] = useState(0);
  const [progress, setProgress] = useState(0);
  const [session_duration, setSession_duration] = useState(0);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    setData(rowData.row.original);
    setHeight(Object.keys(rowData).length * 15);

    render_dataFn();

  }, [rowData]);

  const view = (e) => {

    render_dataFn();
    setIsShown(true);
    setSidebar('event');
    setView_count(0);

  }

  const page_viewsFn = async (e) => {

    let timer;

    if (most_recent === data.session_id) {

      render_page_views('card', page_view_data);

      clearInterval(timer);

      setIsShown(true);

      return;

    }

    setIsShown(true);
    setSidebar('loading');
    setRender_data('Page stream is being queried...');

    let progress = 0;

    timer = setInterval(() => {
      progress += 10;
      setProgress(progress);
    }, 1000);

    let url = `${IdentityApi}lookup/session`;

    setMost_recent(data.session_id);

    const response = await axios.get(url, { params: { session: data?.session_id?.trim(), client: client } });

    const { status, data: respData } = response;

    if (status === 200) {

      try {

        setProgress(100);

        if (respData.status === "success") {

          setTimeout(() => {

            console.log("Data > ", respData.data);
            setPage_view_data(respData.data);

            render_page_views('card', respData.data);

            clearInterval(timer);

          }, 300);

        }
        else {

          console.log(respData.message);
          swal("ERROR", "An Error occurred during getting data, check logs for further details", "error", { closeOnClickOutside: false });

        }

      } catch (error) {

        console.log(error);
        swal("ERROR", "An Error occurred during getting data, check logs for further details", "error", { closeOnClickOutside: false });

      }

    } else {

      swal("ERROR", "Something went wrong! Please try again later.", "error", { closeOnClickOutside: false });

    }

  }

  ///*******Render*********///

  const render_dataFn = () => {

    let render = '';

    for (let value in data) {

      render += `<p>${value}: ${data[value]}</p>`;

    }

    setRender_data(render);

  }

  const render_page_views = (type, page_data) => {

    setView_count(page_data.length);

    function sortNumber(a, b) {
      return a.timestamp - b.timestamp;
    }

    page_data.sort(sortNumber);

    page_data.sort(sortNumber);

    function timediff(date1, date2) {

      const minutes = parseInt(Math.abs(date1 - date2) / (1000 * 60) % 60, 10);

      const seconds = parseInt(Math.abs(date1 - date2) / (1000) % 60, 10);

      const formatedminutes = minutes < 10 ? '0' + minutes : minutes;

      const formatedseconds = seconds < 10 ? '0' + seconds : seconds;

      return `${formatedminutes} minutes : ${formatedseconds} seconds`;

    }

    let session_duration = page_data[0] && timediff(page_data[0].timestamp, page_data[page_data.length - 1].timestamp);

    let render;

    if (page_data.length <= 0) {

      setNoData(true);

      render = <div style={{ backgroundColor: 'aliceblue', border: '2px solid #dce6ef', padding: '10px', margin: '-20px 0px' }}>
        No Data Found
      </div>
    } else {

      setNoData(false);

      render = page_data.map((view) => {

        if (type === 'card') {

          return (

            <div key={view.unique} style={{ backgroundColor: 'aliceblue', border: '2px solid #dce6ef', padding: '10px', margin: '3px 0px' }}>

              <p>URL: <Link to={view.url} target="_blank" style={{ overflowWrap: 'break-word', color: '#337ab7' }}>{view.url}</Link></p>

              <p>Date: {view.date}</p>

              <p>RS ID: {view.rs_id}</p>

              <h5>Google Tracking (If Present):</h5>

              <p>UTM Campaign: {view.utm_campaign}</p>

              <p>UTM Medium: {view.utm_medium}</p>

              <p>UTM Source: {view.utm_source}</p>

              <br />

            </div>

          );


        }
        else {

          return (<div></div>);

        }

      });
    }

    setSession_duration(session_duration);
    setRender_data(render);
    setSidebar('card');
    setProgress(0);

  }

  const download = (source) => {

    let save = JSON.stringify(data);

    if (source === "page_view") {
      save = JSON.stringify(page_view_data);
    }

    let hiddenElement = document.createElement('a');

    let file = new window.Blob([save], { type: 'application/json' });

    hiddenElement.href = window.URL.createObjectURL(file);

    hiddenElement.target = '_blank';

    hiddenElement.download = `${data.unique}.json`;

    if (source === "page_view") {
      hiddenElement.download = "page_stream.json";
    }

    document.body.appendChild(hiddenElement);

    hiddenElement.click();

    document.body.removeChild(hiddenElement);

  }

  return (

    <div>

      {/*Option Buttons*/}
      <Button size="small" onClick={view} icon={<EyeOutlined />} title="View"></Button> &nbsp;

      <Button size="small" onClick={() => download("unique",)} icon={<DownloadOutlined />} title="Download"></Button> &nbsp;

      <Button size="small" onClick={page_viewsFn} icon={<RiseOutlined />} title="View Session"></Button> &nbsp;

      <SideSheet
        isShown={isShown === true}
        onCloseComplete={() => setIsShown(false)}
        containerProps={{ display: 'flex', flex: '1', flexDirection: 'column' }}
      >

        <Pane flex="1" overflowY="scroll" appearance="tint1" padding={16}>

          <Card
            backgroundColor="white"
            elevation={0}
            height={height}
            display="block"
            alignItems="center"
            justifyContent="center"
          >

            {sidebar === 'event' && (

              <div style={{ padding: '10px' }}>

                <h2>Event Content</h2>

                <div
                  dangerouslySetInnerHTML={{ __html: render_data }}
                />


              </div>

            )}


            {sidebar === 'loading' && (

              <div style={{ padding: '15px', textAlign: 'center' }}>

                <h5>Building page view record...</h5>

                <Progress type="circle" percent={progress} />

                <Skeleton active />

                <Skeleton active />

                <Skeleton active />

              </div>

            )}

            {sidebar === 'card' && (

              <div style={{ padding: '10px' }}>

                <h2>Visitor Journey &nbsp; &nbsp;
                  {view_count > 0 && <Button onClick={() => download("page_view")} size="large" icon={<DownloadOutlined />} type="primary">Download</Button>}
                </h2>

                {!noData && (
                  <>
                    <p>Total pages viewed: <strong>{view_count}</strong></p>
                    <p>Session Duration: {session_duration}</p>
                  </>
                )}

                <br />

                {render_data}

              </div>

            )}

          </Card>

        </Pane>

      </SideSheet>

    </div>
  );

};

export default Event;