import React, { useState } from "react";
import { DownloadOutlined, ExpandOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";

import { Progress, Pagination, Tooltip as AntdTooltip, Modal, Row, Col, Button } from "antd";
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSelector } from "react-redux";
import { replaceArrayKeys } from "../../Helper/helper";
import ReactTableUI from "../../Views/UI/ReactTableUI";

const CityTable = ({
  data = [],
  isValid = false,
  urlSegment,
  isLoading,
  btnActive,
  isDropDown,
  dates,
  setisLoading,
}) => {
  // State to manage current page
  const [currentPage, setCurrentPage] = useState(1);
  const { client, IdentityApi } = useSelector(({ main }) => main);
  const { user: authUser } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Number of items to display per page
  const itemsPerPage = 5;

  // Calculate index range based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filter data based on current page
  const currentPageData = data.slice(startIndex, endIndex);

  const handledownload = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (currentPageData.length === 0) {
      window.swal({
        title: "Error",
        text: "No data to download",
        icon: "error",
        button: "OK",
      });
      setIsModalOpen(false);
      return;
    }

    setisLoading(true);
    try {
      let array = replaceArrayKeys(currentPageData, [
        { old: "name", new: "Name" },
        { old: "count", new: "Count" },
        { old: "totalamount", new: "Total Amount" },
      ]);

      // Step 1: Extract Column Headers
      const headers = Object.keys(array[0]);

      // Step 2: Create Header Row
      const csvHeader = headers.join(',') + '\n';

      // Step 3: Create Data Rows
      const csvRows = array.map(row => {
          return headers.map(fieldName => {
          // Handle values that contain commas or newlines
          const fieldValue = row[fieldName];
          return `"${fieldValue.toString().replace(/"/g, '""')}"`; // Escape double quotes
          }).join(',');
      }).join('\n');

      // Step 4: Combine Header and Data Rows
      const csvContent = csvHeader + csvRows;

      // Proceed with creating and downloading the file as before
      const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `Donation by City & State_${client}_${dayjs(dates?.startDate).format("YYYY-MM-DD")}_${dayjs(dates?.endDate).format("YYYY-MM-DD")}.csv`);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);

      setisLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      window.swal({
        title: "Error",
        text: "Something went wrong! Please try again later.",
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const activeColumns = [
    {
      Header: "City, State",
      accessor: "name",
      width: 100,
    },
    {
      Header: "No. of Donations",
      accessor: "count",
      width: 100,
    },
    {
      Header: "Total Amount",
      accessor: "totalamount",
      width: 100,
    },
  ];

  return (
 
<div className="city-chart p-0">
    <div className={urlSegment ? "pl-0 pt-8" : "city-table pl-0 pt-6"}>
        {urlSegment && (
        <div className="flex flex-wrap">
        <div className="w-full">
          <div className="text-2xl font-bold">Donation by City & State</div>
        </div>
      </div>
        )}
        <div className="card p-1" style={{ height: urlSegment ? "342px" : "", marginBottom:'0px' }}>
            <div className="p-1" >
                {!isLoading ? (
                      
                            <div>
                                {urlSegment && (
                                    <div className={`flex flex-wrap download event-icons ${urlSegment ? "event-icons" : "event-icons-download"}`}>
                                        <AntdTooltip title="Maximize" className="mr-2 p-1">
                                            {urlSegment && (
                                                <a
                                                    href={`/admin/give-central/maximized/${urlSegment}/${btnActive}/${isDropDown}/${dayjs(dates?.startDate).format("YYYY-MM-DD")}_${dayjs(dates?.endDate).format("YYYY-MM-DD")}`}
                                                    target="_blank"
                                                    className="text-gray-600"
                                                >
                                                    <ExpandOutlined className="cursor-pointer" />
                                                </a>
                                            )}
                                        </AntdTooltip>
                                        <AntdTooltip title="Download" className="hidden">
                                            <DownloadOutlined className="cursor-pointer text-gray-600" onClick={() => handledownload()} />
                                        </AntdTooltip>
                                    </div>
                                )}
                                <div className="charts">
                                    <div className="progress-bars giving-central-card">
                                        <ReactTableUI className="border-0"
                                            data={data}
                                            columns={activeColumns}
                                            isLoading={!isValid}
                                            showFilter={false}
                                            showPaginationOnBottom={true}
                                            pageSizeOptions={false}
                                            defaultPageSize={5}
                                            urlSegment={urlSegment}
                                            btnActive={btnActive}
                                            isDropDown={isDropDown}
                                            dates={dates}
                                            handledownload={handledownload}
                                        />
                                    </div>
                                </div>
                            </div>
                ) : (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="skeleton skeleton-chart">
                                <SkeletonLine style={{ height: "50%", width: "4%" }} />
                                <SkeletonLine style={{ height: "45%", width: "4%" }} />
                                <SkeletonLine style={{ height: "20%", width: "4%" }} />
                                <SkeletonLine style={{ height: "100%", width: "4%" }} />
                                <SkeletonLine style={{ height: "50%", width: "4%" }} />
                                <SkeletonLine style={{ height: "45%", width: "4%" }} />
                                <SkeletonLine style={{ height: "20%", width: "4%" }} />
                                <SkeletonLine style={{ height: "100%", width: "4%" }} />
                                <SkeletonLine style={{ height: "50%", width: "4%" }} />
                                <SkeletonLine style={{ height: "45%", width: "4%" }} />
                                <SkeletonLine style={{ height: "20%", width: "4%" }} />
                                <SkeletonLine style={{ height: "100%", width: "4%" }} />
                                <SkeletonLine style={{ height: "50%", width: "4%" }} />
                                <SkeletonLine style={{ height: "45%", width: "4%" }} />
                                <SkeletonLine style={{ height: "20%", width: "4%" }} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Modal open={isModalOpen} onCancel={handleCancel} footer={[]}>
                <div className="download-modal">
                    <p>Downloading</p>
                    <strong>Donation by City & State</strong>
                    <span>
                        You are about to download the data for Donation by City & State. Do you want to continue?
                    </span>
                    <Button
                        className="send-download-link"
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                        icon={isLoading ? <LoadingOutlined /> : <SendOutlined />}
                    >
                        Download CSV
                    </Button>
                </div>
            </Modal>
        </div>
    </div>
</div>
   
  );
};

export default CityTable;
