import React, { useState, useEffect } from 'react';
import { getProfile } from "../Auth/Auth";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from 'react-redux';
import { ChangeRole, ChangeSidebar, UpdateUserType, ChangeClient } from '../../redux/Main/action';
import { UpdateProducts, UpdateUser } from '../../redux/Security/action';

const Validate = () => {

    const dispatch = useDispatch();

    const [profile, setProfile] = useState({});
    const { user: authUser, isAuthenticated } = useAuth0();

    useEffect(() => {
        const fetchProfile = async () => {
            if (isAuthenticated) {
                const user = await getProfile(authUser);
                setProfile(user);
            }
        };

        fetchProfile();
    }, [isAuthenticated]);

    useEffect(() => {
        testRole();
    }, [profile]);

    //Testing the role of the user: client or admin
    const testRole = () => {

        if(Object.keys(profile).length > 0){

            const { email, sub, picture, role: userRole, type: userType, name, nickname} = profile;

            let userObject = {
                email: email,
                id: sub,
                picture: picture,
                role: userRole,
                type: userType,
                products: profile["http://one.rkd.io/app_metadata"].products,
                dashboard: profile["http://one.rkd.io/app_metadata"].sidebar,
                name: name,
                nickName: nickname,
            };

            let sideBar = profile["http://one.rkd.io/app_metadata"].sidebar;
            let products = profile["http://one.rkd.io/app_metadata"].products;

            if (sideBar) {
                dispatch(ChangeSidebar(sideBar));
            }

            // Saving user type
            dispatch(UpdateUserType(userType));

            // Saving user role

            if(userRole === 'admin'){
                dispatch(ChangeRole(userRole));
            } else {
                dispatch(ChangeClient(userRole));                
            }

            // Saving user products
            dispatch(UpdateProducts(products));

            // Save user details
            dispatch(UpdateUser(userObject));

        }

    }

    return null;
};

export default Validate;
