import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const AreaCharts = ({
    data = [],
    isValid = false,
    eventType,
    segment,
}) => {
    const formattedDate=(dateString)=> {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        return formattedDate;
      }
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            segment ?
                <g transform={`translate(${x},${y})`}>
                    <text x={8} y={6} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                        {formattedDate(payload.value)}
                    </text>
                </g>
                : <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                         {formattedDate(payload.value)}
                    </text>
                </g>
        );
    };

    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip bg-white p-4 rounded shadow-lg">
                    <p className="date">{`${formattedDate(payload[0].payload.date)}`}</p>
                    <div>
                        <p className={!segment ? `average` : 'central-tooltip'} style={{ color: '#1d617f' }}>{`New Subscriptions: ${payload[0].payload.recurringTotalDonors}`}</p>
                        <p className={!segment ? `average` : 'central-tooltip'} style={{ color: '#287d9f' }}>{`One Time Donations: ${payload[0].payload.oneTimeTotalDonors}`}</p>
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div className='area-chart'>
            <ResponsiveContainer width={"100%"} height={segment ? 300 : 450}>
                <AreaChart
                    width={500}
                    height={400}
                    data={data}
                    tick={<CustomizedAxisTick />}
                    margin={{ top: 5, right: 10, left: -20, bottom: 20 }}
                >
                    <XAxis
                        allowDataOverflow
                        dataKey="date"
                        tick={<CustomizedAxisTick />}
                        domain={['dataMin', 'dataMax']}
                        interval={!segment ? data ? data.length < 31 ? 0 : Math.round(data.length / 30) : 0 : ''}
                        height={70}
                        padding={{
                            right: 10,
                            left: 1
                        }}
                        label={{
                            value: `${eventType === "onetime_vs_recurring" && 'Time (days)'} `,
                            style: { textAnchor: 'middle' },
                            position: 'insideBottom',
                        }}
                    />
                    <YAxis
                        allowDataOverflow={true}
                        interval="preserveStartEnd"
                        width={100}
                        padding={{
                            top: 10,
                            bottom: 4,
                            left:0
                        }}
                        label={{
                            value: `Total Donation Count`,
                            style: { textAnchor: 'middle' },
                            angle: -90,
                            position: 'left',
                            offset: -35,
                        }}
                    />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                    {isValid ? <Tooltip content={<CustomTooltip />} /> : ''}
                    <Area type="monotone" dataKey="oneTimeTotalDonors" stackId="1" stroke={isValid ? "#287d9f" : "white"} fill={isValid ? "#287d9f" : "white"} />
                    <Area type="monotone" dataKey="recurringTotalDonors" stackId="1" stroke={isValid ? "#1d617f" : "white"} fill={isValid ? "#1d617f" : "white"} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default AreaCharts