import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Tabs, Card, Tooltip as AntTooltip } from "antd";
import { addLeadingZero } from "../../Helper/helper";
import Rechart from "../Rechart/Rechart";
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import dayjs from "dayjs";
import { LineChartOutlined, InfoCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const formatDateTick = (tick) => {
  return dayjs(tick).format("D MMM YYYY");
};

const DashboardChart = ({
  revenueTab,
  isArrayEmpty,
  giftTab,
  isGiftArrayEmpty,
  performanceTab,
  isPerformanceArrayEmpty,
  startDate,
  endDate,
  isLoading,
  type = "",
}) => {
  let lineAttributes;
  if (type === "AbandonedGiftsLightbox") {
    lineAttributes = {
      Revenue: [
        {
          dataKey: "onetime",
          stroke: "#006272",
        },
        {
          dataKey: "intialSubscription",
          stroke: "#ff9e1b",
        },
      ],
      Gifts: [
        {
          dataKey: "onetime",
          stroke: "#006272",
        },
        {
          dataKey: "intialSubscription",
          stroke: "#ff9e1b",
        },
      ],
      Performance: [
        {
          dataKey: "onetime",
          stroke: "#006272",
        },
        {
          dataKey: "intialSubscription",
          stroke: "#ff9e1b",
        },
      ],
    };
  } else {
    lineAttributes = {
      Revenue: [
        {
          dataKey: "Revenue",
          stroke: "#006272",
        },
        {
          dataKey: "Source",
          stroke: "#ff9e1b",
        },
        {
          dataKey: "Creative",
          stroke: "#d50032",
        },
        {
          dataKey: "ipPostal",
          stroke: "#0093b2",
        },
      ],
      Gifts: [
        {
          dataKey: "Gifts",
          stroke: "#006272",
        },
        {
          dataKey: "Source",
          stroke: "#ff9e1b",
        },
        {
          dataKey: "Creative",
          stroke: "#d50032",
        },
        {
          dataKey: "ipPostal",
          stroke: "#0093b2",
        },
      ],
      Performance: [
        {
          dataKey: "Performance",
          stroke: "#006272",
        },
        {
          dataKey: "Gifts",
          stroke: "#d50032",
        },
        {
          dataKey: "Source",
          stroke: "#ff9e1b",
        },
        {
          dataKey: "Creative",
          stroke: "#512d6d",
        },
        {
          dataKey: "ipPostal",
          stroke: "#0093b2",
        },
      ],
    };
  }

  const [activeTab, setActiveTab] = useState("Revenue");
  const [currentChartLines, setCurrentChartLines] = useState(
    lineAttributes.Revenue
  );
  const [data, setData] = useState([]);
  const [isDataEmpty, setIsDataEmpty] = useState(true);
  const tabData = {
    Revenue: revenueTab?.RechartArray,
    Gifts: giftTab?.RechartGiftArray,
    Performance: performanceTab?.RechartPerformanceArray,
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentChartLines(lineAttributes[tab]);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setData(tabData[activeTab]);
      setIsDataEmpty(
        activeTab === "Revenue"
          ? isArrayEmpty
          : activeTab === "Gifts"
            ? isGiftArrayEmpty
            : isPerformanceArrayEmpty
      );
    }, 5);

    return () => clearTimeout(timer);
  }, [activeTab, isLoading, revenueTab, giftTab, performanceTab]);

  const formattedDate=(dateString)=> {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    return formattedDate;
  }
  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={5}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          fontSize={"12px"}
        >
          {formattedDate(payload.value)}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload,coordinate }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip p-4 bg-white shadow-lg rounded-md">
          <p className="date font-semibold">{`${formattedDate(payload[0].payload.date)}`}</p>
          {activeTab === "Revenue" && (
            type === "AbandonedGiftsLightbox" ? (
              <div>
                <p className="revenue " style={{ color: "#006272" }}>{`One time gift Revenue: $${addLeadingZero(
                  payload[0].payload.onetime
                )}`}</p>
                <p className="source " style={{ color: "#ff9e1b" }}>{`First time recurring revenue: $${addLeadingZero(
                  payload[0].payload.intialSubscription
                )}`}</p>
              </div>
            ) : (
              <div>
                <p className="revenue " style={{ color: "#006272" }}>{`Overall Revenue: $${addLeadingZero(
                  payload[0].payload.Revenue
                )}`}</p>
                <p className="source " style={{ color: "#ff9e1b" }}>{`Leading Source: $${addLeadingZero(
                  payload[0].payload.Source
                )}`}</p>
                <p className="creative " style={{ color: "#d50032" }}>{`Leading Creative: $${addLeadingZero(
                  payload[0].payload.Creative
                )}`}</p>
                <p className="zipcode " style={{ color: "#0093b2" }}>{`Leading ZipCode: $${addLeadingZero(
                  payload[0].payload.ipPostal
                )}`}</p>
              </div>
            )
          )}
          {activeTab === "Gifts" && (
            type === "AbandonedGiftsLightbox" ? (
              <div>
                <p className="revenue " style={{ color: "#006272" }}>{`One time gifts: $${addLeadingZero(
                  payload[0].payload.onetime
                )}`}</p>
                <p className="source " style={{ color: "#ff9e1b" }}>{`First time recurring gifts: $${addLeadingZero(
                  payload[0].payload.intialSubscription
                )}`}</p>
              </div>
            ) : (
              <div>
                <p className="revenue " style={{ color: "#006272" }}>{`Gifts: ${addLeadingZero(
                  payload[0].payload.Gifts
                )}`}</p>
                <p className="source " style={{ color: "#ff9e1b" }}>{`Leading Source: ${addLeadingZero(
                  payload[0].payload.Source
                )}`}</p>
                <p className="creative " style={{ color: "#d50032" }}>{`Leading Creative: ${addLeadingZero(
                  payload[0].payload.Creative
                )}`}</p>
                <p className="zipcode " style={{ color: "#0093b2" }}>{`Leading ZipCode: ${addLeadingZero(
                  payload[0].payload.ipPostal
                )}`}</p>
              </div>
            )
          )}
          {activeTab === "Performance" && (
            <div>
              <p className="revenue " style={{ color: "#006272" }}>{`Click through rate: $${addLeadingZero(
                payload[0].payload.onetime
              )}`}</p>
              <p className="source " style={{ color: "#ff9e1b" }}>{`Conversion rate: $${addLeadingZero(
                payload[0].payload.intialSubscription
              )}`}</p>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="dashboard-chart flex flex-col md:flex-row">
      <Card className="flex-1">
        <div className="card-header flex items-center justify-between bg-gradient-to-r from-purple-800 to-purple-600 p-4 text-white rounded">
          <LineChartOutlined className="text-5xl" />
          {activeTab === "Revenue" && (
            <h2 className="text-3xl text-white">
              <span className="font-bold">Revenue</span> -{" "}
              {`${dayjs(startDate).format("MMM D, YYYY")} to ${dayjs(
                endDate
              ).format("MMM D, YYYY")}`}
            </h2>
          )}
          {activeTab === "Gifts" && (
            <h2 className="text-3xl text-white">
              <span className="font-bold">Gifts</span> -{" "}
              {`${dayjs(startDate).format("MMM D, YYYY")} to ${dayjs(
                endDate
              ).format("MMM D, YYYY")}`}
            </h2>
          )}
          <AntTooltip className="rounded-xl"
            title={
              activeTab === "Revenue" ? (
                <div className="tabs-main-container bg-white border-none">
                  <div className="tabs-containt">
                    <div className="revenue" />
                    <div className="ml-5"><span className="icons-containt " style={{ color: "#006272" }}>Overall Revenue</span></div>
                  </div>
                  <div className="tabs-containt">
                    <div className="source" />
                    <div className="ml-5 leading-data">
                      <span className="icons-containt " style={{ color: "#ff9e1b" }}>Leading Source &nbsp;=&nbsp;
                        <span className="leading-containt">
                          {(revenueTab.leadingSource && (revenueTab.leadingSource === "Others" ? 'N/A' : revenueTab.leadingSource)) || 'N/A'}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="tabs-containt">
                    <div className="creative" />
                    <div className="ml-5 leading-data">
                      <span className="icons-containt " style={{ color: "#d50032" }}>Leading Creative &nbsp;=&nbsp;
                        <span className="leading-containt">
                          {(revenueTab.leadingCreative && (revenueTab.leadingCreative === "Others" ? 'N/A' : revenueTab.leadingCreative)) || 'N/A'}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="tabs-containt">
                    <div className="zipcode" />
                    <div className="ml-5 leading-data">
                      <span className="icons-containt text-blue-400">Leading ZipCode &nbsp;=&nbsp;
                        <span className="leading-containt">
                          {(revenueTab.RevenueLeadingZipcode && (revenueTab.RevenueLeadingZipcode === "Others" ? 'N/A' : revenueTab.RevenueLeadingZipcode)) || 'N/A'}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="tabs-main-container bg-white border-none">
                  <div className="tabs-containt">
                    <div className="creative" />
                    <div className="ml-5"><span className="icons-containt " style={{ color: "#006272" }}>Overall Gifts</span></div>
                  </div>
                  <div className="tabs-containt">
                    <div className="source" />
                    <div className="ml-5 leading-data">
                      <span className="icons-containt " style={{ color: "#ff9e1b" }}>Leading Source &nbsp;=&nbsp;
                        <span className="leading-containt">
                          {(giftTab.giftLeadingSource && (giftTab.giftLeadingSource === "Others" ? 'N/A' : giftTab.giftLeadingSource)) || 'N/A'}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="tabs-containt">
                    <div className="revenue" />
                    <div className="ml-5 leading-data">
                      <span className="icons-containt text-red-700">Leading Creative &nbsp;=&nbsp;
                        <span className="leading-containt">
                          {(giftTab.giftLeadingCreative && (giftTab.giftLeadingCreative === "Others" ? 'N/A' : giftTab.giftLeadingCreative)) || 'N/A'}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="tabs-containt">
                    <div className="zipcode" />
                    <div className="ml-5 leading-data">
                      <span className="icons-containt text-blue-400">Leading ZipCode &nbsp;=&nbsp;
                        <span className="leading-containt">
                          {(giftTab.GiftLeadingZipcode && (giftTab.GiftLeadingZipcode === "Others" ? 'N/A' : giftTab.GiftLeadingZipcode)) || 'N/A'}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )
            }
            trigger="click"
          >
            <div className="flex flex-col items-center">
              <InfoCircleOutlined className="ml-2 text-white text-2xl cursor-pointer" />
              <p className="text-white">Overall Summary</p>
            </div>
          </AntTooltip>

        </div>
        <Tabs defaultActiveKey="Revenue" onChange={handleTabChange}>
          <TabPane tab="Revenue" key="Revenue">
            <div className="overflow-x-auto mb-6">
              {isLoading ? (
                <SkeletonLine />
              ) : isDataEmpty ? (
                <div className="text-center py-8">No data available</div>
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={data} margin={{ bottom: 20,right:35,left:20,top:5 }}>
                    <XAxis
                      dataKey="date"
                      tickFormatter={formatDateTick}
                      tick={<CustomizedAxisTick />}
                    />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    {currentChartLines.map((line, index) => (
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={line.dataKey}
                        stroke={line.stroke}
                        strokeWidth={2}
                        dot={false}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>
          </TabPane>
          <TabPane tab="Gifts" key="Gifts">
            <div className="overflow-x-auto mb-6">
              {isLoading ? (
                <SkeletonLine />
              ) : isDataEmpty ? (
                <div className="text-center py-8">No data available</div>
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={data} margin={{ bottom: 20,right:35,left:20,top:5 }}>
                    <XAxis
                      dataKey="date"
                      tickFormatter={formatDateTick}
                      tick={<CustomizedAxisTick />}
                    />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    {currentChartLines.map((line, index) => (
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={line.dataKey}
                        stroke={line.stroke}
                        strokeWidth={2}
                        dot={false}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>
          </TabPane>
        </Tabs>
        <div className="card-footer flex justify-center bg-gray-200 p-4">
                    {activeTab === "Revenue" && (
                        <div>Your top earner, <strong>{(revenueTab.leadingCreative && (revenueTab.leadingCreative == "Others" ? 'N/A' : revenueTab.leadingCreative) || 'N/A')}</strong> creative, brought in <strong>{(revenueTab.revenueTotalAmount || 'N/A')}</strong> total with an average of <strong>{(revenueTab.revenueAverageAmount || 'N/A')}</strong> per gift.</div>
                    )}
                    {activeTab === "Gifts" && (
                        <div>Your top gift magnet, <strong> {(giftTab.giftLeadingCreative && (giftTab.giftLeadingCreative == "Others" ? 'N/A' : giftTab.giftLeadingCreative) || 'N/A')}</strong> creative, saw <strong>{giftTab.giftLeadingCreative ? giftTab.totalGifts : 'N/A'}</strong> gifts (averaging <strong> {(giftTab.giftAverageAmount || 'N/A')} </strong>per gift).</div>
                    )}

                </div>
      </Card>
    </div>
  );
};

export default DashboardChart;
