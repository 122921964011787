import React from 'react'
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const DeleteAction = ({ row, delete_search }) => {
    return (
        <span>
            <Button
                title="delete"
                onClick={() => delete_search(row)}
                style={{ background: 'red', color: 'white' }}
                type="danger"
                size="small"
                icon={<DeleteOutlined />} />
        </span>
    )
}

export default DeleteAction;