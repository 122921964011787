// packages
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

// Import Columns
import {
  general_email_submitted as emailSignUps,
} from "../ClientData/actions";

//import components
import { ChangeInitial } from "../../redux/Main/action";
import ClientChoose from "../Clients/chooseClient";
import DynamicCards from "../Shared/DynamicCards";
import { IdentityApi } from "../../utils/constants";
import SignupEventChart from "../../Components/EventCharts/SignupEventChart";
import { addLeadingZero } from "../../Helper/helper";

const { RangePicker } = DatePicker;


const EmailSignUps = () => {

  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { role, client } = useSelector(({ main }) => main);

  const [average, setaverage] = useState([]);
    const [data, setData] = useState([]);
    const [datePopup, setDatePopup] = useState(true);
    const [piedata, setPieData] = useState([]);
    const [bardata, setBarData] = useState([]);
    const [growthratedata, setGrowthRateData] = useState([]);
    const [dates, setDates] = useState({
      startDate: dayjs().subtract(6, 'day'),
      endDate: dayjs()
    });
    const [isLoading, setisLoading] = useState(true);
    const [ApiData, setApiData] = useState({});
    const [isSelect, setIsSelect] = useState(false);

    useEffect(() => {
      fetchData();
    }, []);
    
    useEffect(() => {
      if (isSelect && !datePopup && dates.startDate && dates.endDate) {
        fetchData();
        setIsSelect(false);
      }
    }, [datePopup]);

    useEffect(() => {
        setDates({
            startDate: dayjs().subtract(7, 'day'),
            endDate: dayjs()
        })
    }, [client]);

    const fetchData = async () => {
        let Array = [];
        let pieArray = [];
        let barArray = [];
        let growthDataArray = [];
        try {
            setisLoading(true);

            // Parse start and end dates
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
            const dateDifference = moment(endDate).diff(moment(startDate), 'days');

            const formData = {
                startDate: startDate,
                endDate: endDate,
                client: client.toLowerCase() // client name
            };

            const results = await axios.post(`${IdentityApi}query/getEmailSignup`, formData);
            if (results.data.status === 'success') {
              if (results.data.data[0]) {
                // for line chart and pie chart
                const countsData = results.data.data[0]?.dateWiseData;
                setApiData(results.data.data[0]?.dateWiseData);
                Array = [];
                let finalArr = [];
                for (let i = 0; i <= dateDifference; i++) {
                    // for line chart
                    const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                    const matchingData = countsData[currentDate] || [];
                    const total = matchingData?.totalLenght?.count ? matchingData?.totalLenght?.count : 0;
                    // for pie chart
                    const datewiseData = countsData[currentDate]?.signupData ? countsData[currentDate].signupData : [];
                    finalArr = [...finalArr, ...datewiseData];

                    Array.push({ date: currentDate, average: parseFloat(total) });
                }
                let objAr = {};
                finalArr.filter((e) => {
                  if (e.url.includes("&source=")) {
                    const splitVal = e.url.split("&source=");
                    const str = splitVal[1];
                    if (str) {
                      const finalSplit = str.split("&");
                      objAr[finalSplit[0]] = { count: (objAr[finalSplit[0]]?.count || 0) + 1 }
                    }
                  }
                });
                pieArray = Object.keys(objAr).map((obj) => {
                  const name = obj;
                  const value = objAr[name]?.count;
                  return { name, value };
                })

                let allData = results.data.data[0];
                // for bar chart
                // for getting uniqueVisitor with email campaign
                const emaildatas = allData?.emailData.filter((e) => e?.utm_medium == "email" && e?.utm_campaign !== "");
                let uniqueVisitor = {};
                for (let i in emaildatas) {
                  uniqueVisitor[emaildatas[i]['utm_campaign']] = {
                    count: (uniqueVisitor[emaildatas[i]['utm_campaign']]?.count || 0) + 1
                  };
                }
                
                // for getting uniqueVisitor
                const pageViewdatas = allData?.page_view_data;
                for (const key in uniqueVisitor) {
                  if (uniqueVisitor.hasOwnProperty(key)) {
                    const keys = pageViewdatas.filter((e) => e.utm_campaign == key);
                    const pagedata =  (keys[0] || {});
                    let con_rate = (uniqueVisitor[key].count / (pagedata.count ? pagedata.count : 0)) * 100;
                    barArray.push({ name: key, value: parseFloat(addLeadingZero(parseFloat(con_rate).toFixed(2))) });
                  }
                }

                console.log("line chart Array ::", Array);
                console.log("bar chart Array ::", barArray);
                console.log("pie chart Array ::", pieArray);

                barArray = formatChartData(barArray); // Sort by desc and take top 5 records
                pieArray = formatChartData(pieArray); // Sort by desc and take top 5 records

                growthDataArray = aggregateWeeklyData(Array);
                console.log("growthDataArray", growthDataArray);
                setData(Array);
                setPieData(pieArray);
                setBarData(barArray);
                setaverage(Array);
                setGrowthRateData(growthDataArray);
                setisLoading(false);
              } else {
                Array = [];
                const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
                const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
                const dateDifference = moment(endDate).diff(moment(startDate), 'days');
                //calculate dashbard Rechart  
                for (let i = 0; i <= dateDifference; i++) {
                    // Get the current date
                    const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                    Array.push({ date: currentDate, average: 0, y: 0 });
                    growthDataArray.push({ date: currentDate, subscribers: 0, y: 0 });
                }
                setData(Array);
                setPieData(pieArray);
                setBarData(barArray);
                setApiData({});
                setGrowthRateData(growthDataArray);
                setisLoading(false);
                console.error("No data found:");
              }
            }
        } catch (error) {
            Array = [];
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            //calculate dashbard Rechart  
            for (let i = 0; i <= dateDifference; i++) {
                // Get the current date
                const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                Array.push({ date: currentDate, average: 0, y: 0 });
                growthDataArray.push({ date: currentDate, subscribers: 0, y: 0 });
            }
            setData(Array);
            setPieData(pieArray);
            setBarData(barArray);
            setApiData({});
            setGrowthRateData(growthDataArray);
            setisLoading(false);
            console.error("An error occurred:", error);
        }
    };

    //Date-Range change function.
    const handleDateChange = async (date, dateString) => {
      if (dateString && dateString[0] && dateString[1]) {
        setIsSelect(true);
        setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) });
      }
    }
    
    const handle = async (val) => {
      setDatePopup(val);
    }

    // Sort data by desc and take top 5 records
    const formatChartData = (finalData) => {
      let sortData = finalData.sort((a, b) => b.value - a.value);
      const topFive = sortData.slice(0, 5);

      return topFive;
    };

    const aggregateWeeklyData = (data) => {
      const weeks = {};
  
      data.forEach(item => {
          const date = new Date(item.date);
          // Adjust to the beginning of the week, assuming week starts on Monday
          const weekStart = new Date(date);
          weekStart.setDate(weekStart.getDate() - (weekStart.getDay() === 0 ? 6 : weekStart.getDay() - 1));
  
          // Format weekStart to string to use as a key
          const weekKey = weekStart.toISOString().split('T')[0];
  
          if (weeks[weekKey]) {
              weeks[weekKey] += item.average;
          } else {
              weeks[weekKey] = item.average;
          }
      });
  
      // Convert the object to an array of objects
      return Object.keys(weeks).map(weekStart => ({
          date: weekStart,
          subscribers: weeks[weekStart],
          lastDate: moment(new Date(new Date(weekStart).getTime() + 6 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD")
      }));
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setData(tabData['average']);
        }, 5);

        return () => clearTimeout(timer);
    }, [average]);

    const tabData = {
        average: average
    };

  useEffect(() => {
    dispatch(ChangeInitial("Signup Success"));
  }, [dispatch]);

  return (
    <div className="container-fluid">
      {isAuthenticated && role === "admin" && (
        <ClientChoose alertType="alert alert-success">
          <span>
            Welcome <strong>Team Member</strong>. Please choose a client to
            view/edit their data.
          </span>
        </ClientChoose>
      )}
      {client !== "NONE" && (
        <>
          <div className="dashboard dynamic-card">
            <div className="date-range card">
              <div className="card-content">
                <div className="row">
                  <div className="col-md-9 col-sm-9">
                    <RangePicker
                      defaultValue={[dates.startDate, dates.endDate]}
                      onChange={handleDateChange}
                      onOpenChange={handle}
                      allowClear={false}
                      size={"middle"}
                      variant="borderless"
                      format={"YYYY-MM-DD"}
                      separator={<i className="material-icons">arrow_right_alt</i>}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Chart Component */}
            <SignupEventChart eventType="total_signups_over_time" data={data} ApiData={ApiData} isLoading={isLoading}/>
            <SignupEventChart eventType="signup_conversion_rate_by_campaign" data={bardata} ApiData={bardata.length == 0 ? {} : ApiData} isLoading={isLoading}/>
            <SignupEventChart eventType="signup_source_distribution" data={piedata} ApiData={piedata.length == 0 ? {} : ApiData} isLoading={isLoading}/>
            <SignupEventChart eventType="subscriber_growth_rate" data={growthratedata} ApiData={growthratedata.length == 0 ? {} : ApiData} isLoading={isLoading}/>

            {/* Signup Success Card */}
            <DynamicCards
              title="Signup Success"
              formType="general_email_submitted"
              headerIcon="drafts"
              background="linear-gradient(60deg, rgb(0, 98, 114), rgb(0, 59, 73))"
              // background="linear-gradient(60deg, rgb(0, 98, 114), rgb(0, 59, 73))"
              columns={emailSignUps}
              card={["Person", "Device"]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EmailSignUps;