import React, { useState, useEffect } from 'react';
import { Table, Select, Input, Button, Pagination, Dropdown, Menu, Space, Skeleton, message } from 'antd';
import { TableOutlined, AppstoreOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the CSS for blur effect

const { Option } = Select;
const { Search } = Input;

const ActivationList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [view, setView] = useState('table');
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(current, pageSize, filter);
  }, [current, pageSize, filter]);

  const fetchData = async (page, size, filter) => {
    setLoading(true);
    try {
      const response = await axios.get('https://z0eepnybq0.execute-api.us-east-1.amazonaws.com/dev/v1/fetch', {
        params: { r: 'activations', c: page, l: size }
      });
      const responseData = response.data.map(item => item.activations);
      setData(responseData);
      setTotal(response.headers['x-total-count'] || responseData.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => setFilter(value);

  const handleChangeView = (newView) => setView(newView);

  const handleEdit = (id) => {
    navigate(`/admin/activations/id/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://z0eepnybq0.execute-api.us-east-1.amazonaws.com/dev/v1/activations/${id}`);
      message.success('Activation deleted successfully');
      fetchData(current, pageSize, filter);
    } catch (error) {
      console.error('Error deleting activation:', error);
      message.error('Failed to delete activation');
    }
  };

  const handleNewActivation = () => {
    navigate('/admin/activations');
  };

  const columns = [
    { title: 'Activation Name', dataIndex: 'name', key: 'name' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Headline', dataIndex: 'headlineText', key: 'headlineText' },
    { title: 'Status', dataIndex: 'isActive', key: 'isActive', render: isActive => (
      <span className={isActive ? 'text-green-500' : 'text-red-500'}>
        {isActive ? 'Published' : 'Unpublished'}
      </span>
    ) },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleEdit(record.id)}>Edit</Menu.Item>
              <Menu.Item key="2" onClick={() => handleDelete(record.id)}>Delete</Menu.Item>
            </Menu>
          }
        >
          <Button>...</Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="p-4">
      <div className="flex justify-end mb-4">
        <Space className='hidden'>
          <Select defaultValue="Activation" style={{ width: 120 }}>
            <Option value="activation">Activation</Option>
            <Option value="impression">Impression</Option>
            <Option value="conversion">Conversion</Option>
            <Option value="status">Status</Option>
          </Select>
          <Search placeholder="Search" onSearch={handleSearch} style={{ width: 200 }} />
        </Space>
        <div>
          <Button type="primary" className="mr-2" onClick={handleNewActivation}>+ New Activation</Button>
          <Space>
            <Button 
              type={view === 'table' ? 'primary' : 'default'} 
              icon={<TableOutlined />} 
              onClick={() => handleChangeView('table')} 
            />
            <Button 
              type={view === 'tile' ? 'primary' : 'default'} 
              icon={<AppstoreOutlined />} 
              onClick={() => handleChangeView('tile')} 
            />
          </Space>
        </div>
      </div>
      {view === 'table' ? (
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
          rowKey="id"
        />
      ) : (
        <div className="grid grid-cols-4 gap-4">
          {loading ? (
            Array.from({ length: pageSize }).map((_, index) => (
              <div key={index} className="flex flex-col p-4 border rounded shadow-xl bg-white">
                <Skeleton.Image className="w-full h-40 mb-2" />
                <Skeleton active paragraph={{ rows: 3 }} />
              </div>
            ))
          ) : (
            data.map(item => (
              <div key={item.id} className="flex flex-col p-4 border rounded shadow-xl bg-white">
                <LazyLoadImage
                  src={item.bannerImageSrc}
                  alt={item.name}
                  effect="blur"
                  className="w-full h-auto mb-2"
                />
                <div className="flex-1">
                  <h3 className="font-bold">{item.name}</h3>
                  <p>Type: {item.type}</p>
                  <p>Headline: {item.headlineText}</p>
                  <p className={item.isActive ? 'text-green-500' : 'text-red-500'}>
                    Status: {item.isActive ? 'Published' : 'Unpublished'}
                  </p>
                </div>
                <div className="flex space-x-2 mt-auto">
                  <Button onClick={() => handleEdit(item.id)}>Edit</Button>
                  <Button onClick={() => handleDelete(item.id)}>Delete</Button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
      <Pagination
        current={current}
        pageSize={pageSize}
        total={total}
        onChange={page => setCurrent(page)}
        onShowSizeChange={(current, size) => setPageSize(size)}
        className="mt-4"
      />
    </div>
  );
};

export default ActivationList;
