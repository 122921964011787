import React from 'react'
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const LoadSearchAction = ({ row, load_search }) => {
    return (
        <span>
            <Button
                title="load"
                id={row.value}
                onClick={() => load_search(row)}
                type="default"
                size="small"
                icon={<DownloadOutlined />}
            />
        </span>
    )
}

export default LoadSearchAction;