import React from "react";
import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Tooltip,
    Legend
} from "recharts";

const EventPieChart = ({ data }) => {
    let datas = [{ name: 'no data found', value: 1 }];
    const COLORS = ['#004A6C', '#0E7D9F', '#41B0D2', '#1A237E', '#303F9F', '#3F51B5', '#263238', '#455A64', '#607D8B'];

    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <>
                        <div>
                            <p className="average" style={{ color: '#287d9f' }}>{`${payload[0].payload.name}: ${payload[0].payload.value}`}</p>
                        </div>
                    </>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer width="100%" height={500}>
                {data?.length == 0 ? (
                    <PieChart width={800} height={500}>
                        <Pie
                            data={datas}
                            cx={'50%'}
                            cy={'45%'}
                            innerRadius={130}
                            outerRadius={160}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                        >
                            {datas?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill="#f3f6f9" />
                            ))}
                        </Pie>
                    </PieChart>
                ) : (
                    <PieChart width={500} height={500}>
                        <Pie
                            data={data}
                            cx={'50%'}
                            cy={'50%'}
                            innerRadius={130}
                            outerRadius={160}
                            fill="#8884d8"
                            paddingAngle={2}
                            dataKey="value"
                        >
                            {data?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend />
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                )}
            </ResponsiveContainer>
        </div>
    )
}

export default EventPieChart;
