const clientType = {
    USER_PROFILE: "USER_PROFILE",
    UPDATE_USER_TYPE: "UPDATE_USER_TYPE",
    CHANGE_ROLE: "CHANGE_ROLE",
    CHANGE_CLIENT: "CHANGE_CLIENT",
    CHANGE_INITIAL: "CHANGE_INITIAL",
    CHANGE_CLIENT_TYPE: "CHANGE_CLIENT_TYPE",
    UPDATE_MULTICHANNEL_REPORTING_STAGE: "UPDATE_MULTICHANNEL_REPORTING_STAGE",
    UPDATE_MULTICHANNEL_REPORTING_LIVE: "UPDATE_MULTICHANNEL_REPORTING_LIVE",
    CHANGE_SIDEBAR: "CHANGE_SIDEBAR",
    CHANGE_DOMAIN: "CHANGE_DOMAIN",
    CHANGE_EXPORT_DATA: "CHANGE_EXPORT_DATA",
    CHANGE_QUERY_DATA: "CHANGE_QUERY_DATA",
    CHANGE_EXPORT_DATA_S3URL: "CHANGE_EXPORT_DATA_S3URL",
    CHANGE_IMAGE_DATA: "CHANGE_IMAGE_DATA",
    CHANGE_CREATIVE_DATA: "CHANGE_CREATIVE_DATA",
    CHANGE_HTML: "CHANGE_HTML",
    CHANGE_CURRENTE_MAIL: "CHANGE_CURRENTE_MAIL",
    CHANGE_CAMPAIGN_STORED: "CHANGE_CAMPAIGN_STORED",
    UPDATE_CLIENT_DATA: "UPDATE_CLIENT_DATA",
    UPDATE_CREATIVES: "UPDATE_CREATIVES",
    SWAP_DASHBOARD: "SWAP_DASHBOARD",
    CHANGE_CREATIVE_NAME: "CHANGE_CREATIVE_NAME",
    CHANGE_QUESTION_TYPE: "CHANGE_QUESTION_TYPE",
    CHANGE_QUESTION_DATA: "CHANGE_QUESTION_DATA",
    CHANGE_USERID: "CHANGE_USERID",
    CHANGE_CAMPAIGN_YEAR: "CHANGE_CAMPAIGN_YEAR",
    CHANGE_TICKET_DATA: "CHANGE_TICKET_DATA",
    CHANGE_BREADCRUMBS: "CHANGE_BREADCRUMBS",
    CHANGE_LAYOUT: "CHANGE_LAYOUT",
    CHANGE_FORM_CREATIVE_DATA: "CHANGE_FORM_CREATIVE_DATA",
    CHANGE_ISCOLOR_SETTING: "CHANGE_ISCOLOR_SETTING",
    CHANGE_COLOR_SETTING_NEIGHBOR: "CHANGE_COLOR_SETTING_NEIGHBOR",
    CHANGE_COLOR_SETTING_GIFTING: "CHANGE_COLOR_SETTING_GIFTING",
    CHANGE_ISMINIMUM_AMOUNT: "CHANGE_ISMINIMUM_AMOUNT",
    CHANGE_MINIMUM_AMOUNT_VALUE: "CHANGE_MINIMUM_AMOUNT_VALUE",
    CHANGE_DONOR_PORTAL_IMAGE_DATA: "CHANGE_DONOR_PORTAL_IMAGE_DATA",
    UPDATE_CLIENT_METADATA: "UPDATE_CLIENT_METADATA",
    CHANGE_SUB_CAMPAIGN_DATA: "CHANGE_SUB_CAMPAIGN_DATA",
    GET_CLIENT_DASHBOARD: "GET_CLIENT_DASHBOARD",

}

export default clientType;