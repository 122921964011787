import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Login from "./Views/Login/Login";
import CallbackPage from "./Views/Callback/Callback";
import { Provider } from "react-redux";
import { AUTH_CONFIG } from "./Security/Auth/auth0-variables";
import { storeReducer } from './redux/store.js';
import Wrapper from "./Views/Wrapper/Wrapper";
import { AuthenticationGuard } from "./Components/Authentication/authentication-guard";
import { routesList } from "./Routing/routesList";
// import './Styling/App.scss';
import './index.css'; // Custom styles for the app

const App = () => {

    const renderRoutes = () => {
        console.log(" app load 2 ___");
        return routesList.map((route, i) => {
            return <Route path={route.path} element={<AuthenticationGuard component={route.componentName} />} key={i} />;
        });
    };
    
    return (
        <div>
            <Provider store={storeReducer()}>
                <BrowserRouter>
                    <Auth0Provider
                        domain={AUTH_CONFIG.domain}
                        clientId={AUTH_CONFIG.clientId}
                        redirectUri={AUTH_CONFIG.callbackUrl}
                        audience={`https://${AUTH_CONFIG.domain}/userinfo`}
                        responseType={'token id_token'}
                        cacheLocation="localstorage"
                        scope={'openid profile email role'}
                    >
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/callback" element={<CallbackPage />} />
                            <Route path="/" exact element={<Login />} />
                        </Routes>
                        <Wrapper>
                            <Routes>
                                {renderRoutes()}                                
                            </Routes>
                        </Wrapper>
                    </Auth0Provider>
                </BrowserRouter>
            </Provider>
        </div>
    )
}

export default App;