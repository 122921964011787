import React, { useEffect, useState } from 'react'
import {
    LineChart,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Line,
} from "recharts";
import moment from 'moment';
import dayjs from 'dayjs';

const Rechart = ({ startDate, endDate }) => {

    const [chartData, setChartData] = useState([])
    useEffect(() => {
        let Array = [];
        let tempStartDate = dayjs(startDate).format('YYYY-MM-DD');
        let tempEndDate = dayjs(endDate).format('YYYY-MM-DD');
        let start = moment(tempStartDate);
        let end = moment(tempEndDate);
        let dateDifference = end.diff(start, 'days');
        //calculate dashbard Rechart  
        for (let i = 0; i <= dateDifference; i++) {
            // Get the current date
            let currentDate = moment(tempStartDate).add(i, "days").format("MM-DD-YYYY");
            Array.push({ date: currentDate, average: 0 });
        }
        setChartData(Array)
    }, [startDate, endDate])
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                    {payload.value}
                </text>
            </g>
        );
    };

    return (
        <div className='col-md-12'>
            <div className='charts'>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={chartData}>
                        <XAxis
                            allowDataOverflow
                            tick={<CustomizedAxisTick />}
                            dataKey="date"
                            interval={chartData.length < 31 ? 0 : Math.round(chartData.length / 30)}
                            height={70}
                        />
                        <YAxis />
                        <Line strokeWidth={0} dot={false} type="monotone" dataKey='average' stroke="#eee" />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className='rechart-text'>No Data Found</div>
        </div>
    )
}

export default Rechart
