import UserTypes from "./type";

let initialState = {
    usersAPI: '',
    userAction: {
        type: "NONE",
        userId: "NONE",
    },
};

const UserReducer = (state = initialState, action) => {

    switch (action.type) {

        case UserTypes.CHANGE_USER_ACTION:
            return {
                ...state,
                userAction: action.payload
            }

        default:
            return state;
    }

}

export default UserReducer;