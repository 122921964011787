import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Drawer } from 'antd';
import {
  AppstoreTwoTone,
  PieChartTwoTone,
  DollarTwoTone,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  GiftTwoTone,
  MessageTwoTone,
  ApiTwoTone,
  CrownTwoTone
} from '@ant-design/icons';
import axios from 'axios';
import {
  updateClientMetaData,
  ChangeClientType,
  UpdateMultiChannelReportingStage,
  UpdateMultiChannelReportingLive,
} from '../redux/Main/action';
import '../index.css'; // Custom styles for sidebar

const { Sider } = Layout;
const siderStyle = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
};
const Sidebar = ({ toggleCollapsed, collapsed }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useSelector(({ main }) => main.client);
  const api = useSelector(({ main }) => main.api);

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const getClient = useCallback(() => {
    let clientConfig = `${api}getClientConfig?client=${client}`;

    axios.get(clientConfig).then((response) => {
      const { data: config } = response.data;
      let url = window.location.href;

      dispatch(ChangeClientType(config.clientType));
      dispatch(updateClientMetaData(config));

      if (url.indexOf('/home') !== -1 && config.clientType === 'Portal') {
        navigate('/dashboard/donorPortal/v2/transactionUpload');
      }

      if (config.multichannel && config.multichannel.multiChannelReportingStage === 'true') {
        config.multichannel.multiChannelReportingStage = true;
      }

      dispatch(UpdateMultiChannelReportingStage(config.multichannel && config.multichannel.multiChannelReportingStage));

      if (config.multichannel && config.multichannel.multiChannelReportingLive === 'true') {
        config.multichannel.multiChannelReportingLive = true;
      }

      dispatch(UpdateMultiChannelReportingLive(config.multichannel && config.multichannel.multiChannelReportingLive));
    }).catch((error) => {
      console.log('Error >>> ', error);
    });
  }, [api, client, dispatch, navigate]);

  useEffect(() => {
    if (client !== 'NONE') {
      getClient();
    }
  }, [client, getClient]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const getSelectedKey = () => {
    const path = location.pathname;
    if (path.startsWith('/admin/identity-dashboard')) {
      return '1';
    } else if (path.startsWith('/admin/give-central')) {
      return '2';
    } else if (path.startsWith('/admin/engaged-sessions')) {
      return '3';
    } else if (path.startsWith('/admin/traffic-trends')) {
      return '4';
    } else if (path.startsWith('/admin/activationsList')) {
      return '5';
    } else if ((path.startsWith('/admin/CreateConnections')) || (path.startsWith('/admin/createConnections'))) {
      return '6';
    } else if (path.startsWith('/admin/connections')) {
      return '6';
    } else {
      return '1'; // Default to "Dashboard" if no match
    }
  };

  const selectedKey = getSelectedKey();
  const menuItems = [
    {
      key: '1',
      icon: <AppstoreTwoTone />,
      label: 'Dashboard',
      onClick: () => {
        navigate('/admin/identity-dashboard');
        if (isMobile) toggleCollapsed(); // Collapse sidebar on mobile
      },
    },
    {
      key: '2',
      icon: <GiftTwoTone />,
      label: 'Giving Central',
      onClick: () => {
        navigate('/admin/give-central');
        if (isMobile) toggleCollapsed(); // Collapse sidebar on mobile
      },
    },
    {
      key: '3',
      icon: <MessageTwoTone />,
      label: 'Engaged Sessions',
      onClick: () => {
        navigate('/admin/engaged-sessions');
        if (isMobile) toggleCollapsed(); // Collapse sidebar on mobile
      },
    },
    {
      key: '4',
      icon: <PieChartTwoTone />,
      label: 'Traffic Trends',
      onClick: () => {
        navigate('/admin/traffic-trends');
        if (isMobile) toggleCollapsed(); // Collapse sidebar on mobile
      },
    },
    {
      key: '5',
      icon: <DollarTwoTone />,
      label: 'Activations',
      onClick: () => {
        navigate('/admin/activationsList');
        if (isMobile) toggleCollapsed(); // Collapse sidebar on mobile
      },
    },
    {
      key: '6',
      icon: <ApiTwoTone />,
      label: 'Connections',
      onClick: () => navigate('/admin/connections'),
    }, 
  ];

  return (
    <Sider
    trigger={null}
    collapsible
    collapsed={collapsed}
    onCollapse={toggleCollapsed}
    breakpoint="lg"
    collapsedWidth={isMobile ? (collapsed ? 'auto' : 201) : (collapsed ? 82 : 201)} // Adjust width dynamically
    style={{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: collapsed && isMobile ? '-200px' : 0, // Move sidebar out of view on mobile when collapsed
      top: 0,
      zIndex: 1000,
      transition: 'left 0.1s', // Smooth transition when showing/hiding the sidebar
    }}
  >
    <div className="flex items-center justify-between p-4">
      {!collapsed && (
        <div className="w-full">
          <img
            src="https://rkdgroup.com/wp-content/uploads/2023/02/logo-rkd.svg"
            alt="Logo"
            className="h-28 w-full object-contain"
          />
        </div>
      )}
    </div>
  {/* Collapse Button - Always visible */}
  <div
      style={{
        position: 'fixed',
        top: 10,
        left: collapsed && isMobile ? 2 : (collapsed ? 90 : 178), // Adjust button position based on sidebar state
        zIndex: 1101,
        transition: 'left 0.3s', // Smooth transition for button position
      }}
    >
      <button
        onClick={toggleCollapsed}
        style={{
          border: 'none',
          background: 'transparent',
          fontSize: '20px',
          cursor: 'pointer',
        }}
      >
        {collapsed ? <MenuUnfoldOutlined className='text-blue-700' /> : <MenuFoldOutlined className='text-blue-700' />}
      </button>
    </div>

    <Menu theme="light" mode="inline" defaultSelectedKeys={['1']} selectedKeys={[selectedKey]} items={menuItems} />
  </Sider>
  );
};

export default Sidebar;