import React, { useState, useEffect } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";

const LineCharts = ({
    data = [],
    isValid = false,
    eventType,
    segment,
}) => {
    const [showChart, setShowChart] = useState([]);

    useEffect(() => {
        setShowChart(data);
    }, []);
    const formattedDate=(dateString)=> {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        return formattedDate;
      }
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            segment ?
                <g transform={`translate(${x},${y})`}>
                    <text x={10} y={14} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                        {formattedDate(payload.value)}
                    </text>
                </g>
                : <g transform={`translate(${x},${y})`}>
                    <text x={5} y={-1} dy={10} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                          {formattedDate(payload.value)}
                    </text>
                </g>
        );
    };

    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip bg-white p-4 rounded shadow-lg">
                    <p className="date">{`${formattedDate(payload[0].payload.date)}`}</p>
                    {eventType === "average_donation_amount" &&
                        <>
                            <div>
                                <p className={!segment ? `average` : 'central-tooltip'} style={{ color: '#4c9ef5' }}>{`One-Time Average Donation: $${payload[0].payload.average1}`}</p>
                            </div>
                            <div>
                                <p className={!segment ? `average` : 'central-tooltip'} style={{ color: '#005A9C' }}>{`Recurring Average Donation: $${payload[0].payload.average2}`}</p>
                            </div>
                        </>
                    }
                    {eventType === "total_donation" &&
                        <div>
                            <p className={!segment ? `average` : 'central-tooltip'} style={{ color: '#4c9ef5' }}>{`Total Donation Amount: $${payload[0].payload.totalDonationAmt}`}</p>
                        </div>
                    }
                </div>
            );
        }
        return null;
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

    return (
        <div className='line-Chart p-0'>
            <ResponsiveContainer width={"100%"} height={segment ? 300 : 450}>
                <LineChart data={showChart} 
                margin={{ top: 5, right: 10, left: -15, bottom: 20 }}
                >
                    <XAxis
                        allowDataOverflow
                        dataKey="date"
                        tick={<CustomizedAxisTick />}
                        domain={['dataMin', 'dataMax']}
                        interval={!segment ? showChart ? showChart.length < 31 ? 0 : Math.round(showChart.length / 30) : 0 : ''}
                        height={70}
                        padding={{
                            right: 10,
                            left: 1
                        }}
                        label={{
                            value: `Time (days)`,
                            style: { textAnchor: 'middle' },
                            position: 'insideBottom',
                        }}
                    />

                    <YAxis
                        tickFormatter={(value) => `$${parseInt(value)}`}
                        allowDataOverflow={true}
                        interval="preserveStartEnd"
                        width={100}
                        padding={{
                            top: 10,
                            bottom: 4,
                            left:-10
                        }}
                        label={{
                            value: `Total Donation Amount`,
                            style: { textAnchor: 'middle' },
                            angle: -90,
                            position: 'left',
                            offset: -30,
                        }}
                    />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                    {isValid ? <Tooltip content={<CustomTooltip />} /> : ''}
                    {
                        eventType === "average_donation_amount" ?
                            <>
                                <Line strokeWidth={2.5} type="monotone" dataKey='average1' strokeDasharray="10 5" stroke={isValid ? "#4c9ef5" : "white"} dot={{ stroke: isValid ? "#4c9ef5" : "white", strokeWidth: 2, r: 4, fill: isValid ? "#4c9ef5" : "white" }} />
                                <Line strokeWidth={2.5} type="monotone" dataKey='average2' strokeDasharray="10 5" stroke={isValid ? "#005A9C" : "white"} dot={{ stroke: isValid ? "#005A9C" : "white", strokeWidth: 2, r: 4, fill: isValid ? "#005A9C" : "white" }} />
                            </>
                            : eventType === "total_donation" &&
                            <Line strokeWidth={2.5} type="monotone" dataKey='totalDonationAmt' strokeDasharray="10 5" stroke={isValid ? "#4c9ef5" : "white"} dot={{ stroke: isValid ? "#4c9ef5" : "white", strokeWidth: 2, r: 4, fill: isValid ? "#4c9ef5" : "white" }} />
                    }
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default LineCharts;