import React, { useState,useEffect } from 'react';
import {Progress, Tabs, Radio, Input, Button, Form, Checkbox,Upload, Select, ColorPicker, DatePicker,notification  } from 'antd';
import { DownOutlined,InboxOutlined,DeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { UploadManager } from '@bytescale/sdk';

const { TextArea } = Input;
const { Dragger } = Upload;
const Activations = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('#0000FFFF');
  const [primaryTextColor, setPrimaryTextColor] = useState('#000000FF');
  const [formatHex, setFormatHex] = useState('rgb');
  const [primaryOpen, setPrimaryOpen] = useState(false);
  const [primaryTextOpen, setPrimaryTextOpen] = useState(false);
  const [inclusions, setInclusions] = useState([{ when: 'When', url: '', condition: '' }]);
  const [exclusions, setExclusions] = useState([{ when: 'When', url: '', condition: '' }]);

  const [showInclusions, setShowInclusions] = useState(false);
  const [showExclusions, setShowExclusions] = useState(false);
  // const [inclusions, setInclusions] = useState([]);
  // const [exclusions, setExclusions] = useState([]);

  const [showSelectedVisitors, setShowSelectedVisitors] = useState(false);
  const [showAllVisitors, setShowAllVisitors] = useState(false);

  const [audienceInclusions, setAudienceInclusions] = useState([]);
  const [audienceExclusions, setAudienceExclusions] = useState([]);

  const [showTriggerInclusions, setShowTriggerInclusions] = useState(false);
  const [showTriggerExclusions, setShowTriggerExclusions] = useState(false);
  const [triggerInclusions, setTriggerInclusions] = useState([{ when: '', condition: '', url: '' }]);
  const [triggerExclusions, setTriggerExclusions] = useState([{ when: '', condition: '', url: '' }]);
  const [fileUrl, setFileUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const { id } = useParams();
  const options = [
    { value: 'email', type: 'text' },
    { value: 'avgGiftAmt', type: 'number',operator:["greater","less","between"] },
    { value: 'giftCount', type: 'number',operator:["greater","less","between"]  },
    {
      value: 'isSustainer', type: {
        "Select": ["Yes", "No"]
      }
    },
    {
      value: 'isTribDonor', type: {
        "Select": ["Yes", "No"]
      }
    },
    { value: 'lastGiftAmt', type: 'name',operator:["greater","less","between"]  },
    { value: 'lastGiftDate', type: 'Date Range',operator:["greater","less","between"]  },
    { value: 'lifetimeGiftAmt', type: 'number',operator:["greater","less","between"]  },
    { value: 'maxGiftAmt', type: 'number',operator:["greater","less","between"]  },
    {
      value: 'twzi', type: {
        "Select": ["Yes", "No"]
      }
    },
  ];
  // const uploadManager = new UploadManager({
  //   apiKey: "public_W142iiuFKD34NgpAhwdB8gTRZPMo", // This is your API key.
  //   maxFileCount: 1,
  //   showFinishButton: true, // Note: You must use 'onUpdate' if you set 'showFinishButton: false' (default).
  //   styles: {
  //     colors: {
  //       primary: "#377dff"
  //     }
  //   }
  // });
  const uploadManager = new UploadManager({
   // apiKey: "public_W142ie6Ei41QjWipXnMqnrUbjire", // Replace with your API key
   apiKey:"public_W142iiuFKD34NgpAhwdB8gTRZPMo", 
   credentials: {
      accessKeyId: "AKIAWGAOCMGASD5AQDGT", // Replace with your access key
      secretAccessKey: "fPJpaDA4hLRX/fvmOlgNOwtEMXHcL9tJjddnXlLo" // Replace with your secret key
    }
  });
  const onFileSelected = async (event) => {
    const file = event.file.originFileObj;
    if (!file || uploading) {
      return; // Prevent duplicate uploads
    }

    setUploading(true);
    setUploadProgress(0);
    try {
      const { fileUrl, filePath } = await uploadManager.upload({
        data: file,
        mime: file.type,
        originalFileName: file.name,
        path: {
          fileName: `custom-file-${Date.now()}{ORIGINAL_FILE_EXT}`,
          folderPath: "/identityBanners"
        },
        metadata: {
          uploadedBy: "user123",
          customField: "customValue"
        },
        tags: ["custom_tag"],
        onProgress: ({ progress }) => {
          setUploadProgress(progress);
          console.log(`Progress: ${progress}%`);
        }
      });

      // File successfully uploaded!
      setFileUrl(fileUrl);
      setUploadProgress(100);
      notification.success({
        message: 'Upload Successful',
        description: 'Your file has been uploaded successfully.',
      });
      console.log(`${filePath}  File uploaded: \n${fileUrl}`);
    } catch (e) {
      setUploadProgress(0);
      notification.error({
        message: 'Upload Failed',
        description: `Error: ${e.message}`,
      });
      console.log(`Error:\n${e.message}`);
    }
  };

  const handleDelete = () => {
    setFileUrl(null);
    setUploadProgress(0);
    notification.info({
      message: 'File Deleted',
      description: 'The uploaded file has been deleted.',
    });
  };


  const uploadProps = {
    name: 'file',
    multiple: false,
    customRequest: onFileSelected,
    showUploadList: false,
  };
  const [triggerOnScroll, setTriggerOnScroll] = useState(false);
  const [triggerOnExit, setTriggerOnExit] = useState(false);
  const [triggerOnTimeSpent, setTriggerOnTimeSpent] = useState(false);
  const [triggerOnInactivity, setTriggerOnInactivity] = useState(false);

  const handleCheckboxChange = (triggerType) => {
    setTriggerOnScroll(triggerType === 'scroll');
    setTriggerOnExit(triggerType === 'exit');
    setTriggerOnTimeSpent(triggerType === 'timeSpent');
    setTriggerOnInactivity(triggerType === 'inactivity');
  };
  const rgbaToHex = (rgba) => {
    const [r, g, b, a] = rgba.match(/\d+/g).map(Number);
  const alpha = (a !== undefined) ? Math.round(a * 255).toString(16).padStart(2, '0') : 'FF';
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}${alpha.toUpperCase()}`;

};

  const [form] = Form.useForm();

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedTemplate(null);
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handlePrimaryOpenChange = (open) => {
    setPrimaryOpen(open);
  };

  const handlePrimaryTextOpenChange = (open) => {
    setPrimaryTextOpen(open);
  };

  const [selectedSection, setSelectedSection] = useState(null); // 'inclusions' or 'exclusions'

  const handleAddInclusion = () => {
    setInclusions([...inclusions, { when: '', condition: 'Is all Pages', url: '' }]);
  };

  const handleAddExclusion = () => {
    setExclusions([...exclusions, { when: '', condition: 'Is all Pages', url: '' }]);
  };

  const handleRemoveInclusion = (index) => {
    setInclusions(inclusions.filter((_, i) => i !== index));
  };

  const handleRemoveExclusion = (index) => {
    setExclusions(exclusions.filter((_, i) => i !== index));
  };

  const handleInclusionChange = (index, field, value) => {
    const newInclusions = [...inclusions];
    newInclusions[index][field] = value;
    setInclusions(newInclusions);
  };

  const handleExclusionChange = (index, field, value) => {
    const newExclusions = [...exclusions];
    newExclusions[index][field] = value;
    setExclusions(newExclusions);
  };

  const handleToggleSection = (section) => {
    setSelectedSection(section);
    if (section === 'inclusions') {
      setExclusions([{ when: 'When', url: '', condition: '' }]);
    } else {
      setInclusions([{ when: 'When', url: '', condition: '' }]);
    }
  };


  const handleAddAudienceInclusion = () => {
    setAudienceInclusions([...audienceInclusions, { segment: '', list: '' }]);
  };

  const handleRemoveAudienceInclusion = (index) => {
    const newAudienceInclusions = [...audienceInclusions];
    newAudienceInclusions.splice(index, 1);
    setAudienceInclusions(newAudienceInclusions);
  };



  const handleAddAudienceExclusion = () => {
    setAudienceExclusions([...audienceExclusions, { segment: '', list: '' }]);
  };

  const handleRemoveAudienceExclusion = (index) => {
    const newAudienceExclusions = [...audienceExclusions];
    newAudienceExclusions.splice(index, 1);
    setAudienceExclusions(newAudienceExclusions);
  };

  // Handle inclusion/exclusion changes for trigger activation
  const handleTriggerInclusionChange = (index, field, value) => {
    const newInclusions = [...triggerInclusions];
    newInclusions[index][field] = value;
    setTriggerInclusions(newInclusions);
  };

  const handleTriggerExclusionChange = (index, field, value) => {
    const newExclusions = [...triggerExclusions];
    newExclusions[index][field] = value;
    setTriggerExclusions(newExclusions);
  };

  // Handle inclusion/exclusion changes for audience activation
  const handleAudienceInclusionChange = (index, field, value) => {
    const newInclusions = [...audienceInclusions];
    newInclusions[index][field] = value;
    setAudienceInclusions(newInclusions);
  };

  const handleAudienceExclusionChange = (index, field, value) => {
    const newExclusions = [...audienceExclusions];
    newExclusions[index][field] = value;
    setAudienceExclusions(newExclusions);
  };


  const handleShowAllVisitorsChange = (e) => {
    setShowAllVisitors(e.target.checked);
    if (e.target.checked) {
      setShowSelectedVisitors(false);
    }
  };

  const handleShowSelectedVisitorsChange = (e) => {
    setShowSelectedVisitors(e.target.checked);
    if (e.target.checked) {
      setShowAllVisitors(false);
    }
  };


  const onFinish = async (values) => {
    values.buttonColor = primaryColor;
    values.buttonTextColor = primaryTextColor;
    values.bannerImageSrc = fileUrl;
    values.bannerImage = fileUrl;
    const combinedValues = {
      ...values,
      buttonColor: primaryColor,
      buttonTextColor: primaryTextColor,
      inclusions,
      exclusions,
      triggerInclusions,
      triggerExclusions,
      audienceInclusions,
      audienceExclusions,
    };

    const data = {
      id: values.id,  // Add the ID for update
      name: values.activationName,
      type: selectedType,
      templateType: 
        selectedType === 'lightbox' 
          ? (selectedTemplate === 'lightbox_full' ? 'lightbox_full' : 'lightbox_half')
          : selectedType === 'banner' 
            ? 'banner_full' 
            : selectedType === 'bug' 
              ? 'bug_full' 
              : '',
      donationUrl: values.buttonUrl,
      buttonColor: primaryColor,
      buttonTextColor: primaryTextColor,
      bannerImageSrc: values.bannerImage?.file?.response?.url || fileUrl,
      buttonText: values.buttonText,
    };

    const templateData = {
      name: values.activationName,
      type: selectedType,
      templateType: 
        selectedType === 'lightbox' 
          ? (selectedTemplate === 'lightbox_full' ? 'lightbox_full' : 'lightbox_half')
          : selectedType === 'banner' 
            ? 'banner_full' 
            : selectedType === 'bug' 
              ? 'bug_full' 
              : '',
      donationUrl: values.buttonUrl,
      buttonColor: primaryColor,
      buttonTextColor: primaryTextColor,
      bannerImageSrc: values.bannerImage?.file?.response?.url || fileUrl,
      headlineText: values.headline,
      bodyMarkup: values.bodyCopy,
      buttonText: values.buttonText,
    };

    const isUpdate = id;//new URLSearchParams(location.search).get('id');

    try {
      if(id){
        data.id = parseInt(id);
      }
  
      const response = await fetch(`https://z0eepnybq0.execute-api.us-east-1.amazonaws.com/dev/v1/activations`, {
        method: isUpdate ? 'PATCH' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        generateComponentCode(templateData);
        const result = await response.json();
        console.log(values ,' Success : ', result);
        form.resetFields();
        notification.success({
          message: `${isUpdate ? 'Update' : 'Creation'} Successful`,
          description: `Component was ${isUpdate ? 'updated' : 'created'} successfully.`,
        });
        setFileUrl(null);
        setUploadProgress(0);
      } else {
        console.error('Error:', response.statusText);
        notification.error({
          message: `${isUpdate ? 'Update' : 'Creation'} Failed`,
          description: `Component ${isUpdate ? 'update' : 'creation'} failed with status: ${response.statusText}`,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      notification.error({
        message: `${isUpdate ? 'Update' : 'Creation'} Failed`,
        description: `Component ${isUpdate ? 'update' : 'creation'} failed with error: ${error.message}`,
      });
    }
    console.log(combinedValues ,' Received values : ', values);
  };

  useEffect(() => {
    //const params = new URLSearchParams(location.search);
   // const id = id;//params.get('id');
    if (id) {
      // Fetch the data for the given ID and populate the form
      // Assuming you have an API endpoint to fetch the details by ID
      fetch(`https://z0eepnybq0.execute-api.us-east-1.amazonaws.com/dev/v1/fetch?r=activations&i=${id}`)
        .then(response => response.json())
        .then(data => {
         
          form.setFieldsValue({
            ...data,
            activationName: data[0].activations.name,
            buttonUrl: data[0].activations.donationUrl,
            buttonText: data[0].activations.buttonText,
            headline: data[0].activations.headlineText,
            bodyCopy: data[0].activations.bodyMarkup,
            buttonColor: data[0].activations.buttonColor,
            buttonTextColor: data[0].activations.buttonTextColor,
            bannerImage: data[0].activations.bannerImageSrc,
          });
          console.log(data.name," --  ", data[0].activations.name," Response data ",data);
          setSelectedType(data[0].activations.type);
          setSelectedTemplate(data[0].activations.templateType);
          setFileUrl(data[0].activations.bannerImageSrc);
          setPrimaryColor(data[0].activations.buttonColor);
          setPrimaryTextColor(data[0].activations.buttonTextColor);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [id, form]);

  const generateComponentCode = (data) => {
    const {
      type,
      templateType,
      bannerImageSrc,
      headlineText,
      bodyMarkup,
      buttonText,
      donationUrl,
      buttonColor,
      buttonTextColor
    } = data;
  
    console.log("Datta ", data);
  
    let componentCode = `
      const styles = \`
        body {
          font-family: Arial, sans-serif;
        }
        .lightbox-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          display: none;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .lightbox {
          background: white;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          max-width: 600px;
          width: 100%;
          overflow: hidden;
          position: relative;
          height: 400px;
        }
        .lightbox img {
          object-fit: cover;
          width: 100%;
          border-radius: 8px;
        }
        .lightbox h1, .banner h1 {
          margin: 10px 0;
          font-size: 1.5em;
        }
        .lightbox p, .banner p {
          margin: 10px 0;
        }
        .lightbox button, .banner button, .bug button {
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        .close-btn {
          position: absolute;
          top: 10px;
          right: 10px;
          background: transparent;
          border: none;
          font-size: 1.5em;
          cursor: pointer;
        }
        .lightbox .overlay-text {
          position: absolute;
          bottom: 45px;
          left: 20px;
          color: white;
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
        }
        .lightbox .overlay-button {
          position: absolute;
          bottom: 11px;
          left: 22px;
        }
        .banner {
          border-radius: 6px;
          position: fixed;
          top: 3%;
          left: 3%;
          width: 92%;
          background: white;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;
          z-index: 1000;
        }
        .banner .banner-content {
          display: flex;
          align-items: center;
        }
        .banner img {
          max-height: 50px;
          margin-right: 10px;
        }
        .bug {
          position: fixed;
          bottom: 10px;
          right: 10px;
          background: white;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          z-index: 1000;
        }
      \`;
  
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = styles;
      document.head.appendChild(styleSheet);
  
      function triggerComponent(type, template, imageUrl, headline, bodyMarkup, buttonText, buttonUrl, buttonColor, buttonTextColor) {
        const existingOverlay = document.getElementById('componentOverlay');
        if (existingOverlay) existingOverlay.remove();
  
        const overlay = document.createElement('div');
        overlay.id = 'componentOverlay';
  
        const closeButton = document.createElement('button');
        closeButton.className = 'close-btn';
        closeButton.innerHTML = '&times;';
        closeButton.onclick = () => {
          overlay.style.display = 'none';
        };
  
        if (type === 'lightbox') {
          overlay.className = 'lightbox-overlay';
          const content = document.createElement('div');
          content.className = 'lightbox';
          content.id = 'lightboxContent';
  
          const img = document.createElement('img');
          img.src = imageUrl;
          img.style.height = template === 'lightbox_full' ? '400px' : '200px';
  
          const h1 = document.createElement('h1');
          h1.textContent = headline;
          h1.style.color = 'white';
  
          const p = document.createElement('p');
          p.textContent = bodyMarkup;
          p.style.color = 'white';
  
          const button = document.createElement('button');
          button.textContent = buttonText;
          button.style.backgroundColor = buttonColor;
          button.style.color = buttonTextColor;
          button.onclick = () => window.location.href = buttonUrl;
  
          content.appendChild(closeButton);
          if (template === 'lightbox_full') {
            content.style.padding = '0';
            img.style.width = '100%';
            img.style.height = '400px';
            img.style.borderRadius = '8px 8px 0 0';
  
            const overlayText = document.createElement('div');
            overlayText.className = 'overlay-text';
            overlayText.appendChild(h1);
            overlayText.appendChild(p);
  
            const overlayButton = document.createElement('div');
            overlayButton.className = 'overlay-button';
            overlayButton.appendChild(button);
  
            const imgWrapper = document.createElement('div');
            imgWrapper.style.position = 'relative';
            imgWrapper.appendChild(img);
            imgWrapper.appendChild(overlayText);
            imgWrapper.appendChild(overlayButton);
  
            content.appendChild(imgWrapper);
          } else {
            content.appendChild(img);
            content.appendChild(h1);
            content.appendChild(p);
            content.appendChild(button);
            h1.style.marginLeft = '3%';
            p.style.marginLeft = '3%';
            button.style.marginLeft = '3%';
          }
  
          overlay.appendChild(content);
        } else if (type === 'banner') {
          overlay.className = 'banner';
          const bannerContent = document.createElement('div');
          bannerContent.className = 'banner-content';
  
          const img = document.createElement('img');
          img.src = imageUrl;
  
          const h1 = document.createElement('h1');
          h1.textContent = headline;
  
          const button = document.createElement('button');
          button.textContent = buttonText;
          button.style.backgroundColor = buttonColor;
          button.style.color = buttonTextColor;
          button.onclick = () => window.location.href = buttonUrl;
  
          bannerContent.appendChild(img);
          bannerContent.appendChild(h1);
  
          overlay.appendChild(bannerContent);
          overlay.appendChild(button);
          overlay.appendChild(closeButton);
        } else if (type === 'bug') {
          overlay.className = 'bug';
  
          const button = document.createElement('button');
          button.textContent = buttonText;
          button.style.backgroundColor = buttonColor;
          button.style.color = buttonTextColor;
          button.onclick = () => window.location.href = buttonUrl;
  
          overlay.appendChild(button);
          overlay.appendChild(closeButton);
        }
  
        document.body.appendChild(overlay);
  
        if (type === 'lightbox') {
          overlay.style.display = 'flex';
          document.getElementById('lightboxOverlay').addEventListener('click', function(event) {
            if (event.target === this) {
              this.style.display = 'none';
            }
          });
        }
      }
  
      // Example usage
      triggerComponent(
        '${type}',
        '${templateType}',
        '${bannerImageSrc}',
        '${headlineText}',
        \`${bodyMarkup}\`,
        '${buttonText}',
        '${donationUrl}',
        '${buttonColor}',
        '${buttonTextColor}'
      );
    `;
  
    // You can use a library like FileSaver.js to save the file
    const blob = new Blob([componentCode], { type: 'text/javascript' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${type}Component.js`;
    console.log("Link - JS ", link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  
  // const handleSubmit = async (values) => {

  // };
  const renderInput = (option, index, inclusion, handleChange) => {
    const selectedOption = options.find(opt => opt.value === inclusion.selectedOption);
  
    if (!selectedOption) return null;
  
    const selectedOptionType = selectedOption.type;
    const hasOperators = selectedOption.operator;
  
    return (
      <div className="flex items-center space-x-2 w-1/2">
             {hasOperators && (
          <Select
            placeholder="Select operator"
            value={inclusion.operator || ''}
            onChange={(value) => handleChange(index, 'operator', value)}
            className="w-1/3"
          >
            {selectedOption.operator.map((operator, i) => (
              <Select.Option key={i} value={operator}>
                {operator}
              </Select.Option>
            ))}
          </Select>
        )}
        {selectedOptionType === 'text' || selectedOptionType === 'number' ? (
          <Input
            type={selectedOptionType}
            placeholder={"Enter Details"}
            value={inclusion.value || ''}
            onChange={(e) => handleChange(index, 'value', e.target.value)}
            className="w-1/3"
            style={{width:"100%"}}
       
          />
        ) : typeof selectedOptionType === 'object' && selectedOptionType.Select ? (
          <Select
            placeholder="Select option"
            value={inclusion.value || ''}
            onChange={(value) => handleChange(index, 'value', value)}
            className="w-1/3"
           
          >
            {selectedOptionType.Select.map((selectOption, i) => (
              <Select.Option key={i} value={selectOption}>
                {selectOption}
              </Select.Option>
            ))}
          </Select>
        ) : selectedOptionType === 'Date Range' ? (
          <DatePicker.RangePicker
            placeholder={['Start Date', 'End Date']}
            value={inclusion.value || null}
            onChange={(dates) => handleChange(index, 'value', dates)}
            className="w-1/3"
            style={{width:"100%"}}
         
          />
        ) : null}
  
   
      </div>
    );
  };
  


  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="bg-white p-6 rounded shadow">
        <h1 className="text-2xl mb-4">New Activation</h1>
        {/* <p>Activation ID: {id}</p> */}
        <Form
          layout="vertical"
          className="bg-white p-6 rounded shadow"
          form={form}
          onFinish={onFinish}
        >
          <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Design Activation" key="1">
  <div className="mb-6">
    <label className="block mb-2">Choose a Type <span className="text-red-500">*</span></label>
    <Radio.Group onChange={handleTypeChange} value={selectedType} className="flex space-x-4">
      <Radio.Button value="lightbox" className="custom-radio w-1/3 h-64 p-4 rounded shadow-sm flex flex-col items-center justify-center">
        <div className="w-40 h-40 bg-blue-200 flex items-end mb-2 rounded justify-center">
          <div className="w-16 h-16 bg-blue-200 mb-2 flex items-end justify-center rounded">
            <div className="w-16 h-6 bg-blue-400"></div>
          </div>
        </div>
      </Radio.Button>
      <Radio.Button value="banner" className="custom-radio w-1/3 h-64 p-4 rounded shadow-sm flex flex-col items-center justify-start">
        <div className="w-96 h-20 bg-blue-200 flex items-center mb-2 rounded justify-end">
          <div className="w-16 h-16 bg-blue-200 mb-2 flex items-center justify-center rounded mr-2">
            <div className="w-16 h-6 bg-blue-400"></div>
          </div>
        </div>
      </Radio.Button>
      <Radio.Button value="bug" className="custom-radio w-1/3 h-64 p-4 rounded shadow-sm flex flex-col items-end justify-end">
        <div className="w-24 h-24 bg-blue-200 flex items-end justify-center rounded">
          <div className="w-12 h-8 bg-blue-200 mb-2 flex items-center justify-center">
            <div className="w-12 h-6 bg-blue-400"></div>
          </div>
        </div>
      </Radio.Button>
    </Radio.Group>
    <div className="flex flex-wrap items-center space-y-4">
      <div className="text-center w-1/3">Light Box</div>
      <div className="text-center w-1/3">Banner</div>
      <div className="text-center w-1/3">Bug</div>
    </div>
  </div>

  {selectedType === 'lightbox' && (
    <div className="mb-6">
      <label className="block mb-2">Choose a Template <span className="text-red-500">*</span></label>
      <Radio.Group onChange={handleTemplateChange} value={selectedTemplate} className="flex space-x-4">
        <Radio.Button value="lightbox_half" className="custom-radio w-1/3 h-80 rounded shadow-sm flex flex-row p-0">
          <div className="w-full h-1/2 rounded">
            <img src="https://static.vecteezy.com/system/resources/thumbnails/028/705/608/small_2x/cute-puppy-with-paws-over-white-sign-catahoula-lab-mix-dog-ai-generated-photo.jpg" alt="Half Image" className="rounded w-full h-full object-cover" />
          </div>
          <div className="w-full h-1/2 flex flex-col items-center justify-center text-center p-4">
            <h1 className="text-lg font-semibold mb-1">You can give animals a second chance.</h1>
            <p className="text-sm">With your gift, you are providing critical support for animals throughout North Texas who are desperate for a second chance.</p>
            <button className="p-2 bg-blue-400 text-white text-sm rounded">Donation</button>
          </div>
        </Radio.Button>
        <Radio.Button value="lightbox_full" className="custom-radio w-1/3 h-80 rounded shadow-sm flex flex-row p-0">
          <div className="w-full h-full rounded">
            <img src="https://static.vecteezy.com/system/resources/thumbnails/028/705/608/small_2x/cute-puppy-with-paws-over-white-sign-catahoula-lab-mix-dog-ai-generated-photo.jpg" alt="Full Image" className="rounded w-full h-full object-cover" />
          </div>
          <div className="w-full -mt-36 flex flex-col items-center justify-start text-left p-4 text-white">
            <h1 className="text-lg font-semibold mb-1 text-white">You can give animals a second chance.</h1>
            <button className="p-2 w-1/3 bg-blue-400 text-white text-sm rounded">Donation</button>
          </div>
        </Radio.Button>
      </Radio.Group>
    </div>
  )}

  {(selectedType === 'banner' || selectedType === 'bug' || (selectedType === 'lightbox' && selectedTemplate)) && (
    <>
      <Form.Item
        name="activationName"
        label="Name of your activation"
        rules={[{ required: true, message: 'This field is required' }, { max: 10, message: 'Text cannot exceed 10 characters' }]}
        className='w-3/4'
      >
        <Input maxLength={10} className='text-black' />
      </Form.Item>
      <Form.Item
        name="headline"
        label="Write Headline"
        rules={[{ required: true, message: 'This field is required' }, { max: 10, message: 'Text cannot exceed 10 characters' }]}
        className='w-3/4 text-black'
      >
        <Input maxLength={10} className='text-black' />
      </Form.Item>
      {(selectedType === 'lightbox' || selectedType === 'banner') && (
        <Form.Item
          name="bodyCopy"
          label="Write Body Copy"
          rules={[{ required: true, message: 'This field is required' }, { max: 100, message: 'Text cannot exceed 100 characters' }]}
          className='w-3/4'
        >
          <TextArea rows={4} maxLength={100} className='text-black' />
        </Form.Item>
      )}
      <div className="flex items-center space-x-4 w-3/4">
        <Form.Item
          name="buttonText"
          label="Button Text"
          rules={[{ required: true, message: 'This field is required' }, { max: 10, message: 'Text cannot exceed 10 characters' }]}
          className="w-3/5"
        >
          <Input maxLength={10} className='text-black' />
        </Form.Item>
        <Form.Item
          name="buttonUrl"
          initialValue="https://example.com"
          label="Button URL"
          rules={[{ required: true, message: 'This field is required' }]}
          className="w-3/5"
        >
          <Input defaultValue="https://example.com" className='text-black' />
        </Form.Item>
      </div>
      <div className="flex items-center space-x-4 w-1/2">
        <Form.Item
          name="buttonColor"
          label="Button Color"
          rules={[{ required: true, message: 'This field is required' }]}
          className="w-2/5"
        >
          <ColorPicker
            className='w-full primaryColorPicker justify-start'
            defaultValue={primaryColor}
            open={primaryOpen}
            onOpenChange={handlePrimaryOpenChange}
            onChange={(newColor) => setPrimaryColor(rgbaToHex(newColor.toRgbString()))}
            format="rgb"
            disabledAlpha
            showText={(color) => (
              <div className="flex items-center space-x-2">
                <DownOutlined
                  rotate={primaryOpen ? 180 : 0}
                  style={{ color: 'rgba(0, 0, 0, 0.25)' }}
                />
                <span className="text-custom">({rgbaToHex(color.toRgbString())})</span>
                <div className="border-b-2 border-customColor w-full"></div>
              </div>
            )}
          />
        </Form.Item>
        <Form.Item
          name="buttonTextColor"
          label="Button Text Color"
          rules={[{ required: true, message: 'This field is required' }]}
          className="w-2/5"
        >
          <ColorPicker
            className="w-full justify-start"
            defaultValue={primaryTextColor}
            open={primaryTextOpen}
            onOpenChange={handlePrimaryTextOpenChange}
            onChange={(newColor) => setPrimaryTextColor(rgbaToHex(newColor.toRgbString()))}
            format="rgb"
            showText={(color) => (
              <div className="flex items-center space-x-2">
                <DownOutlined
                  rotate={primaryTextOpen ? 180 : 0}
                  style={{ color: 'rgba(0, 0, 0, 0.25)' }}
                />
                <span className="text-custom">({rgbaToHex(color.toRgbString())})</span>
                <div className="border-b-2 border-customColor w-full"></div>
              </div>
            )}
          />
        </Form.Item>
      </div>
      {selectedType === 'lightbox' && (
        <Form.Item name="bannerImage" label="Add Primary Image">
          <Dragger
            name="file"
            multiple={false}
            onChange={onFileSelected}
            className="w-full"
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: '#1890ff', fontSize: '32px' }} />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Important: Maximum image weight is 2mb. Maximum height 1000px.</p>
          </Dragger>

          {fileUrl && (
            <div className="uploaded-file-preview">
              <img src={fileUrl} alt="Uploaded file" style={{ maxWidth: '100%', height: 'auto' }} />
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                Click to Open
              </a>
              <DeleteOutlined onClick={handleDelete} style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }} />
            </div>
          )}

          {uploadProgress > 0 && uploadProgress < 100 && (
            <Progress percent={uploadProgress} />
          )}
        </Form.Item>
      )}
    </>
  )}
</Tabs.TabPane>

            <Tabs.TabPane tab="Config Activation" key="2" className='p-2'>
            <Form.Item label="1. Setup Activation Trigger Timing" className='text-xl font-extrabold'>
        <div className="space-y-4 flex flex-col">
          <Checkbox
            className='p-4 w-3/4 bg-gray-50 rounded'
            checked={triggerOnScroll}
            onChange={() => handleCheckboxChange('scroll')}
          >
            Trigger upon scrolling a page
          </Checkbox>
          {triggerOnScroll && (
            <div className="ml-8">
              <p className='text-base p-2'>The Activation Object will be shown after a user scrolls the below defined % of the page.</p>
              <Input
                type="number"
                placeholder="Enter percentage"
                className="w-1/3"
              />
            </div>
          )}
          <Checkbox
            className='p-4 w-3/4 bg-gray-50 rounded'
            checked={triggerOnExit}
            onChange={() => handleCheckboxChange('exit')}
          >
            Trigger when exiting webpage
          </Checkbox>
          {triggerOnExit && (
            <div className="ml-8">
              <p className='text-base'>The Activation Object will be shown when the user tries to exit the webpage.</p>
            </div>
          )}
          <Checkbox
            className='p-4 w-3/4 bg-gray-50 rounded'
            checked={triggerOnTimeSpent}
            onChange={() => handleCheckboxChange('timeSpent')}
          >
            Trigger after specific time spent
          </Checkbox>
          {triggerOnTimeSpent && (
            <div className="flex items-center space-x-2 ml-8 w-1/2">
              <Input
                type="number"
                placeholder="Enter number"
                className="w-1/2"
              />
              <Select defaultValue="seconds" className='w-1/2'>
                <Select.Option value="seconds">Seconds</Select.Option>
                <Select.Option value="minutes">Minutes</Select.Option>
                <Select.Option value="hours">Hours</Select.Option>
              </Select>
            </div>
          )}
          <Checkbox
            className='p-4 w-3/4 bg-gray-50 rounded'
            checked={triggerOnInactivity}
            onChange={() => handleCheckboxChange('inactivity')}
          >
            Trigger after idle time
          </Checkbox>
          {triggerOnInactivity && (
            <div className="flex items-center space-x-2 ml-8 w-1/2">
              <Input
                type="number"
                placeholder="Enter number"
                className="w-1/2"
              />
              <Select defaultValue="seconds" className='w-1/2'>
                <Select.Option value="seconds">Seconds</Select.Option>
                <Select.Option value="minutes">Minutes</Select.Option>
                <Select.Option value="hours">Hours</Select.Option>
              </Select>
            </div>
          )}
        </div>
      </Form.Item>

              <Form.Item label="2. Places to Trigger Activation" className='text-xl font-extrabold'>
        <div className="mt-4">
          <Checkbox 
            checked={selectedSection === 'inclusions'} 
            onChange={(e) => handleToggleSection('inclusions')}
          >
            Show Your Inclusions
          </Checkbox>
          {selectedSection === 'inclusions' && (
            <>
              <h2 className="text-xl mb-4">Inclusions</h2>
              {inclusions.map((inclusion, index) => (
                <div key={index} className="mb-4 p-4 border rounded-xl flex w-full space-x-2">
                  <Form.Item label="When:" style={{ width: "15rem" }}>
                    {index === 0 ? (
                      <Select
                        value="When"
                        className="w-full mr-2"
                        disabled
                      >
                        <Select.Option value="When">When</Select.Option>
                      </Select>
                    ) : (
                      <Select
                        className="w-full mr-2"
                        value={inclusion.when}
                        name={`inclusions[${index}].when`}
                        onChange={(value) => handleInclusionChange(index, 'when', value)}
                      >
                        <Select.Option value="AND">AND</Select.Option>
                        <Select.Option value="OR">OR</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Condition:" style={{ width: "20rem" }}>
                    <Select
                      className="flex-1 mr-2 w-full"
                      value={inclusion.condition}
                      name={`inclusions[${index}].condition`}
                      onChange={(value) => handleInclusionChange(index, 'condition', value)}
                    >
                      <Select.Option value="Is all Pages">Is all Pages</Select.Option>
                      <Select.Option value="Contains">Contains</Select.Option>
                      <Select.Option value="Begins With">Begins With</Select.Option>
                    </Select>
                  </Form.Item>
                  {inclusion.condition !== 'Is all Pages' && (
                    <Form.Item label="URL:" style={{ width: "34rem" }}>
                      <Input
                        className="flex-1 mr-2"
                        value={inclusion.url}
                        name={`inclusions[${index}].url`}
                        onChange={(e) => handleInclusionChange(index, 'url', e.target.value)}
                      />
                    </Form.Item>
                  )}
                  <Button type="danger" danger onClick={() => handleRemoveInclusion(index)}>Remove</Button>
                </div>
              ))}
              <Button type="primary" onClick={handleAddInclusion}>Add Inclusion</Button>
            </>
          )}
        </div>

        <div className="mt-8">
          <Checkbox 
            checked={selectedSection === 'exclusions'} 
            onChange={(e) => handleToggleSection('exclusions')}
          >
            Show Exclusions
          </Checkbox>
          {selectedSection === 'exclusions' && (
            <>
              <h2 className="text-xl mb-4">Exclusions</h2>
              {exclusions.map((exclusion, index) => (
                <div key={index} className="mb-4 p-4 border rounded-xl flex w-full space-x-2">
                  <Form.Item label="When:" style={{ width: "15rem" }}>
                    {index === 0 ? (
                      <Select
                        value="When"
                        className="w-full mr-2"
                        disabled
                      >
                        <Select.Option value="When">When</Select.Option>
                      </Select>
                    ) : (
                      <Select
                        className="w-full mr-2"
                        value={exclusion.when}
                        name={`exclusions[${index}].when`}
                        onChange={(value) => handleExclusionChange(index, 'when', value)}
                      >
                        <Select.Option value="AND">AND</Select.Option>
                        <Select.Option value="OR">OR</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Condition:" style={{ width: "20rem" }}>
                    <Select
                      className="w-full mr-2"
                      value={exclusion.condition}
                      name={`exclusions[${index}].condition`}
                      onChange={(value) => handleExclusionChange(index, 'condition', value)}
                    >
                      <Select.Option value="Is all Pages">Is all Pages</Select.Option>
                      <Select.Option value="Contains">Contains</Select.Option>
                      <Select.Option value="Begins With">Begins With</Select.Option>
                    </Select>
                  </Form.Item>
                  {exclusion.condition === 'Contains' && (
                    <Form.Item label="URL:" style={{ width: "34rem" }}>
                      <Input
                        className="w-full mr-2"
                        value={exclusion.url}
                        name={`exclusions[${index}].url`}
                        onChange={(e) => handleExclusionChange(index, 'url', e.target.value)}
                      />
                    </Form.Item>
                  )}
                  <Button type="danger" danger onClick={() => handleRemoveExclusion(index)}>Remove</Button>
                </div>
              ))}
              <Button type="primary" onClick={handleAddExclusion}>Add Exclusion</Button>
            </>
          )}
        </div>
      </Form.Item>
              <Form.Item label="3. Audience for the Activation" className='text-xl font-extrabold'>
                <Checkbox checked={showAllVisitors} onChange={handleShowAllVisitorsChange}>Show it to all visitors</Checkbox>
                <div className="mb-6">
                  <Checkbox checked={showSelectedVisitors} onChange={handleShowSelectedVisitorsChange}>Show it to selected visitors</Checkbox>
                </div>
                {showSelectedVisitors && (
                  <>
                    <div className="mb-6 rounded-xl border p-4">
                      <label className="block mb-2">Inclusions</label>
                      {audienceInclusions.map((inclusion, index) => (
                        <div key={index} className="flex items-center space-x-2 mb-2">
                          <Select
                            placeholder="Select an option"
                            value={inclusion.selectedOption}
                            onChange={(value) => handleAudienceInclusionChange(index, 'selectedOption', value)}
                            className="w-1/3"
                            style={{width:"33%"}}
                          >
                            {options.map((option, optIndex) => (
                              <Select.Option key={optIndex} value={option.value}>
                                {option.value}
                              </Select.Option>
                            ))}
                          </Select>
                          {renderInput(options.find(opt => opt.value === inclusion.selectedOption), index, inclusion, handleAudienceInclusionChange)}
                          <Button onClick={() => handleRemoveAudienceInclusion(index)}>Remove</Button>
                        </div>
                      ))}
                      <Button type="primary" onClick={handleAddAudienceInclusion}>Add Inclusion</Button>
                    </div>
                    <div className="mb-6 rounded-xl border p-4">
                      <label className="block mb-2">Exclusions</label>
                      {audienceExclusions.map((exclusion, index) => (
                        <div key={index} className="flex items-center space-x-2 mb-2">
                          <Select
                            placeholder="Select an option"
                            value={exclusion.selectedOption}
                            onChange={(value) => handleAudienceExclusionChange(index, 'selectedOption', value)}
                            className="w-1/3"
                          >
                            {options.map((option, optIndex) => (
                              <Select.Option key={optIndex} value={option.value}>
                                {option.value}
                              </Select.Option>
                            ))}
                          </Select>
                          {renderInput(options.find(opt => opt.value === exclusion.selectedOption), index, exclusion, handleAudienceExclusionChange)}
                          <Button onClick={() => handleRemoveAudienceExclusion(index)}>Remove</Button>
                        </div>
                      ))}
                      <Button type="primary" onClick={handleAddAudienceExclusion}>Add Exclusion</Button>
                    </div>
                  </>
                )}
              </Form.Item>
              <Form.Item label="4. Activation Object Frequency" className='text-xl font-extrabold'>
                <div className="flex space-x-4">
                  <Select placeholder="Select Timing Filter" >
                    <Select.Option value="afterTimePeriod">After a Time Period</Select.Option>
                    <Select.Option value="repeat">Repeat</Select.Option>
                    <Select.Option value="dontShow">Dont SHow</Select.Option>
                  </Select>
                  <Input placeholder="Enter Numeric" />
                  <Select placeholder="Select Unit" >
                    <Select.Option value="seconds">Seconds</Select.Option>
                    <Select.Option value="minutes">Minutes</Select.Option>
                    <Select.Option value="hours">Hours</Select.Option>
                  </Select>
                </div>
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
          <div className="flex justify-end space-x-4 mt-6">
            <Button>Cancel</Button>
            <Button>Preview</Button>
            <Button>Save Progress</Button>
            <Button type="primary" htmlType="submit">Save and Config</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Activations;
