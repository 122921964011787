import cookie from "react-cookies";
import updateUserTypes from "./type";
import { API, environment, IdentityApi } from "../../utils/constants/index"

let initialState = {
    initial: 'Main',
    role: 'generic',
    authUrl: 'http://one.rkd.io/role',
    api: API,
    htmlContent: {},
    client: localStorage.getItem("choosenClient") || cookie.load('clientChosenCookie') || 'NONE',
    clientType: 'Fundraising',
    currentEmailHTML: '',
    campaignStored: 'NONE',
    clientData: '',
    creatives: '',
    paymentDefaults: {
        ccProcess: 'default',
        bankProcess: 'default',
        paypalProcess: 'default',
        applePayProcess: 'default'
    },
    imageData: '',
    creativeData: '',
    queryData: {},
    exportData: {},
    exportDataS3Url: "",
    domain: 'https://donate.charitable.one',
    donateBuckect: 'donate.charitable.one',
    permissions: {
        super: {
            dashboard: true,
            campaigns: true,
        }
    },
    authURL: 'http://one.rkd.io/',
    userType: 'editor',
    sidebar: 'fundraiser',

    peerDomainAPILocal: 'http://localhost:3030',
    peerDomainAPIStaging: 'https://peer.charitable.one',
    peerDomainAPILive: 'https://fundraise.charitable.one',


    imageDomain: 'https://public.charitable.one',
    appDomain: 'https://app.charitable.one',
    donateDomain: 'https://donate.charitable.one',
    loginBwsBirstDomain: 'https://login.bws.birst.com',

    s3PublicCharitableDomain: 'https://s3.amazonaws.com/public.charitable.one',
    legacyDomain: 'https://t7g51rmuzb.execute-api.us-east-1.amazonaws.com',
    developersFbDomain: 'https://developers.facebook.com',
    vwoDomain: 'https://vwo.com/',
    mailchimpDomain: 'https://mailchimp.com/',

    campaignYear: '',
    creativeName: '',
    questionType: '',
    questionData: {},
    subCampaignData: {},
    userID: '',

    dashboard: 'donation',
    services: 'https://services.charitable.one/',

    eventDomainAPILocal: 'http://localhost:3030',
    eventDomainAPIStaging: 'https://staging-event.charitable.one',
    eventDomainAPILive: 'https://event.charitable.one',

    ticketData: {},


    multiChannelReportingStage: false,
    multiChannelReportingLive: false,

    breadcrumbs: '',

    layout: '',
    formCreativeData: {},
    isColorSetting: false,
    colorSettingNeighbor: {},
    colorSettingGifting: {},
    isMinimumAmount: false,
    minimumAmountValue: {},
    showMissionLayoutForClients: ['LAM'],
    formBuilderEditorDomainStage: "https://dev-builder.unify.charitable.one/",
    formBuilderEditorDomainProduction: "https://formbuilder.charitable.one/",
    formBuilderEditorDomainLocal: "http://localhost:3001/",
    formBuilderViewerDomainStage: "https://dev-viewer.unify.charitable.one",
    formBuilderViewerDomainProduction: "https://viewer.formbuilder.charitable.one",
    formBuilderViewerDomainLocal: "http://localhost:3002",
    dmcRenderComponentsDomainStage: "https://dmc-components.unify.charitable.one",
    dmcRenderComponentsDomainLocal: "http://localhost:3044",
    environment: environment,
    //donor portal related reducer
    donorPortalImageData: [],
    clientMetaData: {},
    clientDashboardData: [],

    IdentityApi: IdentityApi

};

const mainReducer = (state = initialState, action) => {

    switch (action.type) {

        case updateUserTypes.UPDATE_USER_TYPE:
            return state = {
                ...state,
                userType: action.payload,
            };

        case updateUserTypes.CHANGE_DOMAIN:
            return state = {
                ...state,
                domain: action.payload,
            };

        case updateUserTypes.CHANGE_INITIAL:
            return state = {
                ...state,
                initial: action.payload,
            };

        case updateUserTypes.CHANGE_EXPORT_DATA:
            return state = {
                ...state,
                exportData: action.payload,
            };

        case updateUserTypes.CHANGE_QUERY_DATA:
            return state = {
                ...state,
                queryData: action.payload,
            };

        case updateUserTypes.CHANGE_EXPORT_DATA_S3URL:
            return state = {
                ...state,
                exportDataS3Url: action.payload,
            };

        case updateUserTypes.CHANGE_IMAGE_DATA:
            return state = {
                ...state,
                imageData: action.payload,
            };

        case updateUserTypes.CHANGE_CREATIVE_DATA:
            return state = {
                ...state,
                creativeData: action.payload,
            };

        case updateUserTypes.CHANGE_ROLE:
            return state = {
                ...state,
                role: action.payload,
            };

        case updateUserTypes.CHANGE_HTML:
            return state = {
                ...state,
                htmlContent: action.payload,
            };

        case updateUserTypes.CHANGE_CLIENT:
            return state = {
                ...state,
                client: action.payload,
            };

        case updateUserTypes.CHANGE_CLIENT_TYPE:
            return state = {
                ...state,
                clientType: action.payload,
            };

        case updateUserTypes.CHANGE_CURRENTE_MAIL:
            return state = {
                ...state,
                currentEmailHTML: action.payload,
            };

        case updateUserTypes.CHANGE_CAMPAIGN_STORED:
            return state = {
                ...state,
                campaignStored: action.payload,
            };

        case updateUserTypes.UPDATE_CLIENT_DATA:
            return state = {
                ...state,
                clientData: action.payload,
            };

        case updateUserTypes.UPDATE_CREATIVES:
            return state = {
                ...state,
                creatives: action.payload,
            };

        case updateUserTypes.SWAP_DASHBOARD:
            return state = {
                ...state,
                dashboard: action.payload,
            };

        case updateUserTypes.CHANGE_CREATIVE_NAME:
            return state = {
                ...state,
                creativeName: action.payload,
            };

        case updateUserTypes.CHANGE_QUESTION_TYPE:
            return state = {
                ...state,
                questionType: action.payload,
            };

        case updateUserTypes.CHANGE_QUESTION_DATA:
            return state = {
                ...state,
                questionData: action.payload,
            };

        case updateUserTypes.CHANGE_SUB_CAMPAIGN_DATA:
            return state = {
                ...state,
                subCampaignData: action.payload,
            };

        case updateUserTypes.CHANGE_USERID:
            return state = {
                ...state,
                userID: action.payload,
            };

        case updateUserTypes.CHANGE_CAMPAIGN_YEAR:
            return state = {
                ...state,
                campaignYear: action.payload,
            };

        case updateUserTypes.CHANGE_SIDEBAR:
            return state = {
                ...state,
                sidebar: action.payload,
            };

        case updateUserTypes.CHANGE_TICKET_DATA:
            return state = {
                ...state,
                ticketData: action.payload,
            };

        case updateUserTypes.UPDATE_MULTICHANNEL_REPORTING_STAGE:
            return state = {
                ...state,
                multiChannelReportingStage: action.payload,
            };

        case updateUserTypes.UPDATE_MULTICHANNEL_REPORTING_LIVE:
            return state = {
                ...state,
                multiChannelReportingLive: action.payload,
            };

        case updateUserTypes.CHANGE_BREADCRUMBS:
            return state = {
                ...state,
                breadcrumbs: action.payload,
            };

        case updateUserTypes.CHANGE_LAYOUT:
            return state = {
                ...state,
                layout: action.payload,
            };

        case updateUserTypes.CHANGE_FORM_CREATIVE_DATA:
            return state = {
                ...state,
                formCreativeData: action.payload,
            };

        case updateUserTypes.CHANGE_ISCOLOR_SETTING:
            return state = {
                ...state,
                isColorSetting: action.payload,
            };

        case updateUserTypes.CHANGE_COLOR_SETTING_NEIGHBOR:
            return state = {
                ...state,
                colorSettingNeighbor: action.payload,
            };

        case updateUserTypes.CHANGE_COLOR_SETTING_GIFTING:
            return state = {
                ...state,
                colorSettingGifting: action.payload,
            };

        case updateUserTypes.CHANGE_ISMINIMUM_AMOUNT:
            return state = {
                ...state,
                isMinimumAmount: action.payload,
            };

        case updateUserTypes.CHANGE_MINIMUM_AMOUNT_VALUE:
            return state = {
                ...state,
                minimumAmountValue: action.payload,
            };

        case updateUserTypes.CHANGE_DONOR_PORTAL_IMAGE_DATA:
            return state = {
                ...state,
                donorPortalImageData: action.payload,
            };

        case updateUserTypes.UPDATE_CLIENT_METADATA:
            return state = {
                ...state,
                clientMetaData: action.payload,
            };

        case updateUserTypes.GET_CLIENT_DASHBOARD:
            return state = {
                ...state,
                clientDashboardData: action.payload,
            };


        default:
            return state;
    }

}

export default mainReducer;