import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Cell } from 'recharts';
import { Card, Typography } from 'antd';

import { addLeadingZero } from '../../Helper/helper';

const { Text } = Typography;

const BarCharts = ({ data = [], isValid = false, eventType, segment }) => {
  const colors = ['#004A6C', '#0E7D9F', '#41B0D2', '#1A237E', '#303F9F', '#3F51B5', '#263238', '#455A64', '#607D8B'];

  const CustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-4 rounded shadow-lg">
          {eventType === "channels_performance" && (
            <>
              <Text className="date">{`Channel Name: ${payload[0].payload.name}`}</Text>
              <div>
                <Text className={`${!segment ? 'average' : 'central-tooltip'} text-blue-700`}>{`Donation Amount: $${addLeadingZero(payload[0].payload.totalamount)}`}</Text>
              </div>
            </>
          )}
          {eventType === "campaign_performance" && (
            <>
              <Text className="date">{`Campaign Name: ${payload[0].payload.name}`}</Text>
              <div>
                <Text className={`${!segment ? 'average' : 'central-tooltip'} text-blue-700`}>{`Donation Amount: $${addLeadingZero(payload[0].payload.totalamount)}`}</Text>
              </div>
            </>
          )}
          {eventType === "frequency_distribution" && (
            <div>
              <Text className={`${!segment ? 'average' : 'central-tooltip'} text-blue-700`}>{`Percentage: ${parseFloat(payload[0].payload.percentage).toFixed(2)}%`}</Text>
              <br></br>
              <Text className={`${!segment ? 'average' : 'central-tooltip'} text-blue-700`}>{`Total Amount: $${addLeadingZero(payload[0].payload.totalamount)}`}</Text>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        {eventType === "frequency_distribution" ? (
          <text x={2} y={-1} dy={10} textAnchor="end" fill="#666" transform="rotate(0)" fontSize="12px" className="ellipsis w-20">
            {truncateText(payload.value, 7)}
          </text>
        ) : (
          <text x={15} y={-1} dy={10} textAnchor="end" fill="#666" transform="rotate(-18)" fontSize="12px" className="ellipsis w-20">
            {truncateText(payload.value, 7)}
          </text>
        )}
      </g>
    );
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
    <Card className="bar-chart p-0 border-0">
      <ResponsiveContainer width="100%" height={segment ? 300 : 450}>
        {eventType === "frequency_distribution" ? (
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{ top: 5, right: 0, left: 0, bottom: 20 }}
          >
            <XAxis
              dataKey="count"
              textAnchor="end"
              interval={0}
              tick={<CustomizedAxisTick />}
              label={{
                value: 'Number of Donations per Donor',
                style: { textAnchor: 'middle' },
                position: 'bottom',
              }}
            />
            <YAxis
              tickFormatter={(value) => `${parseInt(value)}%`}
              interval="preserveStartEnd"
              width={75}
              padding={{ top: 10, bottom: 4 }}
              label={{
                value: 'Percentage of Donors',
                style: { textAnchor: 'middle' },
                angle: -90,
                position: 'insideLeft',
                offset: 5,
              }}
            />
            <CartesianGrid strokeDasharray="5 5" vertical={false} horizontal={false} />
            {isValid && <Tooltip cursor={false} content={<CustomTooltip />} />}
            <Bar dataKey="percentage" activeBar={false} stroke="none">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill="#004A6C" />
              ))}
            </Bar>
          </BarChart>
        ) : (
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{ top: 5, right: 0, left: 0, bottom: 20 }}
          >
            <XAxis
              dataKey="name"
              textAnchor="end"
              interval={0}
              tick={<CustomizedAxisTick />}
              label={{
                value: eventType === "channels_performance" ? 'Donation Channels' : 'Campaigns',
                style: { textAnchor: 'middle' },
                position: 'bottom',
              }}
            />
            <YAxis
              tickFormatter={(value) => `$${parseInt(value)}`}
              interval="preserveStartEnd"
              width={75}
              padding={{ top: 10, bottom: 4 }}
              label={{
                value: 'Total Donation Amount',
                style: { textAnchor: 'middle' },
                angle: -90,
                position: 'insideLeft',
                offset: 5,
              }}
            />
            <CartesianGrid strokeDasharray="5 5" vertical={false} horizontal={false} />
            {isValid && <Tooltip cursor={false} content={<CustomTooltip />} />}
            <Bar dataKey="totalamount" activeBar={false}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
        )}
      </ResponsiveContainer>
    </Card>
  );
};

export default BarCharts;
