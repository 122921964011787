import Event from '../../Components/Event/Event'

const onetime_donation = [

    {
        Header: 'Date',
        accessor: 'unixdate',
        Cell: row => (<span>{row.value}</span>),
        width: 133,
    },
    {
        Header: 'Campaign Name',
        accessor: 'campaign_name',
        Cell: row => (<span>{row.value}</span>),
        width: 147,
    },
    {
        Header: 'Google Id',
        accessor: 'ga_id',
        width: 147,
        Cell: (row) => (<span>{row.value}</span>),
    },
    {
        Header: 'Amount',
        accessor: 'transaction_amount',
        width: 147,
        Cell: row => (<span>${parseFloat(row.value).toFixed(2).toLocaleString()}</span>)
    },
    {
        Header: 'UTM Campaign',
        accessor: 'utm_campaign',
        Cell: (row) => (<span>{row.value}</span>),
        width: 150,
    },
    {
        Header: 'UTM Source',
        accessor: 'utm_source',
        Cell: (row) => (<span>{row.value}</span>),
        width: 150,
    },
    {
        Header: 'UTM Medium',
        accessor: 'utm_medium',
        Cell: (row) => (<span>{row.value}</span>),
        width: 150,
    }
];

const recurring_donation = [
    {
        Header: 'Date',
        accessor: 'unixdate',
        Cell: row => (<span>{row.value}</span>),
        width: 133
    },
    {
        Header: 'Campaign Name',
        accessor: 'campaign_name',
        Cell: row => (<span>{row.value}</span>),
        width: 147,
    },
    // eslint-disable-next-line
    {
        Header: 'Google ID',
        accessor: 'ga_id',
        width: 147,
        Cell: row => (
            // eslint-disable-next-line
            <span><span>{String(row.value)?.replace(/[\[\]"]/g, '')}</span></span>
        )
    },
    {
        Header: 'Amount',
        accessor: 'transaction_amount',
        width: 147,
        Cell: row => (
            <span>${parseFloat(row.value).toFixed(2).toLocaleString()}</span>
        )
    },
    {
        Header: 'UTM Campaign',
        accessor: 'utm_campaign',
        Cell: row => (<span>{row.value}</span>),
        width: 150,
    },
    {
        Header: 'UTM Source',
        accessor: 'utm_source',
        Cell: row => (<span>{row.value}</span>),
        width: 150,
    },
    {
        Header: 'UTM Medium',
        accessor: 'utm_medium',
        Cell: row => (<span>{row.value}</span>),
        width: 150,
    }
];

const general_email_submitted = [
    {
        Header: 'Signup Success',
        width: 150,
        columns: [{
            Header: 'Date',
            accessor: 'unixdate',
            Cell: row => (<strong>{row.value}</strong>),
            width: 134,
        }]
    },
    {
        Header: 'Data',
        width: 774,
        columns: [
            {
                Header: 'Email',
                accessor: 'email',
                Cell: row => (<strong>{row.value}</strong>),
                width: 126,
            },
            {
                Header: 'Google ID',
                accessor: 'ga_id',
                width: 126,
                Cell: row => (
                    <span><strong>{row.value?.replace('user', '')}</strong></span>
                )
            },
            {
                Header: 'Email Sign-up Type',
                accessor: 'email_signup_type',
                width: 126,
                Cell: row => (
                    <span><strong>{row.value?.replace('user', '')}</strong></span>
                ),
                disableFilters: true
            },
            {
                Header: 'URL',
                accessor: 'url',
                Cell: row => (<strong>{row.value}</strong>),
                width: 126,
            },
            {
                Header: 'UTM Campaign',
                accessor: 'utm_campaign',
                Cell: row => (<strong>{row.value}</strong>),
                width: 90,
            },
            {
                Header: 'UTM Source',
                accessor: 'utm_source',
                Cell: row => (<strong>{row.value}</strong>),
                width: 90,
            },
            {
                Header: 'UTM Medium',
                accessor: 'utm_medium',
                Cell: row => (<strong>{row.value}</strong>),
                width: 90,
            }
        ]
    },
    {
        Header: 'Action',
        width: 100,
        columns: [{
            Header: 'Options',
            accessor: 'type',
            width: 100,
            Cell: row => (<Event rowData={row} />)
        }]
    }
];

const engaged_20 = [
    {
        Header: 'Engaged 20',
        width: 150,
        columns: [{
            Header: 'Date',
            accessor: 'unixdate',
            Cell: row => (<strong>{row.value}</strong>),
            width: 134,
        }]
    },
    {
        Header: 'Data',
        width: 774,
        columns: [
            {
                Header: 'Google Id',
                accessor: 'ga_id',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            },
            {
                Header: 'URL',
                accessor: 'url',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            },
            {
                Header: 'UTM Campaign',
                accessor: 'utm_campaign',
                Cell: row => (<strong>{row.value}</strong>),
                width: 166,
            },
            {
                Header: 'UTM Source',
                accessor: 'utm_source',
                Cell: row => (<strong>{row.value}</strong>),
                width: 166,
            },
            {
                Header: 'UTM Medium',
                accessor: 'utm_medium',
                Cell: row => (<strong>{row.value}</strong>),
                width: 166,
            }
        ]
    },
    // {
    //     Header: 'Action',
    //     width: 100,
    //     columns: [{
    //         Header: 'Options',
    //         accessor: 'type',
    //         width: 100,
    //         Cell: row => (<Event rowData={row} />)
    //     }
    //     ]
    // }
];

const abandoned_cart = [

    {
        Header: 'Date',
        accessor: 'unixdate',
        width: 133,
        Cell: row => (<strong>{row.value}</strong>)
    },
    {
        Header: 'Google Id',
        accessor: 'ga_id',
        width: 147,
        Cell: row => (<span>{row.value}</span>)
    },
    {
        Header: 'Session Id',
        accessor: 'session_id',
        width: 147,
        Cell: row => (<span>{row.value}</span>)
    },
    {
        Header: 'Session Count',
        accessor: 'session_count',
        width: 147,
        Cell: row => (<span>{row.value}</span>)
    },
    {
        Header: 'UTM Campaign',
        accessor: 'utm_campaign',
        width: 150,
        Cell: row => (<span>{row.value}</span>)
    },
    {
        Header: 'UTM Source',
        accessor: 'utm_source',
        width: 150,
        Cell: row => (<span>{row.value}</span>)
    },
    {
        Header: 'UTM Medium',
        accessor: 'utm_medium',
        width: 150,
        Cell: row => (<span>{row.value}</span>)
    }
];

export {
    onetime_donation, recurring_donation, general_email_submitted, engaged_20, abandoned_cart
}