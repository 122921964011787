import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';

// Antd Icons for Header
import { BuildFilled, EnvironmentFilled, LineChartOutlined } from "@ant-design/icons";

// Redux Actions
import { ChangeInitial } from '../../redux/Main/action';

// JSX Components
import ClientChoose from '../Clients/chooseClient';
import TrafficCards from '../../Components/TrafficTrends/TrendCards';

// NPM Packages
import axios from 'axios';

// Date Picker Package
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

// Constant Variables
import { IdentityApi } from '../../utils/constants';
import moment from 'moment';
import { setLocalStorageWithExpiry, getLocalStorage, checkAndCleanExpiredLocalStorage } from "../../Helper/helper";

import TotalVisits from '../../Components/TrafficTrends/TotalVisits';
import UniqueVisits from "../../Components/TrafficTrends/UniqueVisits";
import NewVsRepeatVisits from "../../Components/TrafficTrends/NewVsRepeatVisits";


const { RangePicker } = DatePicker;

const TrafficTrends = () => {

    const { isAuthenticated } = useAuth0();
    const dispatch = useDispatch();
    const { role, client } = useSelector(({ main }) => main);

    const [totalVisits, setTotalVisits] = useState([]);
    const [uniqueVisits, setUniqueVisits] = useState([]);
    const [newVsRepeatVisits, setNewVsRepeatVisits] = useState([]);
    const [disableStartDate, setDisableStartDate] = useState('');

    const [deviceLocation, setDeviceLocation] = useState([]);
    const [deviceCategory, setDeviceCategory] = useState([]);
    const [datePopup, setDatePopup] = useState(true);
    const [pageDepth, setPageDepth] = useState([]);
    const [engagementRate, setEngagementRate] = useState([]);
    const [isValidCategory, setIsValidCategory] = useState(false);
    const [isValidLocation, setIsValidLocation] = useState(false);
    const [isValidPageDepth, setIsValidPageDepth] = useState(false);
    const [isValidEngaged, setIsValidEngaged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dates, setDates] = useState({
        startDate: dayjs().subtract(6, 'day'),
        endDate: dayjs()
    });
    const [isSelect, setIsSelect] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (isSelect && !datePopup && dates.startDate && dates.endDate) {
          fetchData();
          setIsSelect(false);
        }
    }, [datePopup]);

    useEffect(() => {
        setDates({
            startDate: dayjs().subtract(6, 'day'),
            endDate: dayjs()
        })
    }, [client]);

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    const fetchData = async () => {
        const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
        const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
        const dateDifference = moment(endDate).diff(moment(startDate), 'days');

        try {
            setIsLoading(true);

            checkAndCleanExpiredLocalStorage('TrafficTrends_');
            let cookieData = getLocalStorage(`TrafficTrends_${startDate}_${endDate}_${client}`);
            if (cookieData) {
                // A delay is needed for the Page Depth chart to render properly
                await sleep(100);
                setPageDepth(cookieData.pageDepthData);
                setIsValidPageDepth(true);

                setIsValidCategory(true);
                setDeviceCategory(cookieData.deviceCategoryData);

                setIsValidLocation(true);
                setDeviceLocation(cookieData.cityStateData);

                setTotalVisits(cookieData.totalUserData);

                setUniqueVisits(cookieData.uniqueUserData);

                setNewVsRepeatVisits(cookieData.userFrequencyData);
                setIsLoading(false);
                return;
            }

            const pageDepthData = await axios.get(
              `${IdentityApi}page-view/depth?start_date=${startDate}&end_date=${endDate}&client=${client}`
            );

            const deviceCategoryData = await axios.get(
              `${IdentityApi}page-view/device-metrics?start_date=${startDate}&end_date=${endDate}&client=${client}`
            );

            const cityStateData = await axios.get(
              `${IdentityApi}page-view/city-state?start_date=${startDate}&end_date=${endDate}&client=${client}`
            );

            const totalUserData = await axios.get(
              `${IdentityApi}page-view/total-users?start_date=${startDate}&end_date=${endDate}&client=${client}`
            );

            const uniqueUserData = await axios.get(
              `${IdentityApi}page-view/unique-users?start_date=${startDate}&end_date=${endDate}&client=${client}`
            );

            const userFrequencyData = await axios.get(
              `${IdentityApi}page-view/user-frequency?start_date=${startDate}&end_date=${endDate}&client=${client}`
            );

            let storage = {
                'pageDepthData': pageDepthData.data,
                'deviceCategoryData': deviceCategoryData.data,
                'cityStateData': cityStateData.data,
                'totalUserData': totalUserData.data,
                'uniqueUserData': uniqueUserData.data,
                'userFrequencyData': userFrequencyData.data,
            };
    
            setLocalStorageWithExpiry(`TrafficTrends_${startDate}_${endDate}_${client}`, storage, 1);

            setPageDepth(pageDepthData.data);
            setIsValidPageDepth(true);

            setIsValidCategory(true);
            setDeviceCategory(deviceCategoryData.data);

            setIsValidLocation(true);
            setDeviceLocation(cityStateData.data);

            setTotalVisits(totalUserData.data);

            setUniqueVisits(uniqueUserData.data);

            setNewVsRepeatVisits(userFrequencyData.data);

            setIsLoading(false);
        } catch (error) {
            ifArrayBlank(dateDifference, startDate);
            setIsLoading(false);
            console.error("An error occurred:", error);
        }
    };

    const ifArrayBlank = (dateDifference, startDate) => {
        let Array = [];
        let engagedArray = [];
        for (let i = 0; i <= dateDifference; i++) {
            const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
            Array.push({ date: currentDate, average: 0 });
            // engagedArray.push({ date: currentDate, engaged_rate: 0 });
        }
        setPageDepth(Array);
        // setEngagementRate(engagedArray);
        setIsValidPageDepth(false);
        setIsValidEngaged(false);
    };;

    const handleDateChange = (date, dateString) => {
        if (dateString && dateString[0] && dateString[1]) {
            setIsSelect(true);
            setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) });
        }
    }

    const handle = async (val) => {
        setDatePopup(val);
    }

    return (
        <div className='traffic-trends'>
            {isAuthenticated && role === "admin" && (
                <ClientChoose alertType="alert alert-success">
                    <span>
                        Welcome <strong>Team Member</strong>. Please choose a client to
                        view/edit their data.
                    </span>
                </ClientChoose>
            )}

            <div className="date-range card">
                <div className="card-content" style={{padding:"5px 20px"}}>
                    <div className="row">
                        <div className="col-md-9 col-sm-9">
                            <RangePicker
                                defaultValue={[dates.startDate, dates.endDate]}
                                onChange={handleDateChange}
                                onOpenChange={handle}
                                allowClear={false}
                                size={"middle"}
                                variant="borderless"
                                format={"YYYY-MM-DD"}
                                separator={<i className="material-icons">arrow_right_alt</i>}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <TrafficCards
                title="Page Depth"
                trafficType="page_depth"
                data={pageDepth}
                isValid={isValidPageDepth}
                isLoading={isLoading}
                headerIcon={<LineChartOutlined style={{ fontSize: '28px', width: '33px' }} />}
            />
            
            {/* <TrafficCards
                title="Engagement Rate"
                trafficType="engagement_rate"
                data={engagementRate}
                isValid={isValidEngaged}
                isLoading={isLoading}
                headerIcon={<LineChartOutlined style={{ fontSize: '28px', width: '33px' }} />}
            /> */}
            
            <TrafficCards
                title="Device Category"
                trafficType="device_category"
                data={deviceCategory}
                filter_dates={dates}
                isValid={isValidCategory}
                isLoading={isLoading}
                headerIcon={<BuildFilled style={{ fontSize: '28px', width: '33px' }} />}
            />

            <TrafficCards
                title="Device Location"
                trafficType="device_location"
                data={deviceLocation}
                isValid={isValidLocation}
                isLoading={isLoading}
                headerIcon={<EnvironmentFilled style={{ fontSize: '28px', width: '33px' }} />}
            />

          <TotalVisits
            totalVisits={totalVisits}
            isLoading={isLoading}
            dates={dates}
            setDates={setDates}
            disableStartDate={disableStartDate}
          />
          <UniqueVisits
            uniqueVisits={uniqueVisits}
            isLoading={isLoading}
            dates={dates}
            setDates={setDates}
            disableStartDate={disableStartDate}
          />
          <NewVsRepeatVisits
            newVsRepeatVisits={newVsRepeatVisits}
            isLoading={isLoading}
            dates={dates}
            setDates={setDates}
            disableStartDate={disableStartDate}
          />

        </div>
    )
}
export default TrafficTrends;