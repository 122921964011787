import React from 'react';

const CardWithHeader = (props) => {

  const handalTabRedirection = () => {
    props.handleDisplayOfExportTab();
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">

          <div className="card-header card-header-icon" data-background-color={props.color}>
            <i className="material-icons">{props.icon}</i>
          </div>

          <div className="card-content">
            {props.title !== '' && <h4 className="card-title breadcrumbsText"><a href='void(0)' onClick={() => handalTabRedirection()} role="tab" data-toggle="tab">{props.tabName}</a> {props.title}</h4>}
            {props.children}
          </div>

        </div>
      </div>
    </div>
  );

}

export default CardWithHeader;