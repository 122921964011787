import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const EventBarChart = ({ data = [] }) => {
    const demoData = [{ name: "", value: 0 }];
    const colors = ['#004A6C', '#0E7D9F', '#41B0D2', '#1A237E', '#303F9F', '#3F51B5', '#263238', '#455A64', '#607D8B'];
    
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={15} y={-1} dy={10} textAnchor="end" fill="#666" transform="rotate(-18)" fontSize={'12px'} style={{ textOverflow: 'ellipsis', width: '20px' }}>
                    {truncateText(payload.value, 7)}
                </text>
            </g>
        );
    };
 
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }
    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <>
                        <div>
                            <p className="campaign">{`Campaign Name: ${payload[0].payload.name}`}</p>
                            <p className="average" style={{color:'#287d9f'}}>{`Conversion Rate: ${payload[0].payload.value}%`}</p>
                        </div>
                    </>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer width="100%" height={400}>
                {data.length == 0 ? (
                    <BarChart
                        width={500}
                        height={300}
                        data={demoData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 25,
                        }}
                    >
                        <XAxis
                            dataKey="name" 
                            label={{
                                value: "Campaign Name",
                                style: { textAnchor: 'middle' },
                                position: 'bottom',
                                offset: 0,
                            }}
                            interval={0}
                            tick={<CustomizedAxisTick />}
                        />
                        <YAxis
                            allowDataOverflow={true}
                            interval="preserveStartEnd"
                            width={100}
                            padding={{
                                top: 10,
                                bottom: 4
                            }}
                            label={{
                                value: "Conversion Rate (%)",
                                style: { textAnchor: 'middle' },
                                angle: -90,
                                position: 'left',
                                offset: 0,
                            }}
                        />
                        <Bar dataKey="value" fill="#287d9f" />
                    </BarChart>
                ): (
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 25,
                        }}
                    >
                        <XAxis
                            dataKey="name" 
                            label={{
                                value: "Campaign Name",
                                style: { textAnchor: 'middle' },
                                position: 'bottom',
                                offset: 5,
                            }}
                            interval={0}
                            tick={<CustomizedAxisTick />}
                        />
                        <YAxis
                            allowDataOverflow={true}
                            interval="preserveStartEnd"
                            width={100}
                            padding={{
                                top: 10,
                                bottom: 4
                            }}
                            label={{
                                value: "Conversion Rate (%)",
                                style: { textAnchor: 'middle' },
                                angle: -90,
                                position: 'left',
                                offset: 0,
                            }}
                        />
                        {data.length ?
                            <Tooltip cursor={false} content={<CustomTooltip />} />
                            : ''
                        }
                        <Bar dataKey="value">
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Bar>
                    </BarChart>
                )}
            </ResponsiveContainer>
        </div>
    )
}

export default EventBarChart;