import React from 'react'

const PageResizer = ({
    previousPage,
    setPageSize,
    nextPage,
    defaultPageSize,
    gotoPage,
    pageSizeOptions,
    nextPageEvent,
    previousPageEvent,
    tablePages,
    pageNumberCount,
    inputOnChange,
}) => {
    return (
        <div className='pagination-top'>
            <div className='-pagination'>
                <div className='-previous'>
                    <button disabled={pageNumberCount === 1 ? true : false} onClick={() => { previousPage(); previousPageEvent(1); }} className='-btn' type="button">
                        Previous
                    </button>
                </div>
                <div className='-center'>
                    <span className='-pageInfo'>
                        Page
                        <div className='-pageJump'>
                            <input type="number" value={pageNumberCount} onChange={(e) => inputOnChange(parseInt(e.target.value))} min={1} max={tablePages} />
                        </div>
                        <span> of </span>
                        <span className="-totalPages">{tablePages || 1}</span>
                    </span>
                    {pageSizeOptions ?
                        <span className='select-wrap -pageSizeOptions'>
                            <select onChange={(e) => { setPageSize(e.target.value); gotoPage(0); }} defaultValue={defaultPageSize}>
                                {[5, 10, 20, 25, 50, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </span>
                        : ''
                    }
                </div>
                <div className='-next'>
                    <button disabled={pageNumberCount === tablePages ? true : false} onClick={() => { nextPage(); nextPageEvent(1); }} className='-btn' type="button">
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PageResizer;
