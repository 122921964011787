import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Breadcrumb } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { PoweroffOutlined } from '@ant-design/icons';
import crypto from 'crypto';
import { logout as ClearSession } from "../Security/Auth/Auth";
import { UpdateUser, UpdateProducts } from '../redux/Security/action';
import { getProfile } from '../Security/Auth/Auth';

const { Header } = Layout;

const TopNav = ({ collapsed }) => {
    const { breadcrumbs: breadcrumbsTitle, initial } = useSelector(({ main }) => main);
    const { isAuthenticated, user: authUser } = useAuth0();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (isAuthenticated) {
            const authorization = async () => {
                if (Object.keys(authUser).length > 0) {
                    let authenticatedUser = await getProfile(authUser);
                    dispatch(UpdateUser(authenticatedUser));
                    dispatch(UpdateProducts(authenticatedUser.products));
                }
            };
            authorization();
        }
    }, [isAuthenticated, authUser, dispatch]);

    useEffect(() => {
        const secretKey = process.env.REACT_APP_INTERCOM_SECRET;
        const userIdentifier = authUser.email;
        const hash = crypto
            .createHmac('sha256', secretKey)
            .update(userIdentifier)
            .digest('hex');
        window.Intercom('boot', {
            api_base: 'https://api-iam.intercom.io',
            app_id: process.env.INTERCOM_APP_ID,
            name: authUser.name,
            email: authUser.email,
            created_at: authUser.updated_at,
            user_hash: hash,
        });
    }, [authUser]);

    const handleLogout = () => {
        ClearSession();
        window.Intercom('shutdown');
    };

    // Mapping of paths to breadcrumb labels
    const breadcrumbMapping = {
        '/admin/identity-dashboard': 'Dashboard',
        '/admin/give-central': 'Giving Central',
        '/admin/engaged-sessions': 'Engaged Sessions',
        '/admin/traffic-trends': 'Traffic Trends',
        '/admin/activationsList': 'Activations',
        '/admin/constituentProfile': 'Constituent Profile',
        '/admin/connections': 'Connections',
        '/admin/CreateConnections': 'Create Connections',
        '/admin/createConnections': 'Create Connections',
    };

    // Function to generate breadcrumb items based on the sidebar mapping
    const getBreadcrumbItems = () => {
        const currentPath = location.pathname;
        const mainPath = currentPath.split('/').slice(0, 3).join('/');
        const breadcrumbLabel = breadcrumbMapping[mainPath] || 'Dashboard'; // Default to 'Dashboard' if not found in mapping

        return (
            <Breadcrumb>
                {/* Home Breadcrumb Item */}
                <Breadcrumb.Item key="home" className='mr-2'>
                    <Link to="/admin/identity-dashboard"> Home</Link>
                </Breadcrumb.Item>
                {/* Menu Item Label */}
                {breadcrumbLabel !== 'Dashboard' && (
                    <Breadcrumb.Item key={mainPath}>
                        <Link to={mainPath}>  /  {breadcrumbLabel}</Link>
                    </Breadcrumb.Item>
                )}
            </Breadcrumb>
        );
    };

    return (
        <Header className={`bg-white flex flex-row-reverse justify-between items-center px-8 w-full top-0 z-50 transition-all duration-300 ${collapsed ? 'topnav-collapsed' : 'topnav'}`} style={{ background: "white" }}>
            <div className="flex flex-col items-center cursor-pointer" onClick={handleLogout}>
                <PoweroffOutlined className="text-3xl hover:text-red-500" />
                <span className="text-sm text-gray-500">Logout</span>
            </div>
            <div className="navbar-header mt-4">
                <div className="text-2xl font-bold ml-10">
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        {getBreadcrumbItems()}
                    </Breadcrumb>
                </div>
            </div>
        </Header>
    );
};

export default TopNav;