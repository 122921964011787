// packages
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import axios from "axios";
import moment from 'moment';

// Import Columns
import {
  onetime_donation as oneTimeDonations,
  recurring_donation as recurringDonations,
} from "../ClientData/actions";

//import components
import GiveEventChart from "../../Components/GiveEventChart/GiveEventChart";
import CityTable from "../../Components/GiveEventChart/CityTable";
import AvgCharts from "../../Components/AvgCharts/AvgCharts";
import GivingCentralDynamicCard from "../Shared/GivingCentralDynamicCard";
import { setLocalStorageWithExpiry, getLocalStorage, checkAndCleanExpiredLocalStorage } from "../../Helper/helper";

const GiveEvents = ({
  isSelect,
  datePopup,
  setIsSelect,
  btnActive,
  isDropDown,
  dates,
  setIsDateToggle,
  handleDropdownChange
}) => {

  const { client, IdentityApi } = useSelector(({ main }) => main);
  const [average_donation_amount, setAverageDonationAmount] = useState([]);
  const [channels_performance, setChannelsPerformance] = useState([]);
  const [onetime_vs_recurring, setOnetimeVsRecurring] = useState([]);
  const [donation_source, setDonationSource] = useState([]);
  const [donationFrequency, setDonationFrequency] = useState([]);
  const [campaign_performance, setCampaignPerformance] = useState([]);
  const [donation_city, setDonationCity] = useState([]);
  const [totalDonation, setTotalDonation] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isValidAverage, setIsValidAverage] = useState(false);
  const [isValidChannel, setIsValidChannel] = useState(false);
  const [isValidOneTime, setIsValidOneTime] = useState(false);
  const [isValidCampaign, setIsValidCampaign] = useState(false);
  const [isValidSource, setIsValidSource] = useState(false);
  const [isValidFrequency, setIsValidFrequency] = useState(false);
  const [isValidTotalDonation, setIsValidTotalDonation] = useState(false);
  const [isValidCity, setIsValidCity] = useState(false);


  useEffect(() => {
    if (isDropDown !== "custom-date" && isDropDown !== "Select" && btnActive === "days") {
      setIsValidAverage(false);
      setIsValidOneTime(false);
      setIsValidTotalDonation(false);
      fetchData();
    }
  }, [client, isDropDown]);

  useEffect(() => {
    if (isSelect && !datePopup && dates.startDate && dates.endDate) {
      setIsValidAverage(false);
      setIsValidOneTime(false);
      setIsValidTotalDonation(false);
      fetchData();
      setIsSelect(false);
      setIsDateToggle(false);
      handleDropdownChange("Select", dates.startDate, dates.endDate);
    }
  }, [datePopup]);

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const fetchData = async () => {
    const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
    const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
    const dateDifference = moment(endDate).diff(moment(startDate), 'days');

    try {
      setisLoading(true);

      checkAndCleanExpiredLocalStorage('GivingCentral_');
      let cookieData = getLocalStorage(`GivingCentral_${startDate}_${endDate}_${client}`);

      if (cookieData) {
        // A delay is needed for the Average and Onetime vs Recurring charts to render properly
        await sleep(100);
        setAverageDonationAmount(cookieData.avg);
        setIsValidAverage(true);

        setOnetimeVsRecurring(cookieData.new_vs_sub);
        setIsValidOneTime(true);

        setTotalDonation(cookieData.over_time);
        setIsValidTotalDonation(true);

        let frequency_data = cookieData.frequency.length > 0 ? cookieData.frequency : [{ count: "", percentage: 0 }];
        setIsValidFrequency(true);
        setDonationFrequency(frequency_data);

        let channels_data = cookieData.channels.length > 0 ? cookieData.channels : [{ name: "", totalamount: 0 }];
        setIsValidChannel(true);
        setChannelsPerformance(channels_data);

        let campaigns_data = cookieData.campaigns.length > 0 ? cookieData.campaigns : [{ name: "", totalamount: 0 }];
        setIsValidCampaign(true);
        setCampaignPerformance(campaigns_data);

        let sources_data = cookieData.sources.length > 0 ? cookieData.sources : [{ name: "", totalamount: 1 }];
        setIsValidSource(cookieData.sources.length);
        setDonationSource(sources_data);

        let city_state_data = cookieData.city_state.length > 0 ? cookieData.city_state : [];
        setIsValidCity(true);
        setDonationCity(city_state_data);

        setisLoading(false);
        return;
      }
      const avg = await axios.get(
        `${IdentityApi}donations/avg?start_date=${startDate}&end_date=${endDate}&client=${client}`
      );

      const over_time = await axios.get(
        `${IdentityApi}donations/over-time?start_date=${startDate}&end_date=${endDate}&client=${client}`
      );

      const new_vs_sub = await axios.get(
        `${IdentityApi}donations/types?start_date=${startDate}&end_date=${endDate}&client=${client}`
      );

      const frequency = await axios.get(
        `${IdentityApi}donations/frequency?start_date=${startDate}&end_date=${endDate}&client=${client}`
      );

      const channels = await axios.get(
        `${IdentityApi}donations/channel?start_date=${startDate}&end_date=${endDate}&client=${client}`
      );

      const campaigns = await axios.get(
        `${IdentityApi}donations/campaign?start_date=${startDate}&end_date=${endDate}&client=${client}`
      );

      const sources = await axios.get(
        `${IdentityApi}donations/source?start_date=${startDate}&end_date=${endDate}&client=${client}`
      );

      const city_state = await axios.get(
        `${IdentityApi}donations/city-state?start_date=${startDate}&end_date=${endDate}&client=${client}`
      );

      let storage = {
        'avg': avg.data,
        'over_time': over_time.data,
        'new_vs_sub': new_vs_sub.data,
        'frequency': frequency.data,
        'channels': channels.data,
        'campaigns': campaigns.data,
        'sources': sources.data,
        'city_state': city_state.data,
      };

      setLocalStorageWithExpiry(`GivingCentral_${startDate}_${endDate}_${client}`, storage, 1);

      setAverageDonationAmount(avg.data);
      setIsValidAverage(true);

      setOnetimeVsRecurring(new_vs_sub.data);
      setIsValidOneTime(true);

      setTotalDonation(over_time.data);
      setIsValidTotalDonation(true);

      let frequency_data = frequency.data.length > 0 ? frequency.data : [{ count: "", percentage: 0 }];
      setIsValidFrequency(true);
      setDonationFrequency(frequency_data);

      let channels_data = channels.data.length > 0 ? channels.data : [{ name: "", totalamount: 0 }];
      setIsValidChannel(true);
      setChannelsPerformance(channels_data);

      let campaigns_data = campaigns.data.length > 0 ? campaigns.data : [{ name: "", totalamount: 0 }];
      setIsValidCampaign(true);
      setCampaignPerformance(campaigns_data);

      let sources_data = sources.data.length > 0 ? sources.data : [{ name: "", totalamount: 1 }];
      setIsValidSource(sources.data.length > 0);
      setDonationSource(sources_data);

      let city_state_data = city_state.data.length > 0 ? city_state.data : [];
      setIsValidCity(true);
      setDonationCity(city_state_data);

      setisLoading(false);
    } catch (error) {
      const average_donation_amount = Array.from({ length: dateDifference + 1 }, (_, i) => {
        const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
        return { date: currentDate, average1: 0, average2: 0 };
      });
      const onetime_vs_recurring = Array.from({ length: dateDifference + 1 }, (_, i) => {
        const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
        return { date: currentDate, oneTimeTotalDonors: 0, recurringTotalDonors: 0 };
      });
      const totalDonationAmt = Array.from({ length: dateDifference + 1 }, (_, i) => {
        const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
        return { date: currentDate, totalDonationAmt: 0 };
      });
      setAverageDonationAmount(average_donation_amount);
      setOnetimeVsRecurring(onetime_vs_recurring);
      setTotalDonation(totalDonationAmt);
      setisLoading(false);
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      {client !== "NONE" && (
        <div className="dashboard dynamic-card">

          {/*Giving event chart*/}
          <div className="flex flex-wrap">
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <GiveEventChart eventType="average_donation_amount" urlSegment="average_donation_amount" data={average_donation_amount} isValid={isValidAverage} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <GiveEventChart eventType="total_donation" urlSegment="total_donation" data={totalDonation} isValid={isValidTotalDonation} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <GiveEventChart eventType="onetime_vs_recurring" urlSegment="onetime_vs_recurring" data={onetime_vs_recurring} isValid={isValidOneTime} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <GiveEventChart eventType="frequency_distribution" urlSegment="frequency_distribution" data={donationFrequency.sort((a, b) => b.totalamount - a.totalamount).slice(0, 5)} isValid={isValidFrequency} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <GiveEventChart eventType="channels_performance" urlSegment="channels_performance" data={channels_performance.sort((a, b) => b.totalamount - a.totalamount).slice(0, 5)} isValid={isValidChannel} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <GiveEventChart eventType="campaign_performance" urlSegment="campaign_performance" data={campaign_performance.sort((a, b) => b.totalamount - a.totalamount).slice(0, 5)} isValid={isValidCampaign} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <GiveEventChart
                eventType="donation_source"
                urlSegment="donation_source"
                data={donation_source.sort((a, b) => b.totalamount - a.totalamount).slice(0, 5)}
                isValid={isValidSource}
                isLoading={isLoading}
                btnActive={btnActive}
                isDropDown={isDropDown}
                dates={dates}
                setisLoading={setisLoading}
              />

            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <AvgCharts urlSegment="average_chart" btnActive={btnActive} isDropDown={isDropDown} dates={dates} isSelect={isSelect} setIsSelect={setIsSelect} datePopup={datePopup} setisLoading={setisLoading} />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <CityTable eventType="donation_city" urlSegment="donation_city" data={donation_city} isValid={isValidCity} isLoading={isLoading} btnActive={btnActive} isDropDown={isDropDown} dates={dates} setisLoading={setisLoading} />
            </div>
          </div>
          {/* One Time Donations Card */}
          <GivingCentralDynamicCard title="One Time Donations" formType="onetime_donation" background="linear-gradient(60deg, rgb(255, 200, 69), rgb(255, 158, 27))" headerIcon="attach_money" columns={oneTimeDonations} card={["Person", "Revenue", "Device"]} urlSegment="onetime_donation" dates={dates} isDropDown={isDropDown} isSelect={isSelect} setIsSelect={setIsSelect} datePopup={datePopup} btnActive={btnActive}
          />

          {/* Recurring Donations Card */}
          <GivingCentralDynamicCard title="Recurring Donations" formType="recurring_donation" background="linear-gradient(60deg, rgb(239, 83, 80), rgb(229, 57, 53))" headerIcon="attach_money" columns={recurringDonations} card={["Person", "Revenue", "Device"]} urlSegment="recurring_donation" dates={dates} isDropDown={isDropDown} isSelect={isSelect} setIsSelect={setIsSelect} datePopup={datePopup} btnActive={btnActive}
          />
        </div>
      )}
    </>
  );
};

export default GiveEvents;