import SecurityTypes from "./type";

export const UpdateUser = (query) => {
    return {
        type: SecurityTypes.UPDATE_USER,
        payload: query
    }
};

export const UpdateProducts = (query) => {
    return {
        type: SecurityTypes.UPDATE_PRODUCTS,
        payload: query
    }
};