/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Modal, Row } from 'antd';
import { useSelector } from 'react-redux';
import { Card, Typography } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import * as ss from 'simple-statistics'
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import { Tooltip as AntdTooltip } from 'antd';
import { DownloadOutlined, ExpandOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { setLocalStorageWithExpiry, getLocalStorage, checkAndCleanExpiredLocalStorage } from "../../Helper/helper";

dayjs.extend(weekday);
dayjs.extend(localeData);

//Plugins
import moment from 'moment';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { useAuth0 } from "@auth0/auth0-react";
import { replaceArrayKeys } from "../../Helper/helper";

const { RangePicker } = DatePicker;

const AvgCharts = ({
    urlSegment,
    btnActive,
    isDropDown,
    dates,
    isSelect,
    setIsSelect,
    datePopup
}) => {

    const { client, IdentityApi } = useSelector(({ main }) => main);
    const [activeTab, setActiveTab] = useState("oneTime");
    const [average, setAverage] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ApiData, setApiData] = useState([]);
    const { user: authUser } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchData();
        setIsSelect(false);
    }, [client, isDropDown]);

    useEffect(() => {
        if (isSelect && !datePopup && dates.startDate && dates.endDate) {
            fetchData();
        }
    }, [datePopup]);

    const fetchData = async (tab) => {
        try {
            setIsLoading(true);
            // Parse start and end dates
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            checkAndCleanExpiredLocalStorage('GivingCentral_');
            let cookieData = getLocalStorage(`GivingCentral_${startDate}_${endDate}_${client}_avg`);
            if (cookieData) {
                setApiData(cookieData)

                let data = HandleYData(cookieData);

                setData(data);  
                setAverage(data);
                setIsLoading(false)
                return;
            }

            const results = await axios.get(
                `${IdentityApi}donations/avg-trend?start_date=${startDate}&end_date=${endDate}&client=${client}`
            );

            if (results.status === 200) {
                setApiData(results.data)

                let data = results.data;

                setLocalStorageWithExpiry(`GivingCentral_${startDate}_${endDate}_${client}_avg`, data, 1);

                data = HandleYData(data);

                setData(data);
                setAverage(data);
                setIsLoading(false);
            }
        } catch (error) {
            let Array = [];
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            //calculate dashbard Rechart  
            for (let i = 0; i <= dateDifference; i++) {
                // Get the current date
                const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                Array.push({ date: currentDate, average: 0, qty: 0, y: 0 });
            }
            setData(Array)
            setApiData([])
            setIsLoading(false)
            console.error("An error occurred:", error);
        }
    };

    // const handleTabChange = (tab) => {
    //     setActiveTab(tab);
    //     fetchData(tab)
    // };

    useEffect(() => {
        const timer = setTimeout(() => {
            setData(tabData['average']);
        }, 5);

        return () => clearTimeout(timer);
    }, [activeTab, average]);

    const tabData = {
        average: average
    };

    const HandleYData = (data) => {
        //reference line 
        const dates = [];
        const values = [];

        data.map((entry) => {
            const date = entry.date;
            const value = entry.average;
            dates.push(date);
            values.push(value);
        });

        const regression = ss.linearRegression(
            values.map((value, index) => [index, value])
        );

        const predict = ss.linearRegressionLine(regression);
        const predictedValues = dates.map((date, index) => ({
            date: date,
            y: parseFloat(predict(index).toFixed(2)),
        }));

        data.forEach(item => {
            const matchingItem = predictedValues.find(d => d.date === item.date);
            if (matchingItem) {
                item.y = matchingItem.y;
            }
        });

        return data;
    };
    const formattedDate=(dateString)=> {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        return formattedDate;
      }
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            urlSegment ?
                <g transform={`translate(${x},${y})`}>
                    <text x={20} y={14} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                        {formattedDate(payload.value)}
                    </text>
                </g>
                : <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                        {formattedDate(payload.value)}
                    </text>
                </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bg-white p-4 rounded shadow-lg">
                    <p className="date">{`${formattedDate(payload[0].payload.date)}`}</p>
                    <>
                        <div>
                            <p className={!urlSegment ? `average` : 'central-tooltip'}>{`Average: $${payload[0].payload.average}`}</p>
                        </div>
                        <div>
                            <p className={!urlSegment ? `average` : 'central-tooltip'}>{`Quantity: ${payload[0].payload.qty}`}</p>
                        </div>
                        <div>
                            <p className={!urlSegment ? `y` : 'central-tooltip'} style={{ color: '#4c9ef5' }}>{`Trend Line: $${payload[1].payload.y}`}</p>
                        </div>
                    </>
                </div>
            );
        }
        return null;
    };

    const handledownload = () => {
        setIsModalOpen(true);
    }

    const handleOk = async () => {
        if (data.length === 0) {
            window.swal({
                title: "Error",
                text: "No data to download",
                icon: "error",
                button: "OK",
            });
            setIsModalOpen(false);
            return;
        }

        setisLoading(true);

        try {
            let array = replaceArrayKeys(data, [{ old: "average", new: "Average" }, { old: "qty", new: "Quantity" }, { old: "y", new: "Trend Line" }]);

            // Step 1: Extract Column Headers
            const headers = Object.keys(array[0]);

            // Step 2: Create Header Row
            const csvHeader = headers.join(',') + '\n';

            // Step 3: Create Data Rows
            const csvRows = array.map(row => {
                return headers.map(fieldName => {
                // Handle values that contain commas or newlines
                const fieldValue = row[fieldName];
                return `"${fieldValue.toString().replace(/"/g, '""')}"`; // Escape double quotes
                }).join(',');
            }).join('\n');

            // Step 4: Combine Header and Data Rows
            const csvContent = csvHeader + csvRows;

            // Proceed with creating and downloading the file as before
            const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const fileURL = URL.createObjectURL(file);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `Average Donation with Trend Line_${client}_${dayjs(dates?.startDate).format("YYYY-MM-DD")}_${dayjs(dates?.endDate).format("YYYY-MM-DD")}.csv`);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);

            setIsLoading(false);
            setIsModalOpen(false);
        } catch (error) {
            window.swal({ title: "Error", text: 'Something went wrong! Please try again later.', icon: "error", button: "OK" });
            setIsLoading(false);
            setIsModalOpen(false);
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    return (
        <div className="avg-chart">
            <div className={urlSegment ? "" : "avg-card"} style={{ paddingLeft: '0px', paddingTop: '20px' }}>
                {urlSegment && (
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="text-2xl font-bold">Average Donation with Trend Line</div>
                        </div>
                    </div>
                )}
                <div className="card p-0" style={{ maxHeight: urlSegment ? '350px' : '' }}>
                                    <div className={`p-1  flex justify-end space-x-4 ${urlSegment ? 'event-icons' : 'event-icons-download'}`}>
                                        {!isLoading && urlSegment && (
                                            <>
                                                <AntdTooltip title="Maximize" className="mr-2 p-1">
                                                    {urlSegment && (
                                                        <a
                                                            href={`/admin/give-central/maximized/${urlSegment}/${btnActive}/${isDropDown}/${dayjs(dates?.startDate).format('YYYY-MM-DD')}_${dayjs(dates?.endDate).format('YYYY-MM-DD')}`}
                                                            target='_blank'
                                                            style={{ color: "#666666" }}
                                                        >
                                                            <ExpandOutlined className="cursor-pointer" />
                                                        </a>
                                                    )}
                                                </AntdTooltip>
                                                <AntdTooltip title="Download" className="hidden">
                                                    <DownloadOutlined
                                                        className="cursor-pointer"
                                                        onClick={() => handledownload()}
                                                    />
                                                </AntdTooltip>
                                            </>
                                        )}
                                        {/* {! urlSegment && (
                                            <div className="tab-container p-0">
                                                <div className="btn-group btn-group-sm">
                                                    <button className={`btn tabs ${activeTab === "oneTime" && 'tabshover'}`} onClick={() => handleTabChange("oneTime")}>
                                                        One Time
                                                    </button>
                                                    <button className={`btn tabs ${activeTab === "recurring" && 'tabshover'}`} onClick={() => handleTabChange("recurring")}>
                                                        Recurring
                                                    </button>
                                                </div>
                                            </div>
                                        )} */}
                                        {! urlSegment && (
                                            <div className={`${!urlSegment && 'download-icon'}`}>
                                                <AntdTooltip title="Download" className="hidden">
                                                    <span className='icon-title-icon'>
                                                        <DownloadOutlined className="cursor-pointer" onClick={() => handledownload()} />
                                                    </span>
                                                </AntdTooltip>
                                            </div>
                                        )}
                                    </div>
                                    {!isLoading && (
                                        <>
                                            <Card className='charts p-0 border-0' >
                                                <ResponsiveContainer width={"100%"} height={urlSegment ? 300 : 450}>
                                                    <LineChart data={data}
                                                      margin={{ top: 5, right: 0, left: -20, bottom: 20 }}
                                                    >
                                                        <XAxis
                                                            allowDataOverflow
                                                            dataKey="date"
                                                            tick={<CustomizedAxisTick />}
                                                            domain={['dataMin', 'dataMax']}
                                                            interval={!urlSegment ? (data ? (data.length < 31 ? 0 : Math.round(data.length / 30)) : 0) : ''}
                                                            height={70}
                                                            padding={{
                                                                right: 6,
                                                                left: 4,
                                                            }}
                                                            label={{
                                                                value: `Time (days)`,
                                                                style: { textAnchor: 'middle' },
                                                                position: 'insideBottom',
                                                            }}
                                                        />
                                                        <YAxis
                                                            tickFormatter={(value) => `$${parseInt(value)}`}
                                                            allowDataOverflow={true}
                                                            interval="preserveStartEnd"
                                                            width={100}
                                                            padding={{
                                                                top: 10,
                                                                bottom: 4
                                                            }}
                                                            label={{
                                                                value: `Total Donation Amount`,
                                                                style: { textAnchor: 'middle' },
                                                                angle: -90,
                                                                position: 'left',
                                                                offset: -30,
                                                            }}
                                                        />
                                                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                                                        {Object.keys(ApiData).length ? (
                                                            <Tooltip content={<CustomTooltip />} />
                                                        ) : ''}
                                                        <Line strokeWidth={2.5} dot={true} type="monotone" dataKey='average' stroke={Object.keys(ApiData).length ? "#4c9ef5" : "white"} />
                                                        <Line strokeWidth={2.5} dot={false} type="monotone" strokeDasharray="5 5" dataKey="y" stroke={Object.keys(ApiData).length ? "red" : "white"} />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            </Card>
                                            {Object.keys(ApiData).length === 0 && (
                                                 <div className="absolute inset-0 flex items-center justify-center">
                                                 <div className="text-center text-gray-500 font-semibold">No Data Found</div>
                                               </div>
                                            )}
                                        </>
                                    )}
                           
                        </div>
                        {isLoading && (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="skeleton skeleton-chart">
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                    </div>
                                </div>
                            </div>
                        )}
                        <Modal
                            open={isModalOpen}
                            onCancel={handleCancel}
                            footer={[]}
                        >
                            <div className="download-modal">
                                <p>Downloading</p>
                                <strong>Average Donation with Trend Line</strong>
                                <span>You are about to download the data for Average Donation with Trend Line. Do you want to continue?</span>
                                <Row className="profile">
                                    <Col>
                                        <img src={authUser.picture} width={50} height={50} />
                                    </Col>
                                    <Col style={{ padding: "0px 10px" }}>
                                        <span>{authUser.name}</span><br />
                                        <span>{authUser.email}</span>
                                    </Col>
                                </Row>
                                <Button key="submit" type="primary" onClick={handleOk} icon={isLoading ? <LoadingOutlined /> : <SendOutlined />}>
                                    Download CSV
                                </Button>
                            </div>
                        </Modal>
                    </div>
              
            </div>

    )
}

export default AvgCharts;
